import { PublicClientApplication } from "@azure/msal-browser";
import { forgetPasswordRequest, msalForgetPasswordConfig } from "../../config/auth/authConfig";

const msalInstance = new PublicClientApplication(msalForgetPasswordConfig);

export const PasswordReset = () => {

    msalInstance.loginRedirect(forgetPasswordRequest).catch((e) => {
        console.error(e);
    });

    return null;
}
