import React from "react";
import { Row, Col, Modal } from "antd";


const PrivacyPolicy = (params) => {

    const handleCancel = () => {
        params.setIsModalVisible(false);
    };

    return (
        <>
            <Modal
                title="Privacy Policy"
                visible={params.visible}
                className='modal'
                onCancel={handleCancel}
                width={window.innerWidth < 767 ? '100vw' : '60vw'}
                bodyStyle={{ height: '60vh', overflow: 'auto' }}
                footer={null}>
                <Row>
                    <Col span={2}>
                        <b>1.</b>
                    </Col>
                    <Col span={22}>
                        Cipla (herein referred to as, “we”, “our”, “us”, “company”, or “responsible party”) is committed to protect the privacy and security of your personal data that we process. The purpose of this policy is to inform you about our use of the data and/or personal information we collect from you.
                    </Col>
                </Row>

                <Row>
                    <Col span={2}>
                        <b>2.</b>
                    </Col>
                    <Col span={22}>
                        As a responsible party, we need to keep and process your personal information for meeting legal obligations, compliance requirements, and for creating a user database in order to communicate to you in connection with the MyCipla website, including in relation to product news and updates, event invitations, educational training courses.
                    </Col>
                </Row>

                <Row>
                    <Col span={2}>
                        <b>3.</b>
                    </Col>
                    <Col span={22}>
                        <b>Data Collected</b>
                        <Row>
                            <Col span={2}>
                                3.1
                            </Col>
                            <Col span={22}>
                                The MyCipla website (“the website”) is not designed to collect and/or receive any personal information of you by itself. Cipla is not able to identify you personally unless you access the website and/or provide any personal information.
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}>
                                3.2
                            </Col>
                            <Col span={22}>
                                <u>Active Collection of information:</u> Cipla collects personal information that you enter into data fields on the website.  For example, you may submit or share your name, postal address, e-mail address, telephone numbers, profession, photograph and/ or any other personal information.
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}>
                                3.3
                            </Col>
                            <Col span={22}>
                                <u>Passive Collection of information: </u>The website may collect information about your visits to the website without you actively submitting such information. The information will be used to produce website statistics, and to improve the services and information provided via the website.
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col span={2}>
                        <b>4.</b>
                    </Col>
                    <Col span={22}>
                        <b>Data Usage</b>
                        <Row>
                            <Col span={2}>
                                4.1
                            </Col>
                            <Col span={22}>
                                Cipla will use the personal information you provide through the website to respond to your questions or enquiries made on our website, have efficient communication and to provide you with efficient service.
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}>
                                4.2
                            </Col>
                            <Col span={22}>
                                After you have entered personal information into a form or data field on the website, Cipla may use certain identifying technologies to allow the website to “remember” your personal preferences, such as sections of the website that you visit frequently and, if you choose, your login credentials.
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}>
                                4.3
                            </Col>
                            <Col span={22}>
                                We will only use your personal information for the purposes for which we collected it. We will keep and use it to enable us to run the website and manage our relationship with you effectively, lawfully, and appropriately, whilst you are associated with us, and after our association ends. It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes during your working relationship with us.
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col span={2}>
                        <b>5.</b>
                    </Col>
                    <Col span={22}>
                        <b>Consent </b>
                        <Row>
                            <Col span={2}>
                                5.1
                            </Col>
                            <Col span={22}>
                                We require your consent to obtain and use your data. The record of your data will be maintained for as long as we have your consent to do so. You may request us to stop using your information at any time through withdrawal of your consent directed to us in writing at this email address: cipladna.academy@cipla.co.za or by clicking on the “Contact Us” link on the Website.
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}>
                                5.2
                            </Col>
                            <Col span={22}>
                                We may share your personal information with third parties, other Cipla entities and internal group companies. We require third parties and other entities to respect the security of your data and to treat it in accordance with our instructions, and in a way that is consistent with chapter 9 of Protection of Personal Information Act 4 of 2013 (“the POPI Act”).  This information transfer is permitted under Chapter 9 of the POPI Act which authorises the access seeker (known as the responsible party) permission to transfer the information across foreign borders in the following circumstances:
                                <Row>
                                    <Col span={2}>
                                        5.2.1
                                    </Col>
                                    <Col span={22}>
                                        the person receiving the information (outside of the Republic), must be governed by laws, binding corporate rules, binding agreements or memorandum of understanding between two public bodies which provide an adequate level of protection; or
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={2}>
                                        5.2.2
                                    </Col>
                                    <Col span={22}>
                                        you must consent to the transfer; or
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={2}>
                                        5.2.3
                                    </Col>
                                    <Col span={22}>
                                        the transfer must be necessary for:
                                        <Row>
                                            <Col span={3}>
                                                5.2.3.1
                                            </Col>
                                            <Col span={21}>
                                                the performance of a contract between you and the Responsible Party, or for the implementation of pre-contractual measures taken in response to your request;
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={3}>
                                                5.2.3.2
                                            </Col>
                                            <Col span={21}>
                                                the conclusion or performance of a contract concluded in your interest between the Responsible Party and a third party; or
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={2}>
                                                5.2.3.3
                                            </Col>
                                            <Col span={22}>
                                                the transfer is for your benefit and: –
                                                <Row>
                                                    <Col span={4}>
                                                        5.2.3.3.1
                                                    </Col>
                                                    <Col span={20}>
                                                        it is not reasonably practicable to obtain your consent for that transfer; and
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={4}>
                                                        5.2.3.3.2
                                                    </Col>
                                                    <Col span={20}>
                                                        if it were reasonably practicable to obtain such consent, you would provide it.
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col span={2}>
                        <b>6.</b>
                    </Col>
                    <Col span={22}>
                        <b>Security</b>
                        <Row>
                            <Col span={2}>
                                6.1
                            </Col>
                            <Col span={22}>
                                We have implemented measures to protect the security of your data. We have established procedures to deal with any suspected data security breach and will notify you and any applicable regulator of a suspected breach where we are legally required to do so.
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}>
                                6.2
                            </Col>
                            <Col span={22}>
                                Notwithstanding the implementation of the aforesaid security measures, it is your personal responsibility to ensure that the computer you are using is adequately secured and protected against malicious software.
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col span={2}>
                        <b>7.</b>
                    </Col>
                    <Col span={22}>
                        <b>Access to your Personal Information</b>
                        <Row>
                            <Col span={2}>
                                7.1
                            </Col>
                            <Col span={22}>
                                If you wish to review, change, add or remove the personal information you have entered on the website, you can do so using the “Edit” option available to your profile. Should you experience any issues doing so, you may contact us for assistance.
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}>
                                7.2
                            </Col>
                            <Col span={22}>
                                If you have questions about the use, amendment, or deletion of personal information that you have provided to us, please contact us by clicking on the “Contact Us” link on the website or by emailing us at  <u>cipladna.academy@cipla.co.za.</u>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col span={2}>
                        <b>8.</b>
                    </Col>
                    <Col span={22}>
                        <b>Complaint</b>
                        <Row>
                            <Col span={24}>
                                You have a right to complain about our use of your information if you think your rights have been infringed by Cipla. If we do not respond to your full satisfaction, then your complaint can be directed to the Information Regulators’ office.
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Modal>
        </>
    )
}

export default PrivacyPolicy;