import React, {lazy} from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";



const ProfileRoutes = () => {
    const { path } = useRouteMatch();

    const Profile = lazy(() => import('../../components/utilities/auth-info/user-profile/index'));
    const EditProfile = lazy(() => import('../../components/utilities/auth-info/user-profile/editUserDetails'))
    const EditCompany = lazy(() => import('../../components/utilities/auth-info/user-profile/editCompany')); 

    return(
        <Switch>
           <Route exact path={path}  component={Profile}/>
           <Route exact path={`${path}/profile`}  component={Profile}/>
           <Route exact path={`${path}/edit`}  component={EditProfile}/>
           <Route  path={`${path}/companyEdit`} component={EditCompany}/>
         
        </Switch>
    );
};

export default ProfileRoutes;