const actions = {
  COURSE_DETAILS: 'COURSE_DETAILS',
  SET_COURSE_STATUS: 'SET_COURSE_STATUS',
  SET_COURSE_MODULE_STATUS: 'SET_COURSE_MODULE_STATUS',
  SET_COURSE_RATING: 'SET_COURSE_RATING',
  RESET_COURSE: 'RESET_COURSE',
  SET_SCHEDULED_COURSES: 'SET_SCHEDULED_COURSES',

  courseDetails: payload => {
    return {
      type: actions.COURSE_DETAILS,
      payload,
    };
  },

  setCourseStatus: status => {
    return {
      type: actions.SET_COURSE_STATUS,
      status: status,
    };
  },

  setCourseModuleStatus: status => {
    return {
      type: actions.SET_COURSE_MODULE_STATUS,
      status: status,
    };
  },
  setCourseRating: rating => {
    return {
      type: actions.SET_COURSE_RATING,
      status: rating,
    };
  },
  restCourseState: () => {
    return {
      type: actions.RESET_COURSE,
    };
  },

  setScheduledCoursesState: payload => {
    return {
      type: actions.SET_SCHEDULED_COURSES,
      payload,
    };
  },
};

export default actions;
