import { Col, Divider, Row, Button, Image, Descriptions, Select } from 'antd';
import React from 'react';

import { Cards } from '../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import { OrderSummary } from '../ecommerce/Style';
import Heading from '../../components/heading/heading';
import './style.css';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Typography } from 'antd';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const { Option } = Select;
const { Title } = Typography;

function CheckoutDetail(params) {
  const { accountInformation, cart, placeOrder } = params;
  const isMobile = window.innerWidth < 800;

  return (
    <>
      <>
        <PageHeader />

        <Main>
          <Row gutter={15}>
            <Col xxl={24} xl={24} md={24} sm={24} xs={24}>
              <Cards headless>
                <Row gutter={30}>
                  <Col xxl={10} xl={10} md={10} sm={10}>
                    <Descriptions
                      colon={false}
                      layout="Horizontal"
                      column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                    >
                      <Descriptions.Item labelStyle={{ width: '40%' }} label={<b>First name:</b>}>
                        {accountInformation.person?.firstName}
                      </Descriptions.Item>
                      <Descriptions.Item labelStyle={{ width: '40%' }} label={<b>Surname:</b>}>
                        {accountInformation.person?.lastName}
                      </Descriptions.Item>
                      {accountInformation.person?.email !== undefined ? (
                        <Descriptions.Item labelStyle={{ width: '40%' }} label={<b>Email:</b>}>
                          {accountInformation.person?.email}
                        </Descriptions.Item>
                      ) : (
                        ''
                      )}
                      {accountInformation.person?.mobilePhone !== undefined ? (
                        <Descriptions.Item labelStyle={{ width: '40%' }} label={<b>Phone number:</b>}>
                          {accountInformation.person?.mobilePhone}
                        </Descriptions.Item>
                      ) : (
                        ''
                      )}
                    </Descriptions>
                  </Col>
                  <Col xxl={13} xl={13} md={13} sm={13}>
                    <Cards
                      bodyStyle={{
                        backgroundColor: '#F8F9FB',
                        borderRadius: '20px',
                      }}
                      headless
                    >
                      <Heading className="summary-table-title" as="h4">
                        Order Summary
                      </Heading>
                      <Divider />
                      <Descriptions
                        colon={false}
                        layout="Horizontal"
                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                      >
                        {cart.map(item => {
                          return (
                            <>
                              <Descriptions.Item
                                labelStyle={{ width: '40%' }}
                                label={
                                  <Image
                                    src={API_ENDPOINT + '/Storage/v1/storage/get-image' + item.imageUrl}
                                    width={'70%'}
                                  />
                                }
                              >
                                <div>
                                  <b> {item.name}</b>
                                  <br />
                                  <Descriptions.Item>Qty: {item.quantity}</Descriptions.Item>
                                  <br />

                                  <Descriptions.Item>Price: {item.dnaRewards}</Descriptions.Item>
                                  <br />

                                  <Descriptions.Item>Total: {(item.dnaRewards * item.quantity).toLocaleString()}</Descriptions.Item>
                                </div>
                              </Descriptions.Item>
                              <Divider />
                            </>
                          );
                        })}
                      </Descriptions>
                      <Divider />
                      <OrderSummary>
                        <div className="invoice-summary-inner">
                          <Heading className="summary-total" as="h4">
                            <span className="summary-total-label">Total Points: ({cart.length} items)</span>
                            <span className="summary-total-amount">{`${(cart
                              .map(item => item)
                              .reduce((partialSum, a) => partialSum + a.dnaRewards * a.quantity, 0)).toLocaleString()}`}</span>
                          </Heading>
                          <Heading className="summary-total" as="h4">
                            <span className="summary-total-label">Your Balance:</span>
                            <span className="summary-total-amount">{accountInformation.reward?.dnaPointsBalance.toLocaleString()}</span>
                          </Heading>
                        </div>
                      </OrderSummary>
                      <Button
                        style={{ float: 'right', marginTop: 10 }}
                        key={'order'}
                        type="primary"
                        onClick={placeOrder}
                      >
                        Place Order
                      </Button>
                    </Cards>
                  </Col>
                </Row>
              </Cards>
            </Col>
          </Row>
        </Main>
      </>
    </>
  );
}

export default CheckoutDetail;
