import Styled from 'styled-components';
import LandingPageBg from '../static/img/Landing-page-bg.png';

const Main = Styled.div`
    padding: 0px 30px 20px;
    background-color: rgb(244, 245, 247);
    // min-height: 510px;
    min-height: 84vh;
    // min-height: 715px;
    &.grid-boxed{
        padding: 0px 180px 20px;
        @media only screen and (max-width: 1599px){
            padding: 0px 130px 20px;
        }
        @media only screen and (max-width: 1399px){
            padding: 0px 50px 20px;
        }
        @media only screen and (max-width: 991px){
            padding: 0px 30px 20px;
        }
    }
    .gradingTable{
        .ant-table-thead > tr > th{
            color: black !important;
            borderBottom: solid 1px blue;
        }
        .ant-table-thead > tr > td{
            color: black !important;
        }
        td{
            background: transparent !important;
        }
    }
    .lesson-remove-btn{
        width: 20%;
        margin-top: 10px;
        color: ${({ theme }) => theme['danger-color']};
        float: right;
    }
    .report-style-class {
        height: 100vh;
        margin: 1% auto;
        width: 100%;
    }
    .main-row{
        background: white;
        border-radius: 10px;
        padding: 30px 20px;
    }
    .hyIOZv{
        margin-bottom: 0!important;
    }
    .ant-select-item-option:hover{
        background: white!important;
    }
   
    .ant-card-rtl .ant-card-extra{
                margin-right: 0 !important;
            }
    .ant-tabs-tab span svg {        
        ${({ theme }) => (theme.rtl ? 'padding-left' : 'padding-right')}: 5px;
    }

    .ant-select-dropdown .ant-select-item.ant-select-item-option-selected{
        background: white!important;
    }
    /* Picker Under Input */
    .ant-form-item-control-input .ant-picker {
        padding: ${({ theme }) => (theme.rtl ? '0 0 0 12px' : '0 12px 0 0')} !important;
    }
.sequenceNumberClass{
    width:100%;
}
    /* progressbars */

    .ant-progress {
        display: inline-flex !important;
        align-items: center;
    }

    .ant-progress>div {
        display: flex;
        flex-direction: column;
    }

    .ant-progress .ant-progress-outer {
        ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 0 !important;
        ${({ theme }) => (!theme.rtl ? 'padding-right' : 'padding-left')}: 0 !important;
    }

    .ant-progress .ant-progress-text {
        order: 0;
        margin-left: auto;
        ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 10px !important;
        align-self: flex-end;
        text-align: center;
    }

    .ant-progress-status-warning .ant-progress-bg {
        background: #fa8b0c;
    }.ant-carousel .slick-list .slick-slide.slick-active

    /* progress bars */
    
    @media only screen and (max-width: 1199px){
        padding: 0px 15px;
    }
    @media only screen and (max-width: 991px){
        min-height: 580px;
    }
    .w-100{
        width: 100%;
    }
    .product-sidebar-col{
        @media only screen and (max-width: 767px){
            order: 2;
        }
    }
    .ant-skeleton-paragraph{
        margin-bottom: 0;
    }

    /* // ant alert */
    .ant-alert-closable{
        .ant-alert-message{
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
        }
    }

    .ant-alert-with-description .ant-alert-description{
        display: inline-block;
    }

    /* // ant Calendar Picker */
    .ant-picker-calendar{
        .ant-badge-status-text{
            color: ${({ theme }) => theme['gray-color']};
        }
    }
    .ant-picker-calendar-header .ant-picker-calendar-year-select{
        @media only screen and (max-width: 400px){
            width: 50% !important;
        }
    }
    .ant-picker-calendar-header .ant-picker-calendar-month-select{
        @media only screen and (max-width: 400px){
            width: calc(50% - 8px) !important
        }
    }

    /* // Card Grid */
    .card-grid-wrap{
        .ant-card-grid{
            @media only screen and (max-width: 575px){
                width: 50% !important
            }
        }
    }

    /* // Drawer */
    .atbd-drawer{
        .ant-card-body{
            text-align: center;
        }
    }
    .drawer-placement{
        @media only screen and (max-width: 400px){
            text-align: center;
        }
        .ant-radio-group{
            font-size: 11px;
            @media only screen and (max-width: 400px){
                margin-bottom: 15px;
            }
        }
    }
    .ant-drawer-content-wrapper{
        @media only screen and (max-width: 400px){
            width: 260px !important;
        }
        @media only screen and (max-width: 375px){
            width: 220px !important;
        }
    }

    /* // Input */
    .input-wrap{
        @media only screen and (max-width: 991px){
            min-height: 500px;
        }
        input::placeholder{
            color: ${({ theme }) => theme['light-color']};
        }
    }
    /* // Modal Buttons */
    .modal-btns-wrap{
        margin: 0 -5px;
    }
    /* spinner */
    .ant-spin{
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 20px;
        &:last-child{
            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 0;
        }
    }

    /* Column Cards Wrapper */
    .columnCardsWrapper{
        background: #F4F5F7;
        border-radius: 4px;
        padding: 50px 30px 25px;
    }
    .columnCardsWrapper .ant-card{
        background: #fff;
    }
    .columnCardsWrapper .ant-card-head{
        background: #fff;
    }

    /* Ant Collapse */
    .ant-collapse{
        border-color: #E3E6EF;
        border-radius: 5px;
    }
    .ant-collapse.ant-collapse-icon-position-left .ant-collapse-header{
        color: #5A5F7D;
        padding: 12px 16px 10px 45px;
        font-size: 15px;
        font-weight: bold;
    }
    .ant-collapse-content p{
        color: #9299B8;
        margin-bottom: 0;
    }
    .ant-collapse-content > .ant-collapse-content-box {
        padding: 20px 20px 12px;
    }
    .ant-collapse-content > .ant-collapse-content-box .ant-collapse-content-box{
        padding: 10.5px 20px;
    }
    .ant-collapse.ant-collapse-borderless{
        background-color: #fff;
    }
    .ant-collapse > .ant-collapse-item,
    .ant-collapse .ant-collapse-content{
        border-color: #E3E6EF;
    }
    .ant-collapse > .ant-collapse-item.ant-collapse-item-disabled .ant-collapse-header{
        color: #ADB4D2 !important;
    }

    .ant-collapse > .ant-collapse-item .ant-collapse-header .ant-collapse-arrow{

        font-size: 8px;
    }

    .ant-collapse .ant-collapse {
        border: 0 none;
        background: #fff;
    }

    .ant-collapse .ant-collapse > .ant-collapse-item {
        border-bottom: 0;
    }
   
    .ant-collapse .ant-collapse .ant-collapse-content{
        border-radius: 0;
    }

    /* // Ant Radio */
    .ant-radio-button-wrapper{
        height: 48px;
        line-height: 46px;
        padding: 0 25.25px;
        @media only screen and (max-width: 1024px){
            padding: 0 10px;
        }
        @media only screen and (max-width: 379px){
            height: 40px !important;
            line-height: 38px !important;
            font-size: 12px;
            padding: 0 6px;
        }
    }
    .rewardsProgressBar{
        .progressBarCol{
            min-width: 100% !important;
        }
        .ant-col-lg-10{
            min-width: 100% !important;
        }
    }

    /* // Select */
    .ant-tree-select .ant-select-selector{
        height: 42px;
    }
    .tag-select-list{
        margin-bottom: -10px;
        .ant-select{
            margin-bottom: 10px;
        }
    }
    .ant-select-selector{
        border-color: #E3E6EF !important;
    }
   

    .ant-select{
        &.ant-select-multiple{
            .ant-select-selection-item{
                ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 8px;
            }
        }
        .ant-select-selection-item{
            ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 10px !important;
        }
     
        &.ant-select-lg{
            height: 50px;
            line-height: 48px;
            .ant-select-selector{
                height: 50px !important;
                line-height: 48px;
            }
            .ant-select-selection-item{
                line-height: 48px !important;
                ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 8px;
            }
            &.ant-select-multiple.ant-select-lg .ant-select-selection-item{
                height: 32px;
                line-height: 32px !important;
            }
        }
        &.ant-select-multiple.ant-select-sm{
            .ant-select-selection-item{
                height: 16px;
                line-height: 14px;
                font-size: 11px;
            }
        }
    }
    /* // Slider */
    .slider-with-input{
        .ant-slider{
            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
        }
        .slider-with-input__single{
            margin-bottom: 15px;
        }
    }

    /* // Taglist */
    .taglist-wrap{
        margin: -5px;
        .ant-tag {
            line-height: 22px;
            padding: 0 10.2px;
            border: 0 none;
            margin: 5px;
            color: ${({ theme }) => theme['gray-color']};
            &.ant-tag-has-color{
                color: #fff !important;
            }
            &.ant-tag-magenta{
                color: #eb2f96;
            }
            &.ant-tag-red{
                color: #f5222d;
            }
            &.ant-tag-volcano{
                color: #fa541c;
            }
            &.ant-tag-orange{
                color: #fa8c16;
            }
            &.ant-tag-gold{
                color: #faad14;
            }
            &.ant-tag-line{
                color: #a0d911;
            }
            &.ant-tag-green{
                color: #a0d911;
            }
            &.ant-tag-cyan{
                color: #13c2c2;
            }
            &.ant-tag-blue{
                color: #1890ff;
            }
            &.ant-tag-geekbule{
                color: #2f54eb;
            }
            &.ant-tag-purple{
                color: #722ed1;
            }
        }
    }

    /* // Timepicker List */
    .timepicker-list{
        margin: -5px;
        .ant-picker{
            margin: 5px;
        }
    }

    /* // Ant Menu */
    .ant-menu{
        .ant-menu-submenu-title{
            svg{
                color: ${({ theme }) => theme['light-color']};
            }
        }
    }

    /* Ant Comment */
    .ant-comment-inner{
        padding: 0;
    }
    .ant-comment-content-detail p{
        color: #9299B8;
    }
    .ant-list-items{
        padding-top: 22px;
        cursour: pointer;
    }
    .ant-list-items li:not(:last-child){
        margin-bottom: 22px;
    }
    .ant-comment:not(:last-child){
        margin-bottom: 22px;
    }
    .ant-comment-nested{
        margin-top: 22px;
    }
    .ant-comment-actions li span{
        color: #ADB4D2;
    }
    .ant-comment-content-detail textarea{
        resize: none;
        min-height: 170px;
        border-radius: 5px;
    }

    /* // Vector Map */
    .rsm_map{
        min-height: 505px;
        .world-map{
            width: 100%;
            height: auto;
            @media only screen and (max-width: 1599px){
                height: 480px;
            }
            @media only screen and (max-width: 1399px){
                height: 400px;
            }
            @media only screen and (max-width: 575px){
                height: 400px;
            }
            @media only screen and (max-width: 767px){
                height: 300px;
            }
            @media only screen and (max-width: 575px){
                height: 250px;
            }
            @media only screen and (max-width: 479px){
                height: 350px;
            }
            @media only screen and (max-width: 375px){
                height: 240px;
            }
        }
        .controls{
            position: absolute;
            right: 30px;
            bottom: 30px;
            button{
                display: block;
                width: 27px;
                height: 27px;
                background: none;
                color: #5a5f7d;
                border: 1px solid #f1f2f6;
                padding: 0;
                font-size: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                cursor: pointer;
                &:first-child{
                    border-radius: 6px 6px 0 0;
                }
                &:last-child{
                    border-radius: 0 0 6px 6px;
                }
                &:focus{
                    outline: none;
                }
                svg{
                    width: 10px;
                }
            }
            button + button{
                border-top: 0 none;
            }
        }
    }
    .btn-log-ticket-active{
        width: 12rem;
        padding: 5px 10px;
        margin: 0 15px;
        border-radius: 5rem;
        align-items: center;
        color: #255CA3;
        background: #EBEDF1;
        border: solid 2px #EBEDF1;
        font-weight: 700;
    }
    .btn-log-ticket-inactive{
        width: 12rem;
        padding: 5px 10px;
        margin: 0 15px;
        border-radius: 5rem;
        align-items: center;
        color: #979797;
        background: transparent;
        border: transparent;
        font-weight: 700;
        box-shadow: none;
    }
    .btn-log-ticket-inactive:hover{
        border: solid 2px #EBEDF1;
    }
    .btn-view-ticket-active{
        width: 12rem;
        padding: 5px 10px;
        margin: 0 15px;
        border-radius: 5rem;
        align-items: center;
        color: #255CA3;
        background: #EBEDF1;
        border: solid 2px #EBEDF1;
        font-weight: 700;
    }
    .btn-view-ticket-inactive{
        width: 12rem;
        padding: 5px 10px;
        margin: 0 15px;
        border-radius: 5rem;
        align-items: center;
        color: #979797;
        background: transparent;
        border: transparent;
        font-weight: 700;
        box-shadow: none;
    }
    .btn-view-ticket-inactive:hover{
        border: solid 2px #EBEDF1;
    }
    /* // Checkout Wrapper */
    .checkoutWraper{
        // .ant-card-body{
        //     padding: 50px 50px 50px 30px !important;
        //     @media only screen and (max-width: 575px){
        //         padding: 25px !important;
        //     }
        //     .ant-card-body{
        //         padding: 25px !important;
        //         @media only screen and (max-width: 375px){
        //             padding: 15px !important;
        //         }
        //     }
        // }
        .ant-steps{
            margin-top: -22px;
        }
    }

    /* // Star Active */
    a{
        i,
        span.fa{
          font-size: 16px;
          color: ${({ theme }) => theme['extra-light-color']};
        }
        &.starDeactivate{
          i:before{
            content: "\f31b";
          }
        }
        &.starActive{
          i,
          span.fa{
            color: ${({ theme }) => theme['warning-color']};
          }
          i:before,
          span.fa:before{
            color: ${({ theme }) => theme['warning-color']};
            content: "\f005";
    
          }
        }
    }

    .ant-timeline{
        color: ${({ theme }) => theme['gray-color']};
        .ant-timeline-item-content{
            font-size: 16px;
        }
    }

    
    .ant-rate-content{
        font-weight: 500;
        color: ${({ theme }) => theme['gray-color']}
    }

    .account-card{
        .ant-card-head{
            .ant-card-extra{
                @media only screen and (max-width: 575px){
                   padding-top: 0 !important;
                }
            }
            
        }
                
    }

    /* // Rechart */
    .recharts-default-legend{
        .recharts-legend-item{
            min-width: 100px !important;
        }
    }

    /* // Radio */
    .radio-size-wrap{
            .ant-radio-button-wrapper{
                @media only screen and (max-width: 1450px){
                    padding: 0 11.5px;
                }
            }
        }
    }

    /* // Message  */
    .message-button-list{
        margin: -4px;
        .ant-btn {
            margin: 4px;
        }
    }
    /* Chart Label */

    .chart-label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 6px;
        color: #5a5f7d;
    }

    .chart-label .label-dot {
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 8px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
    }

    .chart-label .label-dot.dot-success {
        background: #20c997;
    }

    .chart-label .label-dot.dot-info {
        background: #0055b8;
    }

    .chart-label .label-dot.dot-warning {
        background: #fa8b0c;
    }

    .chart-label .label-dot {
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
    }

    // Ant comment action
    .ant-comment-actions{
        li{
            position: relative;
            &:not(:last-child){
                margin-right: 8px;
                padding-right: 8px;
                &:after{
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 12px;
                    background-color: #C6D0DC;
                    content: '';
                }
            }
            .com-time{
                cursor: default;
            }
            span{
                margin-right: 0;
            }
        }
    }

    // Emoji Picker React
    .emoji-picker-react{
        top: 15px;
        right: 25px;
        box-shadow: 0 5px 10px #efefef10;
        @media only screen and (max-width: 479px){
            top: 25px;
            right: -50px;
            width: 260px;
        }
        .emoji-categories{
            padding: 0 10px;
        }
        .emoji-search{
            margin: 0 10px;
        }
        .content-wrapper:before{
            display: none;
        }
        .emoji-group{
            padding: 0 10px;
        }
        .emoji-group:before{
            font-size: 12px;
            font-weight: 600;
            color: ${({ theme }) => theme['dark-color']};
        }
        .emoji-group .emoji-img{
            margin: 5px !important;
        }
    }

    .wizard-side-border{
        >.ant-card{
            .ant-card-body{
                padding: 0 25px !important;
            }
        }
        .checkout-successful{
            >.ant-card{
                .ant-card-body{
                    padding: 25px !important;
                }
            }
        }
        .payment-method-form.theme-light{
            .shipping-selection__card{
                .ant-card-body{
                    padding: 25px 0 !important;
                }
            }
        }
        .shipping-selection__card{
            .ant-card-body{
                padding: 25px !important;
            }
        }
        .atbd-review-order{
            .ant-card-body{
                padding: 25px 25px 0 !important;
                @media only screen and (max-width: 767px) {
                    padding: 15px 15px 0 !important;
                }
            }
        }
        
        .ant-steps {
            padding: 50px;
            @media only screen and (max-width: 1399px) {
                padding: 25px;
            }
        }
        .steps-wrapper{
            padding: 50px;
            @media only screen and (max-width: 1399px) {
                padding: 25px;
            }
            ${({ theme }) => (theme.rtl ? 'border-right' : 'border-left')}: 1px solid ${({ theme }) =>
  theme['border-color-light']};
        }
    }
    .editor-compose > div {
        position: static;
        max-width: 100%;
        margin: 25px 0;
    }

    // Ant Dragger
    .ant-upload-drag{
        background-color: #fff !important;
        border-radius: 10px !important;
        display: flex;
        align-items: center;
        min-height: 100px;
        border-color: #C6D0DC;
        &.sDash-uploader-large{
            min-height: 180px;
        }
        .ant-upload-drag-container{
            .ant-upload-text{
                margin-bottom: 0;
                font-size: 15px;
                color: #9299B8;
            }
        }
    }

    // Form Validation
    .ant-form-item{
        &.ant-form-item-has-success{
            .ant-input{
                border-color: ${({ theme }) => theme['success-color']};
            }
            &.ant-form-item-with-help{
                .ant-form-item-explain{
                    color: ${({ theme }) => theme['success-color']};
                }
            }
        }
        &.ant-form-item-with-help{
            .ant-form-item-explain{
                margin-top: 6px;
            }
        }
    }
    .removeButton{
        font-size: 17px;
        font-weight: bold;
    }
    .ant-space-item{
        width: 100%!important;
    }
    .image-list{
        border:1px solid lightgray;
        padding: 15px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 15px;
    }
    .error{
        color: red!important;
        margin-top: 5px;
    }
    .panel-class{
        color: black;  
        font-weight: bold; 
        text-transform: capitalize;
    }
    .ant-btn-icon-only.ant-btn-sm > *{
        width: 20px!important;
        color:gray;
    }
    .slide-icon{
        margin-top: 5px;
        text-align: right;
    }
    .new-ticket-btn{
        display: flex !important;
        justify-content: center!important;
        align-items: center!important;
    }
    button.ant-btn.ant-btn-text{
        color: #255CA3;
    }
    .product{

        position: relative;
        button.ant-btn.ant-btn-text{
            color: #255CA3;
        }
        .ant-card-head{
            background: none;
            display: none;
        }
        .ant-card{
            background: none;
        }
        .product-filter{
            background: white;
            border-radius: 10px;
        }
        .hyIOZv{
            margin-bottom: 0px!important;
        }
        .ant-input-affix-wrapper{
            border-radius: 23px 0 0 23px !important;
        }
        .krRpZu .ant-select{
            margin-bottom: 0 !important;
            width: 100%;
        }
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
            height: 30px !important;
            border-radius: 0 23px 23px 0 !important;
        }
        .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .search-result{
            margin-bottom: 0 !important;
            font-size: 11px;
            margin: 0 !important;
            text-align: center;
        }
        .ant-card-body{
            padding: 0!important;
        }
       
       
        .search-div{
            position: fixed;
            z-index: 100;
            width: 100%
            bottom: 0;

            .search-btn{
                margin-bottom: 20px;
                float: right;
                margin-right: 20px;
            }
        }
        .ant-btn > span{
            font-size: 40px;
        }
        .m-20{
            // margin: 20px;

            h2{
                color: #272B41;
                font-size: 18px;
            }

            p{
                color: #272B41;
                font-size: 12px;
                font-weight: 400;

            }
        }
       
        .ant-btn{
            border: none;
            box-shadow: none;
            outline: none;
            background: transparent;
        }
        @media only screen and (max-width: 1599px){
            .ant-select{
                margin-bottom: 0 !important;
            }
        }
    }
    .product-details{
        margin-top: 20px;
        h3{
            color: #255CA3;
        }
        h2{
            font-size: 18px;
            color: #272B41;
            margin-bottom: 15px;
        }

        p{
            font-size: 14px;
            color: #272B41;
        }
         .card-img{
             width: 100%;
             height: 100%;
             padding: 20px;
             object-fit: cover;
             
         }
         .ant-card-actions{
            background: #255CA3 !important;
         }
         .ant-card-body{
             padding: 0!important;
         }
         .ant-card-meta-title{
             padding: 20px !important;
             font-size: 12px;
             border-top: 1px solid #E3E6EF;
         }
         .ant-list-lg .ant-list-item{
             padding: 0 15px 0 0!important;
         }
     }

     .category-product-list{
         h2{
             font-size: 17px;
             color: #255CA3;
             margin-bottom: 20px;
         }
         h3{
            font-weight: 700 !important;
         }
         .card-img{
             width: 100%;
             height: 100%;
             object-fit: cover;
         }
         .ant-card-body{
             padding: 0!important;
         }
         .ant-card{
             overflow: hidden;
         }
         .ant-list-lg .ant-list-item{
             padding: 0 15px 0 0 !important;
         }
     }
     .product-row{

        // background: #E5E5E5;
        padding: 20px 30px; 
        border-radius: 5px, 
        margin-top: 20px; 
        margin-bottom: 20px;
        justify-content: space-between;

        h3{
            font-weight: bold;
        }

        .m-30{
            margin-top: 30px;
        }
        .ant-carousel .slick-list .slick-slide{
            text-align: center;
        }
        .product-gallery{
            margin-top: 40px;
        }
        p{
            color: #272B41;
            margin-top: 20px;
            font-size: 12px;
            font-weight: 300;
        }
        .ant-btn{
            font-size: 12px;
        }
        
        .related{
            margin-top:30px;
            margin-left:15px;
            width: 100%;
            // border-left:2px solid #E3E6EF;
           h5{
            text-transform: uppercase;
            margin-left: 15px;
           }
           .price{
             color: #714194;
             font-size: 32px;
             font-weight: 800;
           }
           b{
            color: #255CA3;
            font-weight: bold;
            font-size: 16px;
           }
           .price-prefix{
            color: #255CA3;
            font-weight: 500;
            font-size: 16px;
           }
           .quiz-button{
            background: #64A2D9;
            border: #64A2D9;
            border-radius: 5rem;
            margin-top: 10px;
            padding-left: 35px;
            padding-right: 35px;
            textAlign: 'center',
           }
           .product-description{
                border: solid 1px #EBEDF1;
                border-radius: 5rem;
                // padding: 5px;
                color: #255CA3;
                font-weight: bold;
                background: #EBEDF1;
                width: 80%;
                height: 2rem;
                text-align: center;
           }
           .relate-content-Btn{
                background: transparent;
                border-radius: 5rem;
                width: 80%;
                height: 2rem
                font-weight: bold;
                color: #979797;
                border: solid 1px transparent;
           }
           .relate-content-Btn:hover{
                background: #EBEDF1;
                color: #255CA3;
                border: solid 1px #EBEDF1;
           }
           a{
               font-size: 16px;
           }
           @media only screen and (max-width: 575px){
            border-left:0px;
            border-top:2px solid #E3E6EF;
            margin-left:10px;
            h5{
                margin-top:10px
            }
         }
        }
        .related-sub{
            margin-left: 15px;
            font-size: 14px;
            font-weight: 400;
            color: #272B41;
        }
        .mt-30{
            margin-top: 30px;
        }
        .product-placeholders{
            margin-top: 30px;

            h4{
                font-size: 13px;
                color: #272B41;
            }
            span{
                color: #272B41;
                font-size: 12px;
                font-weight: 300;
            }
        }
      
     }
     .log-ticket{
        background: white;

        .ant-card-head{
            border-bottom: none!important;
         }
        .ant-upload{
            width: 100%!important;

            .ant-btn{
                text-align: left;
                color: #d9d9d9;
                width: 100%;
                height: 38px;
            }
            .ant-btn > span{
                width: 95%;
                float: left;
                margin-top: 3px;
            }
        }
     }
     .char-len{
         float: right;
         margin-top: -20px;
     }
    
     //quiz
     .quiz-header-text{
         text-transform: uppercase;
         font-size: 16px;
         font-weight: 600;
         margin-bottom: 20px;
     }
     .quiz-card{
         .ant-card-head{
            border-bottom: none !important;
         }
         .ant-card-body{
             padding: 0 15px 0 15px!important;
         }
         .card-text{
            height: 60px; 
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box; 
            overflow: hidden;
            font-size: 12px;
         }
         .quiz-link{
            float: right;
            color: #349CF7;
         }
         .quiz-points{
            color: #349CF7;
            font-weight: 600;
         }
     }
     .dashboard{
         .ant-card-head{
             border-bottom: none !important;
             padding: 0 !important;
         }
         .ant-card-head-title span{
             margin-left: 0 !important;
         }
         .ant-card-body{
             padding: 0px !important;
         }
         .ant-table-column-title{
             color: black !important;
         }
         .ant-carousel{
             width: 100% !important;
             height: 100% !important;
             object-fit: contain;
         }
         .height{
             height: 450px;
         }
         .dashboard-cards{
            // display: flex; 
            // flex-direction: column; 
            // justify-content: space-between;
         }
         .order{
             height: 100%;

             .ant-table-tbody > tr > td{
                padding: 10px 5px !important;
            }

            .ant-table-thead th.ant-table-column-has-sorters{
                padding: 10px 5px !important;
            }

             .order-title{
                 font-size: 18px;
                 font-weight: 500;
                 text-align: center;
                 padding: 15px 0;
             }

            a{
                color: gray !important;
                text-align: center;
            }
         }
         .type-box{
             display: flex;
             justify-content: center;
             align-items: start;
             font-size: 13px;
             flex-direction: column;
            //  border: 1px solid lightgray;
             border-radius: 5px;
             height: 120px;
             word-wrap: break-word;

             p{
               
                 font-size: 14px;
                 text-align: center;
                 font-weight: 600;
                 margin-bottom: 5px !important;
                 overflow-wrap: anywhere
             }
             span{
                 font-size: 15px;
                 font-weight: 600;
                 text-align: center;
                 margin-bottom: 15px;
             }

             a{
                //  color: gray !important;
                 position: absolute;
                 bottom: 0;
                 text-align: center;
             }
         }
         .my-cipla{
             font-size: 12px;
             height: 100%;

             thead{
                 display: none;
             }

             .pl-10{
                 padding-left: 10px;
             }

             .ant-card-head-title{
                text-align: center;
            }

             .progress-bar{
                border: 1px solid lightgray; 
                border-radius: 5px;
                padding: 10px; 
                font-size: 12px;
                margin-bottom: 20px;
                height: fit-content;
                line-height: 24px;
             }
           
             .ant-card-body{
                 padding: 0px !important;
                 font-size: 12px !important;
                 cursor: default;
             }
             .carousel-left{
                border: 1px solid #E3E6EF;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                flex-direction: column;
                text-align: center;
        
                h2{
                    color: #0055b8;
                    font-weight: 1000;
                    font-size: 30px;
                    margin-bottom: 0;
                }
        
                p{
                    color: #50ACE3;
                    font-size: 11px;
                }
            }
            .carousel-right{
                padding: 0 20px;
        
                h4{
                    font-size: 12px;
                }
                span{
                    font-size: 10px;
                }
            }
            .carousel-row{
                background: white; 
                padding: 30px 30px 
                border-radius: 5px;
                border: 1px solid white;
        
                .feature-img{
                    width: 100%;
                }
            }
             @media screen and (max-width: 767px){
                 margin-top: 15px;
             }
         }
         .list{
            border: 1px solid lightgray;
            border-radius: 5px;
            height: 100%;
            padding: 0 !important;

            .ant-card-head-title{
                padding-left: 10px;
                text-align: center;
            }
        

            .desc{
                font-size: 13px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                line-clamp: 2;
                margin-bottom: 10px;
                margin-top: 10px;
            }
         }
         @media screen and (max-width: 767px){
            .height{
                height: 100%;
            }
            .type-box{
                margin-bottom: 10px;
            }
            .order-list{
                margin-top: 30px;
            }
        }
     }
     .cipla-table{
         .ant-card-head-title{
            padding-left: 10px;
         }
        .ant-table-tbody > tr > td{
            padding: 5px !important;
        }
     }
     .f-size{
        font-size: 12px;
    }
    .related-products{
        background: #E5E5E5;

        h3{
            font-weight: 700;
            font-size: 20px;
        }
        .related-products-Btn{
            background: transparent;
            border: solid 2px;
            border-image: linear-gradient(90deg, rgba(6,112,247,1) 0%, rgba(0,193,243,1) 0%, rgba(0,236,162,1) 100%) 1;
            color: rgba(0,236,162,1);
            border-radius: 5rem;
            width: 8rem;
        }
    }
     .banner-img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
     }
     .p-10{
         .ant-card-body{
             padding: 10px !important;
         }
     }
     .p-0{
         padding: 0 !important;
     }
     @media screen and (max-width: 880px) and (min-width:750px){
        .f-size{
            font-size: 12px;
        }
     }
     .color-gray{
         a{
             color: gray !important;
         }
     }
     .featured-course-aboutView{
        .ant-card.ant-card-bordered, .ant-card-head {
            background: transparent;
            
          }
        
          .ant-card.ant-card-bordered{
            .ant-card.ant-card-bordered{
              background: white;
            }
          }
          .ant-card-head-title{
            font-weight: bold;
          }
     }
`;

const Body = Styled.div`
.featured-course-aboutView{
    .ant-card.ant-card-bordered, .ant-card-head {
        background: #EAEDF1;
        
      }
    
      .ant-card.ant-card-bordered{
        .ant-card.ant-card-bordered{
          background: white;
        }
      }
      
      .ant-card-head-title{
        font-weight: bold;
      }
 }

 .height{
    .ant-card.ant-card-bordered, .ant-card-head {
        background: #EAEDF1;
        
      }
    
      .ant-card.ant-card-bordered{
        .ant-card.ant-card-bordered{
          background: white;
        }
      }
      .ant-card-head-title{
        font-weight: bold;
      }
 }
`;

const ButtonsGroupWrapper = Styled.div`
    margin-bottom: -25px;
    .button-group-single{
        margin-bottom: 15px;
        h4{
            font-size: 15px;
            margin-bottom: 8px;
        }
        .ant-btn-group{
            margin-bottom: 10px;
        }
    }
    .button-group-single .ant-btn-white{
        border: 1px solid #E3E6EF;
        background: #fff !important;
        ${({ theme }) => (theme.rtl ? 'border-left-width' : 'border-right-width')}: 0px;
        &:last-child{
            ${({ theme }) => (theme.rtl ? 'border-left-width' : 'border-right-width')}: 1px;
        }
        &:hover{
            color: ${({ theme }) => theme['gray-color']} !important;
            background: ${({ theme }) => theme['bg-color-normal']} !important;
        }
    }
    .button-group-single .ant-btn-light{
        border: 1px solid #E3E6EF;
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 20px;
        ${({ theme }) => (theme.rtl ? 'border-left-width' : 'border-right-width')}: 0px;
        font-weight: 500;
        color: ${({ theme }) => theme['extra-light-color']} !important;
        background: ${({ theme }) => theme['bg-color-normal']} !important;
        &:last-child{
            ${({ theme }) => (theme.rtl ? 'border-left-width' : 'border-right-width')}: 1px;
        }
    }
    .ant-btn-group:not(:last-child){
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 20px;
    }
`;

const BlockButtonsWrapper = Styled.div`
    .ant-btn-block{
        margin-bottom: 10px;
    }
`;

const ButtonSizeWrapper = Styled.div`
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
`;

const BtnWithIcon = Styled.div`
    display: inline;
    .anticon i,
    .anticon svg,
    .anticon img{
        width: 10px;
        height: 10px;
    }
    .ant-btn-group button.active{
        ${({ theme }) => (theme.rtl ? 'border-left' : 'border-right')}: 0px;
    }
`;

const AlertList = Styled.div`
    margin-top: -15px;
    .alert-empty-message{
        .ant-alert{
            padding: 10px 40px 10px!important;
            &.ant-alert-no-icon{
                padding: 10px 20px 10px!important;
            }
        }
        .ant-alert-icon{
            top: 15px !important;
        }
        .ant-alert-message{
            display: none !important;
        }
    }
`;

const AutoCompleteWrapper = Styled.div`
    .ant-select:not(:last-child){
        margin-bottom: 20px;
    }
    .auto-complete-input{
        .ant-select{
            width: 200px !important;
            @media only screen and (max-width: 575px){
                width: 100% !important;
            }
        }
    }
`;

const CalendarWrapper = Styled.div`
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
        height: 30px !important;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 28px !important;
    }
    .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content{
        height: 105px;
    }
    .ant-radio-button-wrapper{
        height: 30px;
        line-height: 28px;
        @media only screen and (max-width: 575px){
            height: 30px !important;
            line-height: 28px !important;
        }
    }
`;

const DatePickerWrapper = Styled.div`
    .ant-picker{
        padding: 6px 11px 6px;
        width: 100%;
        border-color: #E3E6EF;
        border-radius: 5px;
    }
    .ant-picker:not(:last-child){
        margin-bottom: 20px;
    }
    .ant-picker-input > input{
        color: #5A5F7D;
    }
    .ant-picker-range .ant-picker-input > input{
        text-align: center;
        font-weight: 500;
    }
`;

const NotificationListWrapper = Styled.div`
    margin: -4px;
    button{
        margin: 4px;
    }
`;

const TagInput = Styled.div`
    padding: 12px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme['border-color-normal']};
    margin: -3px;
    .ant-tag{
        margin: 3px;
        font-size: 11px;
        padding: 0 4px;
        border: 0 none;
        height: 24px;
        display: inline-flex;
        align-items: center;
    }
`;

const PageHeaderWrapper = Styled.div`
    .ant-page-header{
        border: 1px solid ${({ theme }) => theme['border-color-normal']};
        border-radius: 5px;
    }
    .ant-page-header .ant-page-header-heading-left{
        margin: 2px 0;
    }
`;

const MessageStyleWrapper = Styled.div`
    .ant-btn-lg{
        font-size: 14px;
        background: #0055b8 !important
    }
`;

const BasicFormWrapper = Styled.div`
    .ant-card-body{
        padding: 25px !important;
    }
    .ant-form {
        .form-item{
            margin-bottom: 30px;
            label{
                font-weight: 500;
                display: block;
                margin-bottom: 15px;
            }
            .ant-cascader-picker{
                width: 100%;
                min-height: 48px;
                .ant-cascader-input{
                    min-height: 48px;
                }
            }
        }
        .ant-input-affix-wrapper > input.ant-input{
            padding-top: 12px;
            padding-bottom: 12px;
        }
        .ant-input-affix-wrapper .ant-input-prefix svg{
            color: #9299B8;
        }
    }
    .ant-form-item-control-input{
        min-height: auto !important;
    }
    .ant-form-item{
        flex-flow: column;
        &:not(:last-child){
            margin-bottom: 26px;
        }
        &:last-child{
            margin-bottom: 0;
        }
        .ant-form-item-label{
            text-align: ${({ theme }) => (theme.rtl ? 'right' : 'left')};
            label{
                height: fit-content;
                margin-bottom: 6px;
            }
        }
        .ant-form-item-control-input{
            input,
            textarea{
                color: ${({ theme }) => theme['gray-color']};
                &:placeholder{
                    color: ${({ theme }) => theme['light-color']};
                }
            }
            input[type="password"]{
                padding-top: 12px;
                padding-bottom: 12px;
            }
            .ant-picker-input input{
                padding: 12px;
            }
            button{
                height: 44px;
            }
            .ant-input-affix-wrapper{
                padding: 0 11px;
            }
        }
        .ant-select-single{
            .ant-select-selector{
                padding: 0 20px;
                height: 48px !important;
                border: 1px solid ${({ theme }) => theme['border-color-normal']};
                .ant-select-selection-item{
                    line-height: 46px !important;
                    padding: 0 !important;
                }
                .ant-select-selection-placeholder{
                    line-height: 46px !important;
                }
            }
        }
    }
    .setting-form-actions{
        margin: 48px 0 14px;
        @media only screen and (max-width: 575px){
            margin: 40px 0 14px;
        }
        button{
            border-radius: 6px;
            height: 44px;
            margin-bottom: 14px;
            &.ant-btn-light{
                border: 1px solid ${({ theme }) => theme['border-color-light']};
                background-color: ${({ theme }) => theme['bg-color-light']};
            }
        }
    }
    .ant-form-item-control-input{
        .input-prepend{
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0 20px;
            height: 48px;
            border-radius: ${({ theme }) => (theme.rtl ? '0 4px 4px 0' : '4px 0 0 4px')};
            z-index: 10;
            border: 1px solid ${({ theme }) => theme['border-color-normal']};
            background-color: ${({ theme }) => theme['bg-color-light']};
            svg,
            i{
                color: ${({ theme }) => theme['gray-color']};
            }
        }
        .input-prepend-wrap{
            .ant-input-number{
                input{
                    ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 70px;
                }
            }
        }
        .ant-input-number{
            width: 100% !important;
            border: 1px solid ${({ theme }) => theme['border-color-normal']};
        }
    }
    .add-record-form{
        margin: 25px 0 35px 0;
        
        .record-form-actions{
            padding-right: 40px;
        }
        .ant-btn{
            height: 44px;
            font-size: 14px;
            font-weight: 500;
        }
        .ant-radio-group{
            margin-bottom: -4px;
            .ant-radio-wrapper{
                margin-bottom: 4px;
            }
        }
    }
    .adTodo-form{
        .btn-adTodo {
            font-size: 14px;
        }
    }

    .sDash_form-action{
        margin: -7.5px;
        button{
            font-size: 14px;
            font-weight: 500;
            border-radius: 6px;
            margin: 7.5px;
            padding: 6.4px 19px;
            &.ant-btn-light{
                height: 44px;
                background-color: #F1F2F6;
                border-color: #F1F2F6;
            }
        }
        .ant-form-item{
            margin-bottom: 25px !important;
        }
        .ant-btn-light{
            background-color: #F8F9FB;
        }
    }
    .sDash_color-picker{
        border: 1px solid #E3E6EF;
        border-radius: 4px;
        padding: 11px 14px;
        input{
            width: 100%;
            border: 0 none;
            background-color: #fff;
            &::-webkit-color-swatch{
                min-height: 18px;
                border: 1px solid #C6D0DC;
            }
        }
    }
`;

const CardToolbox = Styled.div`
    .ant-page-header-heading{
        @media only screen and (max-width: 991px){
            flex-flow: column;
            align-items: center;
        }
    }

    .ant-page-header-heading-left{
        @media only screen and (max-width: 575px){
            flex-flow: column;
        }
        @media only screen and (max-width: 800px){
            max-width: 100%;
        }
        .ant-page-header-back{
            @media only screen and (max-width: 575px){
                margin: 0;
                padding: 0;
            }
        }
        .ant-page-header-heading-title{
            @media only screen and (max-width: 575px){
                margin: 10px 0 8px;
                padding: 0;
            }
            &:after{
                @media only screen and (max-width: 575px){
                    display: none;
                }
            }
        }
        .ant-page-header-heading-sub-title{
            @media only screen and (max-width: 575px){
                margin: 0;
                padding: 0;
                flex-flow: column;
            }
            .title-counter{
                @media only screen and (max-width: 575px){
                    margin-bottom: 16px;
                }
            }
        }
    }

    .ant-page-header-heading-title{
        position: relative;
        ${({ theme }) => (theme.rtl ? 'padding-left' : 'padding-right')}: 24px;
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 24px;
        @media only screen and (max-width: 767px){
            ${({ theme }) => (theme.rtl ? 'padding-left' : 'padding-right')}: 12px;
            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 12px !important;
        }
        &:after{
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0px;
            top: 0;
            height: 100%;
            width: 1px;
            content: '';
            background: ${({ theme }) => theme['border-color-normal']};
        }
    }
    .ant-page-header-heading-sub-title{
        font-weight: 500;
        display: flex;
        align-items: center;
    }
    .ant-select{
        ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 25px;
        @media only screen and (max-width: 575px){
            ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 0;
        }
        .ant-select-selector{
            height: 46px !important;
            min-width: 350px;
            @media only screen and (max-width: 991px){
                min-width: 100%;
            }
        }
        .ant-select-selection-search-input{
            height: 46px;
            border-radius: 23px;
            border: 0 none;
            box-shadow: 0 5px 20px #9299B803;
            input{
                height: 46px !important;
                font-size: 14px;
            }
        }
    }
    .btn-add_new{
        border-radius: 6px;
        height: 40px;
        padding: 0 14px;
        font-size: 12px;
        @media only screen and (max-width: 991px){
            margin-top: 10px;
        }
        a{
            display: flex;
            align-items: center;
            svg{
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
            }
        }
    }
`;

const FormGroupWrapper = Styled.div`
    .ant-card-body{
        @media only screen and (max-width: 767px){
            padding: 0 !important;
        }
    }
`;
const BannerCardStyleWrap = Styled.div`
    .ant-card-body{
        padding: 25px 25px 0 25px !important;
    }
`;

const FileCardWrapper = Styled.div`
    .file-list{
        min-height: 385px;
        .file-list__single{
            justify-content: space-between;
            align-items: center;
            &:not(:last-child){
                margin-bottom: 18px;
            }
            span{
                display: block;
                font-size: 12px;
                line-height: 1.42;
                &.file-name{
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme['dark-color']};
                }
                &.file-size{
                    margin: 2px 0;;
                    color: ${({ theme }) => theme['gray-solid']};
                }
                &.file-content-action{
                    a{
                        font-weight: 500;
                        color: ${({ theme }) => theme['primary-color']};
                    }
                    a + a{
                        margin-left: 8px;
                    }
                }
            }
        }
        .file-single-info{
            width: 50%;
            align-items: center;
            .file-single-logo{
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 16px;
                img{
                    max-width: 42px;
                }
            }
        }
        .file-single-action{
            .ant-dropdown-trigger {
                color: ${({ theme }) => theme['extra-light-color']};
            }
        }
    }
`;

const TableStyleWrapper = Styled.nav`
  table{
    tbody{
      td{
          font-weight: 0px !important;
        .user-info{
          .user-name{
            font-size: 14px;
          }
        }   
        .customer-name{
            width: 100px !important;
            max-width: 150px !important;
            
        }    
        span.status-text{
          font-size: 12px;
          padding: 0 12.41px;
          line-height: 1.9;
          font-weight: 500;
          border-radius: 12px;
          text-transform: capitalize;
          display: inline-block !important;
          background: #ddd;
          &.active{
            background-color: ${({ theme }) => theme['success-color']}15;
            color: ${({ theme }) => theme['success-color']};
          }
          &.deactivate{
            background-color: ${({ theme }) => theme['warning-color']}15;
            color: ${({ theme }) => theme['warning-color']};
          }
          &.blocked{
            background-color: ${({ theme }) => theme['danger-color']}15;
            color: ${({ theme }) => theme['danger-color']};
          }
        }
      }
    }
  }

  .ant-table{
    background: transparent !important;
  }
  
  .table-striped-rows
  .ant-descriptions-row
  td {      
    background: transparent !important;
  }
  .description-item{
    background: transparent !important;
  }
//   .tr{
//     border-radius: 5rem !important;
//   }

  .table-striped-rows tr:nth-child(2n) td {      
    background-color: #EAEDF1 !important;
    // border-radius: 5rem !important;
  }
  .table-striped-rows {
    background-color:  transparent !important;
  }

  .ant-table-pagination.ant-pagination{
    width: 100%;
    text-align: ${({ theme }) => (!theme.rtl ? 'right' : 'left')};
    border-top: 1px solid ${({ theme }) => theme['border-color-light']};
    margin-top: 0 !important;
    padding-top: 30px;
    @media only screen and (max-width: 767px){
      text-align: center;
    }
  }
  .contact-table{
    table{
      tr{
        th{
          &:first-child{
            ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 20px;
          }
          &:last-child{
            ${({ theme }) => (theme.rtl ? 'padding-left' : 'padding-right')}: 20px;
          }
        }
      }
      tbody{
        .customer-name{
            width: 100px !important;
            max-width: 150px !important;
            
        }
      }
      .table-actions{
        button{
          width: auto;
          height: auto;
          padding: 0;
          background-color: transparent;
          &:hover{
            background-color: transparent;
          }
          &.ant-btn-primary{
            &:hover{
              color: #ADB4D2;
            }
          }
        }
      }
      tbody >tr.ant-table-row-selected >td{
        background-color: ${({ theme }) => theme['primary-color']}10;
      }
    }
  }
`;

const TableWrapper = Styled.div`
    .ant-pagination-prev, .ant-pagination-next {
        color: #255CA3 !important;
        background: transparent !important;
        line-height: 28px !important;
        transform: rotateY(${({ theme }) => (theme.rtl ? '180deg' : '0deg')})
    }
    .ant-pagination-item{
        background: transparent !important;
        a{
            color: #255CA3 !important;
        }
    }
    .ant-table table{
        text-align: ${({ theme }) => (!theme.rtl ? 'left' : 'right')};
        background: transparent !important;
    }
    .ant-table-thead > tr > th{
        text-align: ${({ theme }) => (!theme.rtl ? 'left' : 'right')};
        background: transparent !important;
        border-radius: 0 !important;

    }
    span.anticon.anticon-right{
        transform: rotateY(${({ theme }) => (theme.rtl ? '180deg' : '0deg')})
    }
    span.anticon.anticon-left{
        transform: rotateY(${({ theme }) => (theme.rtl ? '180deg' : '0deg')})
    }
    &.table-order,
    &.table-seller,
    &.table-data-view{
        .ant-table-selection{
            .ant-checkbox-indeterminate{
                .ant-checkbox-inner{
                    background: ${({ theme }) => theme['primary-color']};
                    border-color: ${({ theme }) => theme['primary-color']};
                    &:after{
                        height: 2px;
                        background-color: #fff;
                    }
                }
            }
        }
        .ant-table-container{
            padding-bottom: 25px;
            border-bottom: 1px solid ${({ theme }) => theme['border-color-light']};
        }
        tbody{
            
            tr{
                &:hover{
                    td{
                        background: ${({ theme }) => theme['bg-color-light']};
                    }
                }
                td{
                    .product-id{
                        max-width: 60px;
                        text-align: ${({ theme }) => (theme.rtl ? 'left' : 'right')};
                    }
                    .customer-name{
                        width: 100px !important;
                        max-width: 150px !important;
                        
                    }
                }
            }
        }
        .ant-pagination{
            margin-top: 25px !important;
        }
    }
    &.table-data-view{
        .ant-table-container{
            padding-bottom: 0;
        }
        table{
            thead{
                background: transparent !important;
                border-radius: 0 !important;

                th{
                    padding: 15px 16px;
                    border-radius: 0 !important;

                }
            }
            tbody{
                
                td{
                  
                    padding: 11px 16px;
                    .record-img{
                        img{
                            max-width: 38px;
                            border-radius: 50%;
                            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 12px;
                        }
                    }
                    .customer-name{
                        width: 100px !important;
                        max-width: 150px !important;
                        
                    }
                    .record-location{
                        display: block;
                        font-size: 12px;
                        font-weight: 400;
                        color: ${({ theme }) => theme['light-color']};
                    }
                    .status{
                        font-weight: 500;
                        text-transform: capitalize;
                        &.active{
                            color: ${({ theme }) => theme['success-color']};
                            background: ${({ theme }) => theme['success-color']}10;
                        }
                        &.deactivated{
                            color: ${({ theme }) => theme['warning-color']};
                            background: ${({ theme }) => theme['warning-color']}10;
                        }
                        &.blocked{
                            color: ${({ theme }) => theme['danger-color']};
                            background: ${({ theme }) => theme['danger-color']}10;
                        }
                    }
                    .table-actions{
                        a{
                            svg, i{
                                width: 16px;
                                color: ${({ theme }) => theme['extra-light-color']};
                            }
                            &.edit{
                                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
                              &:hover{
                                svg,
                                i{
                                    color: ${({ theme }) => theme['info-color']};
                                }
                              }  
                            }
                            &.delete{
                              &:hover{
                                svg,
                                i{
                                    color: ${({ theme }) => theme['danger-color']};
                                }
                              }  
                            }
                        }
                    }
                }
            }
        }
    }
    table{
        thead{
            background: transparent !important;
            border-radius: 0 !important;

            tr{
                border-radius: 0 !important;
                th{
                    border-bottom: solid 2px #80aadc !important;
                    border-radius: 0 !important;
                   
                    &:last-child{
                        text-align: 'left';
                    }
                    color: black;
                    background: ${({ theme }) => theme['bg-color-light']};
                    border-top: 1px solid ${({ theme }) => theme['border-color-light']};
                    border-bottom: 1px solid ${({ theme }) => theme['border-color-light']};
                    &:first-child{
                        ${({ theme }) => (!theme.rtl ? 'border-left' : 'border-right')}: 1px solid ${({ theme }) =>
  theme['border-color-light']};
                        border-radius: ${({ theme }) => (!theme.rtl ? '10px 0 0 10px' : '0 10px 10px 0')} !important;
                    }
                    &:last-child{
                        ${({ theme }) => (theme.rtl ? 'border-left' : 'border-right')}: 1px solid ${({ theme }) =>
  theme['border-color-light']};
                        border-radius: ${({ theme }) => (!theme.rtl ? '0 10px 10px 0' : '10px 0 0 10px')} !important;
                    }
                }
            }
        }
        tbody{
           
            tr{
                &:hover{
                    td{
                        background: ${({ theme }) => theme['bg-color-light']};
                    }
                }
                &.ant-table-row-selected{
                    &:hover{
                        td{
                            background: ${({ theme }) => theme['bg-color-light']};
                        }
                    }
                    td{
                        background: ${({ theme }) => theme['bg-color-light']};
                    }
                }
                .customer-name{
                    width: 100px !important;
                    max-width: 150px !important;
                    
                }
                td{
                    border: 0 none;
                    font-weight: 400;
                    color: ${({ theme }) => theme['dark-color']};
                    &:first-child{
                        border-radius: ${({ theme }) => (!theme.rtl ? '10px 0 0 10px' : '0 10px 10px 0')} !important;
                    }
                    &:last-child{
                        border-radius: ${({ theme }) => (!theme.rtl ? '0 10px 10px 0' : '10px 0 0 10px')} !important;
                    }
                    span{
                        display: block;
                    }
                    .order-id{
                        min-width: 128px;
                    }
                    .customer-name{
                        width: 100px !important;
                        max-width: 150px !important;
                        
                    }
                    .status{
                        min-width: 175px;
                    }
                    .ordered-amount{
                        min-width: 175px;
                    }
                    .ordered-date{
                        min-width: 165px;
                    }
                    .table-actions{
                        min-width: 60px;
                    }
                }
            }
        }
        .table-actions{
            text-align: 'left' ;
            min-width: 150px !important;
            button{
                height: 40px;
                padding: 0 11px;
                background: transparent;
                border: 0 none;
                color: ${({ theme }) => theme['extra-light-color']};
                &:hover{
                    &.ant-btn-primary{
                        color: ${({ theme }) => theme['primary-color']};
                        background: ${({ theme }) => theme['primary-color']};
                    }
                    &.ant-btn-info{
                        color: ${({ theme }) => theme['info-color']};
                        background: ${({ theme }) => theme['info-color']};
                    }
                    &.ant-btn-danger{
                        color: ${({ theme }) => theme['danger-color']};
                        background: ${({ theme }) => theme['danger-color']};
                    }
                }
            }
        }
        .seller-info{
            img{
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 12px;
            }
        }
        .user-info{
            display: flex;
            align-items: center;
            figure{
                margin: 0 8px 0;
            }
            .user-name{
                margin-bottom: 4px;
                font-weight: 500;
            }
            .user-designation{
                font-size: 12px;
                font-weight: 400;
                color: ${({ theme }) => theme['light-color']};
            }
        }
    }    
`;

const DragDropStyle = Styled.div`
    .ant-card-body{
        padding: 15px !important;
    }
    table{
        thead{
            display: none;
        }
        tbody{
            >tr{
                &:not(:last-child){
                    td{
                         border-bottom: 1px solid ${({ theme }) => theme['border-color-normal']} !important;
                    }
                 }
                 &:hover{
                     td{
                         background-color: transparent !important;
                     }
                 }
                >td{
                    font-size: 14px;
                    &:first-child,
                    &:last-child{
                        border-radius: 0 !important;
                    }
                }
            }
        }
        tr{
            td{
                &.drag-visible{
                    svg,
                    img{
                        width: 20px;
                    }
                    svg,
                    i{
                        color: ${({ theme }) => theme['extra-light-color']} !important;
                    }
                }
            }
        }
        .user-info{
            .user-name{
                font-size: 14px;
                margin-left: 8px;
            }
        }
    }
`;

const ImportStyleWrap = Styled.div`
    .ant-upload.ant-upload-drag{
        background-color: #fff;
        min-height: 280px;
        display: flex;
        align-items: center;
        border-color: #C6D0DC;
        border-radius: 10px;
        .ant-upload-drag-icon{
            svg,
            i{
                color: #ADB4D2;
            }
        }
    }
    .sDash_import-inner{
        .ant-upload-text{
            font-size: 20px;
            font-weight: 500;
        }
        .ant-upload-hint{
            margin-left: 4px;
            span{
                color: ${({ theme }) => theme['primary-color']};
            }
        }
        .ant-upload-list{
            .ant-upload-list-item{
                background-color: #fff;
                border-color: ${({ theme }) => theme['border-color-normal']};
            }
            .ant-upload-list-item-card-actions.picture{
                top: 18px;
                ${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 15px;
            }
        }
    }
`;

const ExportStyleWrap = Styled.div`
    .sDash_export-box{
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        @media only screen and (max-width: 575px){
            flex-flow: column;
        }
        .btn-export{
            height: 44px; 
            @media only screen and (max-width: 575px){
                margin-bottom: 20px;
            }
        }
        .ant-select{
            width: auto !important;
            .ant-select-selector{
                padding: 0 20px;
                .ant-select-selection-search-input{
                    border-radius: 20px;
                }
            }
        }
    }
    .sDash_export-file-table{
        .ant-table-content{
            .ant-table-thead{
                border: 1px solid ${({ theme }) => theme['border-color-light']};
                border-radius: 10px;
                tr{
                    th{
                        background-color: ${({ theme }) => theme['bg-color-light']};
                        border: 0 none;
                        color: ${({ theme }) => theme['gray-color']};
                        &:first-child{
                            border-radius: 10px 0 0 10px;
                        }
                        &:last-child{
                            border-radius: 0 10px 10px 0;
                        }
                    }
                }
            }
            .ant-table-tbody{
                tr{
                    &:hover{
                        box-shadow: 0 15px 50px #9299B820;
                        td{
                            background-color: #fff !important;
                        }
                    }
                    td{
                        border: 0 none;
                        padding: 22px 25px;
                        background-color: #fff;
                    }
                }
            }
        }
    }
`;
const LandingPage = Styled.div`

    width: 100%!important;

    .ant-anchor-ink::before{
        background-color: none !important;
        display: none!important;
    }
    .flippy-front, .flippy-back{
        padding: 0 !important;
        border-radius: 10px !important;learn-row
    }
    
    .ant-card-body{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px!important;
    }
    .ant-card{
        box-shadow: rgba(60, 64, 67, 0.2) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }
    .login-btn{
        background: rgb(247,6,63);
        background: linear-gradient(90deg, rgba(247,6,63,1) 0%, rgba(250,0,123,1) 0%, rgba(115,0,255,1) 100%); 
        color: white;
        margin-right: 10px; 
        border: none; 
        box-shadow: none;
        width: 100px;
        height: 35px;
        border-radius: 2rem;
    }
    .ant-anchor-ink::before{
        background-color: none !important;
    }
    .landing-page-style{
        background: url("${LandingPageBg}");
        background-size: 150% 150%;
        background-position: bottom;
        background-repeat: no-repeat;
        color: white;
        border: none;
        display: flex;
        justify-content: center;
        height: auto;
        padding: 10px 100px;
        flex-direction: column;
        
        .ant-menu{
            background: none!important;
        }
        .ant-anchor-link{
            margin-top: 15px;
            padding: 0!important;
        }
        .ant-anchor-ink::before{
            background: none !important;
            display: none!important;
        }

        .header-menu{
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .sign-up-btn{
                background: #0055b8;
                color: white;
                border: none;
                margin-right: 20px;
                border-radius: 50px;
            }
            a{
                margin-right: 20px;
                color: #0055b8;
                font-size: 14px;
                line-spacing: 22px;
            }
            .menu-btn{
                margin-right: 20px;
                color: #0055b8;
                border: none!important;
                box-shadow: none!important;
            }
            .menuBar {
              width: 100%;
              }
              .menuCon {
                float: right;
                width: 100%;
              }
              
              .menuCon .ant-menu-item {
                padding: 0px 5px;
              }
              
              .menuCon .ant-menu-submenu-title {
                padding: 10px 20px;
              }
              
              .menuCon .ant-menu-item a,
              .menuCon .ant-menu-submenu-title a {
                padding: 10px 15px;
              }
              
              .menuCon .ant-menu-horizontal {
                border-bottom: none;
                display: flex;
                justify-content: flex-end;
              }
              
              
              .menuCon .rightMenu {
                float: right;
                width: 100%;
              }
              
              .barsMenu {
                float: right;
                height: 32px;
                padding: 6px;
                margin-top: 8px;
                display: none;
                background: none;
                border: none;
              }
              
              .barsBtn {
                display: block;
                width: 20px;
                height: 2px;
                background: #1890ff;
                position: relative;
              }
              
              .barsBtn:after,
              .barsBtn:before {
                content: attr(x);
                width: 20px;
                position: absolute;
                top: -6px;
                left: 0;
                height: 2px;
                background: #1890ff;
              }
              
              .barsBtn:after {
                top: auto;
                bottom: -6px;
              }
              .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
                  border-bottom: none!important;
              }
              .ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after{
                  border: none!important;
              }
              .ant-drawer-body {
                padding: 0;
              }
              
              .barsMenu>span {
                display: block;
              }
              
              .ant-drawer-body .ant-menu-horizontal>.ant-menu-item,
              .ant-drawer-body .ant-menu-horizontal>.ant-menu-submenu {
                display: inline-block;
                width: 100%;
              }
              
              .ant-drawer-body .ant-menu-horizontal {
                border-bottom: none;
              }
              
              @media (max-width: 767px) {
                .barsMenu {
                  display: inline-block;
                }
              
                .leftMenu,
                .rightMenu {
                  display: none;
                }
                .menuCon .ant-menu-item,
                .menuCon .ant-menu-submenu-title {
                  padding: 1px 20px;
                }
                .ant-anchor-link{
                    margin-left: -15px!important;
                }
                .ant-anchor-ink::before{
                    display: none!important;
                    content: ''!important;
                    background-color: none !important;
                    width: 0!important;
                }
              }
        }
        .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
            border-right: none!important;
        }
        .w-100{
            width: 100%!important;
        }
        .cipla-logo{
            width: 250px;
            object-fit: cover;
            margin-left: -20px;
        }
        .topRowHeader{
            background-color:red
        }
          
        .header-main{
            display: flex; 
            flex-direction: row;
            justify-content: center;
        }
        .header-text{
            font-size: 27px; 
            font-weight: 500;
            margin-bottom: 10px;
            color: #272B41;
            line-height: 1.2em;
        }
        .left-header-div{
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;
        }
        .header-sub-text{
            font-size: 14px; 
            line-height: 25px;
            color: #272B41;
        }
        
    }
    .new-account-btn{
        background: transparent; 
        color: #0055b8; 
        border: none;
        box-shadow: none;
        font-weight: 600;
    }
    .category-main{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3{
            color: #272B41;
            font-weight: 900;
        }

        p{
            font-size: 12px;
            text-align: center;
            margin-top: 10px;
            width: 70%;
            color: #272B41;
        }
    }
    .ant-carousel{
        width: 100%!important;
        margin-top: 20px;
    }
    .ant-carousel .slick-dots li button{
        background: blue;
    }
    
   
    .category-row{
        padding: 50px 100px;
        background: white;
        border: none;

       h3{
        font-weight: bold; 
       }

       .category-title{
        font-weight: bold;
        color: #255CA3;
       }

       .therapeuticCategoryImg{
            position: relative;
            bottom: 0.9rem;
            right: 0.7rem;
       }

        .card{
            color: black;
            border: 1px solid lightgray;
            border-radius: 5px;
            margin-right: 10px;
            font-size: 12px;
            padding: 15px 10px;
            background: white;
            height: 100%;
            
           
            p{
                text-align: left;
                color: #272B41;                
                width: 100%;
                margin-top: 0!important;
                word-break: break-word;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; 
                -webkit-box-orient: vertical;
            }
        }

        .carousel-col{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .ant-carousel .slick-next::before{
            content: '>';
            top: 3px;
            position: absolute;
            left: 15px;
        }
        .ant-carousel .slick-prev::before{
            content: '<';
            top: 3px;
            position: absolute;
            right: 15px;
        }
        .ant-carousel .slick-next{
            right: -10px;
        }
        .ant-carousel .slick-prev{
            left: -20px;
        }
        .ant-carousel .slick-prev, .ant-carousel .slick-next{
            top: 43%;
        }
        .mt-15{
            margin-top: 25px;
            font-size: 12px;
            font-weight: 600;
        }

        .slick-active.slick-current .card{
            // background: green !important;
            background: #80AADC;
        }

        .slick-active .card{
            background-image: linear-gradient(90deg, rgba(128,170,220,1) 0%, rgba(128,170,220,1) 0%, rgba(79,180,188,1) 100%);
        }
    }
    .learn-row{
        padding: 50px 100px;
        background: white;
        .description-item{
            background: white;
        }
        h3{
            font-weight: bold; 
           }

        .ant-card-body{
            height: 150px;
            padding-right: 10px 20px!important;
            text-align: left;
            font-size: 11px;
        }
        b{
            color: #255CA3;
            font-weight: bold;
            font-size: 16px;
        }
        .ant-card{
            margin-bottom: 10px;
            box-shadow: none;
            border: none;

            h3{
                font-weight: bold;
                color: #255CA3;
                align-self: baseline
            }
            b{
                color: #255CA3;
                font-weight: bold;
                font-size: 16px;
            }
            
        }

        .learnIcons{
            width: 3rem;
            height: 3rem;
            align-self: self-start;
        }

        .flippy-front, .flippy-back {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 1em;
            transform: rotateY(0deg);
            background: transparent;
            box-shadow: none !important;
            backface-visibility: hidden;
            transform-style: preserve-3d;
        }

        .card-row{
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            position: relative;
        }
        
    }
    .course-row{
        background: white;
        padding: 30px 100px;

        
        h4{
            color: #272B41;
            font-weight: 600;
        }

        h3{
            align-self: left;
            font-weight: bold;
        }

        p{
            width: 90%;
            text-align: left;
            font-size: 12px;
            color: #272B41;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; 
            -webkit-box-orient: vertical;
        }
        .course-card{
            border: 1px solid lightgray;
            height: 100%;
           
        }
        .h-250{
            height: 200px;
        }
        .course-text{
            display: flex;
            flex-direction: column;
            
            padding: 10px;
           
        }
        .course-btn{
            background: #64A2D9;
            color: white;
            width: fit-content;
            margin-top: 20px;
            font-size: 12px;
            border-radius: 10rem;
        }
        .course-list-btn{
            background: #64A2D9;
            color: white;
            width: fit-content;
            font-size: 12px;
            border-radius: 10rem;
            border-top-left-radius: 10px;
        }
        .rewards-list {
            position: absolute;
            top: 0;
            left: 0;
            background: transparent;
            z-index: 100;
            color: white;
        }
        .ant-carousel .slick-next::before{
            content: '>';
            top: 3px;
            position: absolute;
            left: 15px;
        }
        .ant-carousel .slick-prev::before{
            content: '<';
            top: 3px;
            position: absolute;
            right: 15px;
        }
        .ant-carousel .slick-next{
            right: -20px;
        }
        .ant-carousel .slick-prev{
            left: -20px;
        }
        .ant-carousel .slick-prev, .ant-carousel .slick-next{
            top: 43%;
        }
        .rewardSticker{
            border: solid 1px #714194;
            border-top-left-radius: 10px;
            position: absolute;
            left: -1px;
            top: -9.4rem;
        }
        .cdp-span{
            color: White;
            font-size: 12px;
            font-weight: 500;
            background: #714194;
            text-align: center;
            padding: 4px;
            border: solid 1px #714194;
            border-top-left-radius: 10px;
            
        }
        .reward-span{
            color: White;
            font-size: 12px;
            font-weight: 500;
            background: #714194;
            text-align: center;
            padding: 4px;
            border: solid 1px #714194;
            border-top-left-radius: 10px;
        }
        .course-img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .ant-card{
            box-shadow: none !important;
        }
    }
    .faq-row{
        padding: 30px 100px;
        background: white;

        h3{
            font-weight: bold; 
        }

        .yes-icon{
            border-color: #36bd82;
            color: #36bd82;

            span{
                margin-left: 10px;
            }
        }
        .no-icon{
            border-color: #FA8B0C;
            color: #FA8B0C;

            span{
                margin-left: 10px;
            }
        }
        .ant-card{
            box-shadow:none !important;
            .ant-card-body{
              h1{
                font-weight: 500;
              }
            }
          }
          .ant-collapse{
            margin-top: 25px;
            width: 95%;
            &.ant-collapse-borderless{
              background: #fff;
            }
            &.ant-collapse-icon-position-left{
              .ant-collapse-header{
                color: ${({ theme }) => theme['dark-color']} !important;
              }
            }
            border-radius: none !important;
          }
          .ant-collapse-item{
            // border: 1px solid ${({ theme }) => theme['border-color-light']} !important;
            border-bottom: solid 2px !important;
            border-image: linear-gradient(90deg, rgba(6,112,247,1) 0%, rgba(0,193,243,1) 0%, rgba(0,236,162,1) 100%) 1;
            border-radius: none !important;
            .ant-collapse-header{
              font-weight: 700;
              font-size: 12px;
              background-color: #fff;
              padding: 18px 25px !important;
            //   border-radius: 5px !important;
            border-radius: none !important;
              @media only screen and (max-width: 575px){        
                padding: ${({ theme }) => (!theme.rtl ? '15px 45px 15px 15px' : '15px 15px 15px 45px')} !important;
              }
              .ant-collapse-arrow{
                ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: auto !important;
                ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 25px !important;
                top: 22px !important;
                transform: translateY(0) !important;
              }
            }
          }
          .ant-collapse-item.ant-collapse-item-active{
            border-radius: none !important;
            border-image: linear-gradient(90deg, rgba(247,6,51,1) 0%, rgba(243,0,156,1) 0%, rgba(206,0,236,1) 100%) 1;
          }
          svg.feather.feather-minus.ant-collapse-arrow{
            background: #f4f1f3;
            border-radius: 100%;
          }
          .ant-collapse-content{
           
            .ant-collapse-content-box{
              border-top: 1px solid ${({ theme }) => theme['border-color-light']} !important;
              padding: 20px 25px 30px !important;
              P{
                font-size: 12px;
                margin-bottom: 35px;
                line-height: 1.667;
                color: ${({ theme }) => theme['gray-color']};
              }
              h1,
              h2,
              h3,
              h4,
              h5,
              h6{
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 12px;
                color: ${({ theme }) => theme['dark-color']};
              }
              .panel-actions{
                button{
                  height: 36px;
                  padding: 0 15px;
                  &:not(:last-child){
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
                  }
                }
              }
            }
          }
          
    }
    .p-30{
        padding: 30px 0;
    }
    .ant-layout-footer{
        background: white!important;
    }
    .footer-row{
        background: linear-gradient(#255CA3, #64A2D9);
        display: flex;
        justify-content: space-between;
        .cipla-footer-logo{
            width: 200px;
            object-fit: cover;
            margin-left: -30px;
        }
        .align-center{
            padding: 0 0 0 5rem;
            display: flex;
            align-items: center;
        }
        .footer-text{
            padding: 0 5rem 0 0;
            font-size: 11px;
            display: flex;
            color: #272B41;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
        }
    }

    @media only screen and (max-width: 600px) {
        .header-text{
            font-size:24px !important;
        }
        .header-img{
            width: 100%;
        }
        .landing-page-style{
            padding: 10px 30px!important;
        }
        .category-row{
            padding: 20px 30px!important;
        }
        .learn-row{
            padding: 20px 30px!important;
        }
        .footer-row{
            padding: 20px 30px!important;
        }
        .faq-row{
            padding: 20px 30px!important;
        }
        .course-row{
            padding: 20px 20px!important;
        }
        .align-center{
            display: flex!important;
            align-items: center!important;
            justify-content: center!important;
            margin-bottom: 10px;
        }
        .footer-text{
            margin-top: 15px;
            display: flex!important;
            flex-direction: column;
            justify-content: center;
            align-items: center!important;
        }
        .ant-anchor-link{
            margin-left: -15px!important;
        }
        .ant-anchor-ink::before{
            display: none!important;
            content: ''!important;
            background-color: none !important;
      }
      .h-250{
          height: 430px !important;
      }
    }
    @media screen and (min-width: 767px) and (max-width: 1200px){
        .header-col{
            width: 100%;
            display: flex;
        }
        .header-img{
            max-width: 100%;
            height: auto;
            display: block;
            object-fit: contain;
        }
        .new-account-btn{
            margin-left: -10px;
        }
        .ant-carousel .slick-dots{
            display: none!important;
        }
`;

const MainFAQs = Styled.div`
.faq-row{
    padding: 30px 100px;
    background: transparent;

    h3{
        font-weight: bold; 
    }

    .yes-icon{
        border-color: #36bd82;
        color: #36bd82;

        span{
            margin-left: 10px;
        }
    }
    .no-icon{
        border-color: #FA8B0C;
        color: #FA8B0C;

        span{
            margin-left: 10px;
        }
    }
    .ant-card{
        box-shadow:none !important;
        .ant-card-body{
          h1{
            font-weight: 500;
          }
        }
      }
      .ant-collapse{
        margin-top: 25px;
        width: 95%;
        &.ant-collapse-borderless{
          background: #fff;
        }
        &.ant-collapse-icon-position-left{
          .ant-collapse-header{
            color: ${({ theme }) => theme['dark-color']} !important;
          }
        }
        border-radius: none !important;
      }
      .ant-collapse-item{
        // border: 1px solid ${({ theme }) => theme['border-color-light']} !important;
        border-bottom: solid 2px !important;
        border-image: linear-gradient(90deg, rgba(6,112,247,1) 0%, rgba(0,193,243,1) 0%, rgba(0,236,162,1) 100%) 1;
        border-radius: none !important;
        .ant-collapse-header{
          font-weight: 700;
          font-size: 12px;
          background-color: #fff;
          padding: 18px 25px !important;
        //   border-radius: 5px !important;
        border-radius: none !important;
          @media only screen and (max-width: 575px){        
            padding: ${({ theme }) => (!theme.rtl ? '15px 45px 15px 15px' : '15px 15px 15px 45px')} !important;
          }
          .ant-collapse-arrow{
            ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: auto !important;
            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 25px !important;
            top: 22px !important;
            transform: translateY(0) !important;
          }
        }
      }
      .ant-collapse-item.ant-collapse-item-active{
        border-radius: none !important;
        border-image: linear-gradient(90deg, rgba(247,6,51,1) 0%, rgba(243,0,156,1) 0%, rgba(206,0,236,1) 100%) 1;
        .ant-collapse-content-box{
            background: '#DCC4DC33';
        }
      }
      svg.feather.feather-minus.ant-collapse-arrow{
        background: #f4f1f3;
        border-radius: 100%;
      }
      .ant-collapse-content{
       
        .ant-collapse-content-box{
          border-top: 1px solid ${({ theme }) => theme['border-color-light']} !important;
          padding: 20px 25px 30px !important;
          P{
            font-size: 12px;
            margin-bottom: 35px;
            line-height: 1.667;
            color: ${({ theme }) => theme['gray-color']};
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6{
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 12px;
            color: ${({ theme }) => theme['dark-color']};
          }
          .panel-actions{
            button{
              height: 36px;
              padding: 0 15px;
              &:not(:last-child){
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
              }
            }
          }
        }
      }     
}
`;

const KnowledgePage = Styled.div`
      .knowledge-main{
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
      }
      .search-form{
          width: 60%;
      }
      .knowledge-title{
          font-weight: bolder;
          font-size: 28px;
          margin-bottom: 15px;
      }
      .knowledge-div{
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          minHeight: calc(100vh - 450px);
      }
      .ant-input-suffix {
        margin-left: 4px;
        font-size: 16px!important;
        background: #269EFF!important;
        color: white!important;
        padding: 6px!important;
        border-radius: 5px!important;
    }
    .form-inner{
        display: flex;
        flex-direction: row;
    }
    .search-input{
        width:100%;
    }
    .btn-search{
        margin-left: -90px;
    }
    .card-img{
        width: 100%;
        
    }
    .ant-card-head{
        padding-left: 0!important;
    }
   .card-text{
       margin-left: 15px;
       overflow-wrap: break-word
       color: gray;
    }
    .card-row{
        padding: 10px 30px;
    }
    .card-title{
        font-size: 16px;
        font-weight: bold;
        color: black;
    }
    .card-desc{
        margin-top: 10px;
    }
    .popular-topics li{
        display: inline;
        margin-left: 10px;
    }
    .hyIOZv{
        margin-bottom: 0!important;
    }
    .ant-list-items li:not(:last-child){
        margin-bottom:10px;
    }
    .article-row{
        h3{
            font-weight: bold;
            margin-bottom: 5px;

            span{
                width: 20px;
                height: 20px;
                background: #2DB6A1;
                color: white;
                border-radius: 50px;
                padding: 5px 8px;
                font-size: 12px;
            }
        }
        .ant-list-lg .ant-list-item{
            padding: 0!important;
            cursor: pointer;
        }
        .ant-card{
            background: none!important;
        }
        .ant-list-split .ant-list-item{
            border-bottom: none!important;
        }
        span{
            margin-right: 10px;
        }
        p{
            margin-bottom: 0!important;
        }
    }
    .faq-img{
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .ant-list-empty-text{
        display: none;
    }
    @media only screen and (max-width: 767px) {
        .search-form{
            width: 100%;
        }
        .knowledge-div{
            minHeight: calc(100vh - 450px);
        }
    }
    
`;
const SidebarSingle = Styled.div`
    h1{
        font-size: 15px;
        margin-bottom: 20px;
        color: ${({ theme }) => theme['dark-color']};
    }
    .ant-slider{
        margin-bottom: 15px;
    }
    .price-range-text{
        font-weight: 500;
    }
    .atbd-category-list{
        li{
            &:not(:last-child){
                margin-bottom: 10px;
            }
            a{
                width: 100%
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                color: ${({ theme }) => theme['gray-color']};
                .category-count{
                    font-size: 12px;
                    color: ${({ theme }) => theme['light-color']};
                }
            }
        }
    }
    .btn-seeMore{
        font-size: 13px;
        font-weight: 500;
        display: inline-block;
        margin-top: 8px;
        color: ${({ theme }) => theme['primary-color']};
    }
    .ant-checkbox-group{
        .ant-checkbox-group-item{
            display: flex;
            align-items: center;
            color: ${({ theme }) => theme['gray-color']};
            &:not(:last-child){
                margin-bottom: 10px;
            }
            &.ant-checkbox-wrapper{
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 0;
            }
            >span + span{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                ${({ theme }) => (theme.rtl ? 'padding-left' : 'padding-right')}: 0;
                .brand-count{
                    font-size: 12px;
                    color: ${({ theme }) => theme['light-color']};
                }
                .rating-left{
                    margin-top: -4px;
                    min-width: 150px;
                    @media only screen and (max-width: 1792px){
                        min-width: 130px;
                    }
                    .ant-rate{
                        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
                    }
                }
                .rating-right{
                    text-align: ${({ theme }) => (theme.rtl ? 'left' : 'right')};
                    min-width: 60px;
                    font-size: 12px;
                    color: ${({ theme }) => theme['light-color']};
                    @media only screen and (max-width: 1792px){
                        min-width: 26.5px;
                    }
                }
            }
        }
    }
    
    .ant-checkbox-group{
        width: 100%;
    }
    .filter-head{
        font-size: 15px;
        font-weight: 500;
        padding: 10px 20px; 
        border-bottom: 1px #E3E6EF solid;
        color: #272B41;

        span{
            margin-left: 10px;
        }
    }
    .filter-head-title{
        font-size: 13px;
        font-weight: 500;
        padding: 0px 20px; 
        color: #272B41;
        margin : 15px 0!important;
    }
    .ant-checkbox-group{
        padding: 0 20px 20px 20px;
        color: #272B41;
        margin-top: 0!important;
    }
    .ant-checkbox-group .ant-checkbox-group-item >span + span .brand-count{
        color: #272B41!important;
    }
`;

const TopToolBox = Styled.div`

    padding: 0px 0px 20px 20px;
    background-color: #F4F5F7;

    .ant-card-body{
        padding: 0 !important;
    }
    /* // Toolbox Common Styles */
    .ant-row{
        align-items: center;
    }
    .table-toolbox-menu{
        margin: -10px;
        color: ${({ theme }) => theme['gray-color']};
        @media only screen and (max-width: 1599px){
            text-align: ${({ theme }) => (theme.rtl ? 'left' : 'right')};
        }
        @media only screen and (max-width: 991px){
            margin-top: 20px;
        }
        .ant-radio-button-wrapper{
            height: 40px;
            line-height: 40px;
            padding: 0 12.5px;
             &.active{
                 span{
                     color: ${({ theme }) => theme['primary-color']};
                 }
             }
        }
        @media only screen and (max-width: 991px){
            text-align: center;
        }
        .toolbox-menu-title,
        .ant-radio-group-outline{
            margin: 10px;
        }
    }
    .ant-select{
        @media only screen and (max-width: 1599px){
            margin-bottom: 20px;
        }
        @media only screen and (max-width: 767px){
            max-width: 350px;
            margin: 0 auto 20px;
        }
        .ant-select-selection-search{
            @media only screen and (max-width: 991px){
                width: 100% !important;
            }
            // .ant-select-selection-search-input{
            //     min-width: 350px;
            //     @media only screen and (max-width: 1792px){
            //         min-width: 230px;
            //     }
            // }
        }
    }
    .search-result{
        margin: ${({ theme }) => (theme.rtl ? '0 25px 0 0' : '0 0 0 25px')};
        color: ${({ theme }) => theme['gray-color']};
        @media only screen and (max-width: 1599px){
            text-align: ${({ theme }) => (theme.rtl ? 'left' : 'right')};
            margin-bottom: 15px;
        }
        @media only screen and (max-width: 991px){
            text-align: center;
            margin-bottom: 18px;
        }
        @media only screen and (max-width: 991px){
            ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 0px;
        }
    }
    .ant-select-selector{
        height: 46px !important;
        .ant-select-selection-search-input{
            box-shadow: 0 5px 20px ${({ theme }) => theme['light-color']}3;
            border-radius: 23px;
            border: 0 none;
            input{
                height: 46px !important;
            }
        }
    }

    .ant-radio-group-outline{
        padding: 0 10px;
        border-radius: 5px;
        background: #fff;
        border: 1px solid ${({ theme }) => theme['border-color-normal']};
        @media only screen and (max-width: 1792px){
            padding: 0 5px;
        }
        @media only screen and (max-width: 991px){
            padding: 0;
        }
    }
    .ant-radio-button-wrapper{
        height: 30px;
        line-height: 32px;
        padding: 0 10px;
        color: #272B41;
        border-color: ${({ theme }) => theme['border-color-normal']};
        border: 0 none !important;
        // @media only screen and (max-width: 1792px){
        //     padding: 0 7.5px;
        // }
        // @media only screen and (max-width: 1599px){
        //     padding: 0 12.5px;
        // }
        &.ant-radio-button-wrapper-checked{
            &:focus-within{
                box-shadow: 0 0;
            }
        }
        &:not(:first-child){
            &:before{
                display: none;
            }
        }
        &:not(:last-child){
            &:after{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0px;
                display: block;
                box-sizing: content-box;
                width: 1px;
                height: 50%;
                padding: 1px 0;
                background-color: #F1F2F6;
                transition: background-color 0.3s;
                content: '';
                z-index: 1;
                @media only screen and (max-width: 479px){
                    display: none;
                }
            }
        }
        span{
            // color: ${({ theme }) => theme['light-color']};
            @media only screen and (max-width: 1792px){
                font-size: 11px;
            }
        }
         &.ant-radio-button-wrapper-checked{
             span{
                 color: ${({ theme }) => theme['primary-color']};
             }
         }
    }
    
    // Product Toolbox Styles.ant-radio-button-wrapper s
    .product-list-action{
        @media only screen and (max-width: 991px){
            flex-flow: column;
            justify-content: center;
        }
    }
    
    .product-list-action__tab{
        margin: 0px;
        color: ${({ theme }) => theme['gray-color']};
        
        @media only screen and (max-width: 767px){
            margin-bottom: 15px;
            text-align: center;
        }
        @media only screen and (max-width: 991px) and (min-width: 768px){
            margin: -10px -10px 0;
        }
        @media only screen and (max-width: 575px){
            margin: -6px -6px 0;
        }
        @media only screen and (max-width: 344px){
            .ant-radio-group-outline{
                margin-top: 8px;
                ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 0;;
            }
        }
        .toolbox-menu-title,
        .ant-radio-group{
            margin: 0 0 0 10px;
            font-size: 11px;
            @media only screen and (max-width: 575px){
                margin: 6px
            }
        }
    }

    .product-list-action__viewmode{
        display: flex;
        align-items: center;
        
        a{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: ${({ theme }) => theme['light-color']};
            box-shadow: 0 5px 20px ${({ theme }) => theme['light-color']}10;
            &.active{
                background-color: #fff;
                color: ${({ theme }) => theme['primary-color']};
            }
        }
    }

    .table-search-box{
        @media only screen and (max-width: 991px){
            max-width: 600px;
            margin: 0 auto;
        }
        .ant-select{
            margin-bottom: 0;
        }
        .ant-select-selection-search{
            width: 100% !important;
            .ant-select-selection-search-input {
                border-radius: 20px;
                border: 0 none;
                background: ${({ theme }) => theme['bg-color-light']};
                height: 40px;
                input{
                    background: ${({ theme }) => theme['bg-color-light']};
                    height: 40px !important;
                }
            }
        }
    }
    .table-toolbox-actions{
        text-align: ${({ theme }) => (theme.rtl ? 'left' : 'right')};
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media only screen and (max-width: 1599px){
            margin-top: 20px;
            justify-content: center !important;
            text-align: center !important;
        }
        button{
            padding: 0px 13.4px;
            height: 38px;
            font-size: 13px;
            font-weight: 500;
            border-radius: 6px;
            svg,
            i{
                color: #fff;
            }
            &{
                +button{
                    ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 8px;
                }
            }
        }
    }
    .carousel-left{
        border: 1px solid #E3E6EF;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-direction: column;
        text-align: center;

        h2{
            color: #0055b8;
            font-weight: 1000;
            font-size: 30px;
            margin-bottom: 0;
        }

        p{
            color: #50ACE3;
            font-size: 11px;
        }
    }
    .carousel-right{
        padding: 0 20px;

        h4{
            font-size: 12px;
        }
        span{
            font-size: 10px;
        }
    }
    .carousel-row{
        background: white; 
        padding: 30px 30px 
        border-radius: 5px;
        border: 1px solid white;

        .feature-img{
            width: 100%;
        }
    }
    .ant-carousel .slick-dots li.slick-active button{
        background: #0055b8
    }
    .ant-carousel .slick-dots li{
       background:  #E3E6EF;
    }
    .ant-carousel .slick-dots-bottom{
        bottom: -20px;
    }
    
    .product-list{
        margin-top: 30px;
        h3{
            font-weight: bold;
        }
        p{
            font-size: 14px;
            color:#272B41;
            margin-left: 10px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-clamp: 2;
          
        }
        span{
            margin: 0 0 10px 10px;
            border: solid 1px #64A2D9;
            padding: 10px 15px;
            border-radius: 10;
            border-radius: 3rem;
            font-weight: 350;
            color: white;
            background: #64A2D9;
        }
        .ant-card-head .ant-card-head-title{
            padding: 5px 0;
            font-size: 14px;
            color: #272B41;
        }
        .ant-card{
            background: white;
            box-shadow: 1px 2px 9px lightgray;
        }
        .ant-card-head{
            display: flex;
            border-bottom: none;
            padding-left: 10px !important;
        }
        .card-img{
            width: 100%;
            height: 100%;
            object-fit: scale-down;
           
        }
        .card-img-list{
            height: 100px;
        }
        .ant-card-meta-title{
            // border-top: 1px solid #E3E6EF;
            // padding: 20px;
            // font-size: 12px;
            // color: #50ACE3;
            margin: 0 0 15px 0;
            border: solid 1px #64A2D9;
            padding: 5px 0;
            border-radius: 10;
            border-radius: 3rem;
            font-weight: 350;
            color: white;
            font-size: 9;
            background: #64A2D9;
            width: 50%;
            text-align: center;
        }
        .space{
            justify-content: space-between;
            margin-top: 30px;
        }
        .p-15{
            padding: 0 10px 1.5px 10px;
            border-bottom: solid 2px;
            border-image: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%) 1;
        }
        .p-16{
            padding: 15px 10px 2px 10px;
        }
        .mb-10{
            margin-bottom: 30px
        }
        
    }
    @media screen and (max-width: 767px){
        padding: 0!important;

        .mb-20{
            margin-bottom: 20px;
        }
    }
`;
const GalleryCard = Styled.nav`
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 5px 20px ${({ theme }) => theme['dark-color']}03;
  figure{
    margin-bottom: 0;
  }
  .gallery-single-content{
    padding: 18px 25px 20px;
    .gallery-single-title{
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 2px;
    }
    p{
      font-size: 13px;
      margin-bottom: 0px;
      color: ${({ theme }) => theme['light-color']};
    }
  }
`;

const UserCard = Styled.div`
  text-align: center;
  .user-card{
    &.theme-list{
      .ant-card-body{
        padding: 30px 25px 30px 30px !important;
        @media only screen and (max-width: 479px){
          padding: 25px 20px 25px 20px !important;
        }
      }
      figure{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @media only screen and (max-width: 479px){
          flex-flow: column;
        }
        img{
          max-width: 80px;
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}:20px;
          @media only screen and (max-width: 479px){
            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}:0px;
          }
        }
      }
      figcaption{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left;
        @media only screen and (max-width: 379px){
          flex-flow: column;
        }
      }
      .card__content{
        p{
          max-width: 400px;
          font-size: 15px;
          color: ${({ theme }) => theme['gray-color']};
        }
        .card__designation{
            font-size: 13px;
            margin-bottom: 15px;
            color: ${({ theme }) => theme['light-color']};
        }
        .card-text{
          margin-bottom: 12px;
        }
        .card-info{
          margin-bottom: 0;
          .user-meta{
            font-size: 14px;
            strong{
              font-weight: 600;
              color: ${({ theme }) => theme['dark-color']};
            }
          }
          .user-meta + .user-meta{
            ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 20px;
          }
        }
      }
      .card__actions{
        text-align: ${({ theme }) => (theme.rtl ? 'left' : 'right')}
        @media only screen and (max-width: 379px){
          margin-top: 15px;
        }
        button{
          padding: 0px 19.05px;
          min-width: 114px;
        }
      }
    }
    &.theme-grid-2{
      .ant-card-body{
        padding: 0 !important;
      }
      figure{
        position: relative;
      }
      .user-card__img{
        margin-bottom: 0;
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        width: 132px;
        height: 132px;
        border-radius: 50%;
        background-color: #fff;
        z-index: 22;
        img{
          position: relative;
          top: 6px;
        }
      }
      .user-card__bg{
        background-size: cover !important;
        background-position: center !important;
        border-radius: 10px 10px 0 0;
      }
      .card__bottom{
        position: relative;
        background-color: #fff;
        top: -26px;
        padding-top: 102px;
        border-radius: 30px 30px 10px 10px;
      }
    }
    &.theme-grid-3{
      .ant-card{
        text-align: left;
      }
      .ant-card-body{
        padding: 0 !important;
      }
      .card__top,
      .card__content,
      .card__info{
        padding: 0 30px;
      }
      .card__top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 30px;
        margin-bottom: 10px;
        .user-card__img{
          margin-right: 12px;
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 12px;
          img{
            max-width: 70px;
          }
        }
        .user-card__info{
          width: 100%;
          position: relative;
          .action-more{
            position: absolute;
            right: 0;
            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
            top: 0;
            color: #ADB4D2;
          }
          .card__designation{
            margin-bottom: 0;
          }
        }
      }
      .card__content{
        p{
          font-size: 15px;
          margin-bottom: 26px;
        }
        .image-group{
          margin: -3px;
        }
        img{
          max-width: 34px;
          margin: 3px;
        }
      }
      .card__info{
        padding-bottom: 30px;
        padding-top: 18px;
        .ant-progress-inner{
          position: relative !important;
        }
        p{
          font-size: 12px;
          color: ${({ theme }) => theme['light-color']};
        }
        h2{
          font-size: 14px;
          font-weight: 500;
          margin-top: 4px;
          margin-bottom: 16px;
        }
        .info-line{
          display: flex;
          justify-content: space-between;
          .success{
            color: ${({ theme }) => theme['success-color']};
          }
        }
        .completed-count{
          margin-top: 4px;
        }
        .project-progress{
          display: flex;
          justify-content: space-between;
          .progress-percentage{
            ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 20px;
            span{
              font-size: 12px;
              color: ${({ theme }) => theme['gray-color']};
            }
          }
        }
      }
    }
  }
  .card{
    position: relative;
    box-shadow: 0 5px 20px ${({ theme }) => theme['light-color']}03;
    .ant-card-body{
      padding: 30px !important;
      div{
        position: static;
      }
    }
    figure{
      margin-bottom: 0;
      img{
        margin-bottom: 20px;
        width: 100%;
        border-radius: 50%;
        max-width: 150px;
      }      
    }
    .card__more_actions{
      position: absolute;
      ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 24px;
      top: 20px;
      line-height: .5;
      padding: 5px 3px;
      color: ${({ theme }) => theme['extra-light-color']};
      box-shadow: 0 10px 20px #9299B815;
      svg,
      img{
        width: 20px;
      }
    }
    .card__name{
      font-size: 16px;
      margin-bottom: 6px;
      font-weight: 500;
      a{
        color: ${({ theme }) => theme['dark-color']};
        &:hover{
          color: ${({ theme }) => theme['primary-color']};
        }
      }
    }
    .card__designation{
      font-size: 13px;
      margin-bottom: 25px;
      color: ${({ theme }) => theme['light-color']};
    }
    .card__social{
      margin-top: 16px;
      a{
        width: 38px;
        height: 38px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 10px 20px ${({ theme }) => theme['light-color']}15;
        background: #fff;
        &:not(:last-child){
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
        }
        &.facebook span.fa{
          color: #3B5998;
        }
        &.twitter span.fa{
          color: #1DA1F2;
        }
        &.dribble span.fa{
          color: #C2185B;
        }
        &.instagram span.fa{
          color: #FF0300;
        }
      }
    }
  }

  .user-card{
    .ant-card-body{
      padding: 30px 25px 18px 25px !important;
      @media only screen and (max-width: 1599px){
        padding: 20px  20px 20px !important;
      }
      @media only screen and (max-width: 767px){
        padding: 15px  15px 15px !important;
      }
    }
    figure{
      img{
        margin-bottom: 18px;
        max-width: 120px;
      }      
    }
    .card__actions{
      margin: -5px;
      .ant-btn-white{
        color: ${({ theme }) => theme['gray-color']};
        border: 1px solid ${({ theme }) => theme['border-color-light']};
        &:hover{
          border: 1px solid ${({ theme }) => theme['primary-color']};
        }
      }
      button{
        font-size: 13px;
        padding: 0px 22.7px;
        height: 38px;
        border-radius: 6px;
        box-shadow: 0px 3px 5px ${({ theme }) => theme['light-color']}05;
        margin: 5px;
        &:hover{
          color: #fff !important;
          background-color: ${({ theme }) => theme['primary-color']} !important;
          svg,
          i{
            color: #fff;
          }
        }
        svg,
        i{
          color: ${({ theme }) => theme['light-color']};
        }
      }
    }
    .card__info{
      padding-top: 20px;
      margin-top: 18px;
      border-top: 1px solid ${({ theme }) => theme['border-color-light']};
      .info-single{
        text-align: center;
      }
      .info-single__title{
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 4px;
      }
      p{
        margin-bottom: 0;
        color: ${({ theme }) => theme['light-color']};
      }
    }
  }
`;
const CompanyCard = Styled.div`
  text-align: center;
  .user-card{
    &.theme-list{
      .ant-card-body{
        padding: 30px 25px 30px 30px !important;
        @media only screen and (max-width: 479px){
          padding: 25px 20px 25px 20px !important;
        }
      }
      figure{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @media only screen and (max-width: 479px){
          flex-flow: column;
        }
        img{
          max-width: 80px;
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}:20px;
          @media only screen and (max-width: 479px){
            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}:0px;
          }
        }
      }
      figcaption{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left;
        @media only screen and (max-width: 379px){
          flex-flow: column;
        }
      }
      .card__content{
        p{
          max-width: 400px;
          font-size: 15px;
          color: ${({ theme }) => theme['gray-color']};
        }
        .card__designation{
            font-size: 13px;
            margin-bottom: 15px;
            color: ${({ theme }) => theme['light-color']};
        }
        .card-text{
          margin-bottom: 12px;
        }
        .card-info{
          margin-bottom: 0;
          .user-meta{
            font-size: 14px;
            strong{
              font-weight: 600;
              color: ${({ theme }) => theme['dark-color']};
            }
          }
          .user-meta + .user-meta{
            ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 20px;
          }
        }
      }
      .card__actions{
        text-align: ${({ theme }) => (theme.rtl ? 'left' : 'right')}
        @media only screen and (max-width: 379px){
          margin-top: 15px;
        }
        button{
          padding: 0px 19.05px;
          min-width: 114px;
        }
      }
    }
    &.theme-grid-2{
      .ant-card-body{
        padding: 0 !important;
      }
      figure{
        position: relative;
      }
      .user-card__img{
        margin-bottom: 0;
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        width: 132px;
        height: 132px;
        border-radius: 50%;
        background-color: #fff;
        z-index: 22;
        img{
          position: relative;
          top: 6px;
        }
      }
      .user-card__bg{
        background-size: cover !important;
        background-position: center !important;
        border-radius: 10px 10px 0 0;
      }
      .card__bottom{
        position: relative;
        background-color: #fff;
        top: -26px;
        padding-top: 102px;
        border-radius: 30px 30px 10px 10px;
      }
    }
    &.theme-grid-3{
      .ant-card{
        text-align: left;
      }
      .ant-card-body{
        padding: 0 !important;
      }
      .card__top,
      .card__content,
      .card__info{
        padding: 0 30px;
      }
      .card__top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 30px;
        margin-bottom: 10px;
        .user-card__img{
          margin-right: 12px;
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 12px;
          img{
            max-width: 70px;
          }
        }
        .user-card__info{
          width: 100%;
          position: relative;
          .action-more{
            position: absolute;
            right: 0;
            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
            top: 0;
            color: #ADB4D2;
          }
          .card__designation{
            margin-bottom: 0;
          }
        }
      }
      .card__content{
        p{
          font-size: 15px;
          margin-bottom: 26px;
        }
        .image-group{
          margin: -3px;
        }
        img{
          max-width: 34px;
          margin: 3px;
        }
      }
      .card__info{
        padding-bottom: 30px;
        padding-top: 18px;
        .ant-progress-inner{
          position: relative !important;
        }
        p{
          font-size: 12px;
          color: ${({ theme }) => theme['light-color']};
        }
        h2{
          font-size: 14px;
          font-weight: 500;
          margin-top: 4px;
          margin-bottom: 16px;
        }
        .info-line{
          display: flex;
          justify-content: space-between;
          .success{
            color: ${({ theme }) => theme['success-color']};
          }
        }
        .completed-count{
          margin-top: 4px;
        }
        .project-progress{
          display: flex;
          justify-content: space-between;
          .progress-percentage{
            ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 20px;
            span{
              font-size: 12px;
              color: ${({ theme }) => theme['gray-color']};
            }
          }
        }
      }
    }
  }
  .card{
    position: relative;
    box-shadow: 0 5px 20px ${({ theme }) => theme['light-color']}03;
    .ant-card-body{
      padding: 30px !important;
      div{
        position: static;
      }
    }
    figure{
      margin-bottom: 0;
      img{
        margin-bottom: 20px;
        width: 100%;
        border-radius: 50%;
        max-width: 150px;
      }      
    }
    .card__more_actions{
      position: absolute;
      ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 24px;
      top: 20px;
      line-height: .5;
      padding: 5px 3px;
      color: ${({ theme }) => theme['extra-light-color']};
      box-shadow: 0 10px 20px #9299B815;
      svg,
      img{
        width: 20px;
      }
    }
    .card__name{
      font-size: 16px;
      margin-bottom: 6px;
      font-weight: 500;
      a{
        color: ${({ theme }) => theme['dark-color']};
        &:hover{
          color: ${({ theme }) => theme['primary-color']};
        }
      }
    }
    .card__designation{
      font-size: 13px;
      margin-bottom: 25px;
      color: ${({ theme }) => theme['light-color']};
    }
    .card__social{
      margin-top: 16px;
      a{
        width: 38px;
        height: 38px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 10px 20px ${({ theme }) => theme['light-color']}15;
        background: #fff;
        &:not(:last-child){
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
        }
        &.facebook span.fa{
          color: #3B5998;
        }
        &.twitter span.fa{
          color: #1DA1F2;
        }
        &.dribble span.fa{
          color: #C2185B;
        }
        &.instagram span.fa{
          color: #FF0300;
        }
      }
    }
  }

  .user-card{
    .ant-card-body{
      padding: 30px 25px 18px 25px !important;
      @media only screen and (max-width: 1599px){
        padding: 20px  20px 20px !important;
      }
      @media only screen and (max-width: 767px){
        padding: 15px  15px 15px !important;
      }
    }
    figure{
      img{
        margin-bottom: 18px;
        max-width: 120px;
      }      
    }
    .card__actions{
      margin: -5px;
      .ant-btn-white{
        color: ${({ theme }) => theme['gray-color']};
        border: 1px solid ${({ theme }) => theme['border-color-light']};
        &:hover{
          border: 1px solid ${({ theme }) => theme['primary-color']};
        }
      }
      button{
        font-size: 13px;
        padding: 0px 22.7px;
        height: 38px;
        border-radius: 6px;
        box-shadow: 0px 3px 5px ${({ theme }) => theme['light-color']}05;
        margin: 5px;
        &:hover{
          color: #fff !important;
          background-color: ${({ theme }) => theme['primary-color']} !important;
          svg,
          i{
            color: #fff;
          }
        }
        svg,
        i{
          color: ${({ theme }) => theme['light-color']};
        }
      }
    }
    .card__info{
      padding-top: 20px;
      margin-top: 18px;
      border-top: 1px solid ${({ theme }) => theme['border-color-light']};
      .info-single{
        text-align: center;
      }
      .info-single__title{
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 4px;
      }
      p{
        margin-bottom: 0;
        color: ${({ theme }) => theme['light-color']};
      }
    }
  }
`;
const ProfileAuthorBox = Styled.div`
    .d-flex{
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }
    .ant-card-body{
        padding: 25px 0 25px !important;
    }
    .ant-card-head{
        display: none;
    }
    .user-info-card{
        .ant-card-head{
            display: block;
            border-width: 2px;
        }
        .ant-card-head-wrapper{
            align-items: flex-start;
        }
        .ant-card-head-title > div{
            flex-direction: row;
            justify-content: space-between !important;

            h1{
                margin-right: 20px;
            }
        }
        .setting-card-title{
            display: flex;
            justify-content: space-between;
        }
    }
    .author-info{
        padding: 0 20px 20px;
        text-align: center;
        border-bottom: 1px solid ${({ theme }) => theme['border-color-light']};
        .info{
            background-color: transparent;
        }
    }
    figure{
        position: relative;
        max-width: 120px;
        margin: 0 auto 18px;
        .ant-upload-select{
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
            bottom: -2px;
            height: 40px;
            width: 40px;
            background: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            span{
                display: inline-flex;
                height: 32px;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 32px;
                background: ${({ theme }) => theme['primary-color']};
            } 
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
            }
        }
    }
    figcaption{
        .info{
            h1,
            h2,
            h3,
            h4,
            h5,
            h6{
                font-size: 18px;
                margin-bottom: 4px;
            }
            p{
                margin-bottom: 0;
                color: #272B41;
            }
        }
    }

    .settings-menmulist{
        padding: 20px 20px 0px 20px;
        li{
            a{
                display: flex;
                align-items: center;
                padding: 12px 20px;
                border-radius: 6px;
                color: #272B41;
                i,
                svg,
                img{
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 13px;
                }
                &.active{
                    font-weight: 500;
                    color: ${({ theme }) => theme['primary-color']};
                    background: ${({ theme }) => theme['primary-color']}05;
                }
            }
        }
    }
    .edit-user-card{
        margin-left: 20px;

        .ant-card-body{
            padding: 25px !important;
        }
        .ant-card-head{
            display: block;
        }
        // .ant-picker{
        //     width: 100%
        // }
    }
    .ant-picker{
        width: 100% !important;
        min-width: 100% !important;
    }
    @media only screen and (max-width: 767px){
        .edit-user-card{
            margin-left: 0;
        }
    }
   
`;
const ContactCardWrapper = Styled.div`
  position: relative;
  button{
    position: absolute;
    background: transparent !important;
    top: 0;
    right: 0;
    padding: 0;
    svg {      
      color: #AEB9CD;
      width: 18px;
      height: 18px;
    }
  }
  .contact-card{
    figure{
      text-align: center;
      margin: 0;
      img{
        border-radius: 50%;
      }
      figcaption{
        margin-top: 20px;
        h3{
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 0;
        }
      }
    }
    .user-info{
      margin-top: 20px;
      border-top: 1px solid #F1F2F6;
      padding-top: 25px;
      ul{
        li{
          display: flex;
          align-items: center;
          color: #8288A4;
          &:not(:last-child){
            margin-bottom: 14px;
          }
          svg{
            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 12px;
            color: #8288A4;
          }
        }
      }
    }
  }
`;

const FeaturedProducts = Styled.div`
h3{
    font-weight: bold;
}
p{
    font-size: 14px;
    color:#272B41;
    margin-left: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
  
}
span{
    margin: 0 0 10px 10px;
    border: solid 1px #64A2D9;
    padding: 10px 15px;
    border-radius: 10;
    border-radius: 3rem;
    font-weight: 350;
    color: white;
    background: #64A2D9;
}
.ant-card-head .ant-card-head-title{
    padding: 5px 0;
    font-size: 14px;
    color: #272B41;
}
.ant-card{
    background: white;
    box-shadow: 1px 2px 9px lightgray;
}
.ant-card-head{
    display: flex;
    border-bottom: none;
    padding-left: 10px !important;
}
.card-img{
    width: 100%;
    height: 100%;
    object-fit: scale-down;
   
}
.card-img-list{
    height: 100px;
}
.ant-card-meta-title{
    margin: 0 0 15px 0;
    border: solid 1px #64A2D9;
    padding: 5px 0;
    border-radius: 10;
    border-radius: 3rem;
    font-weight: 350;
    color: white;
    font-size: 9;
    background: #64A2D9;
    width: 50%;
    text-align: center;
}
.space{
    justify-content: space-between;
    margin-top: 30px;
}
.p-15{
    padding: 0 10px 1.5px 10px;
    border-bottom: solid 2px;
    border-image: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%) 1;
}
.p-16{
    padding: 15px 10px 2px 10px;
}
.mb-10{
    margin-bottom: 30px
}

    .ant-card.ant-card-bordered, .ant-card-head {
    background: #EAEDF1;

    }

    .ant-card.ant-card-bordered{
    .ant-card.ant-card-bordered{
        background: white;
    }
    .ant-card-body{
        background: white;
        }
    }

    .ant-card-head-title{
    font-weight: bold;
    }
 }

//  .card-img{
//     width: 100%;
//     height: 100%;
//     object-fit: scale-down;
// }
`;

const SearchTable = Styled.div`
    table{
        background: none !important;

        td{
            border-bottom: 1px solid #f1f2f6 !important;      

        }
    }
    
    .table-bordered .ant-table-thead > tr > th{
        color: black;
      
    }
    .table-bordered .ant-table-thead tr th:last-child, .table-bordered .ant-table-tbody tr td:last-child{
        text-align: left;
    }
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
        background: none;
    }
    .ant-table-thead > tr > th{
        border-bottom: 1px solid lightgray;
    }
    .ant-table-tbody > tr > td{
        border-bottom: 1px solid #f1f2f6 !important;
        text-decoration: underline;
        cursor: pointer;
    }
`;

const MainContainer = Styled.div`
    padding: 0px 30px 20px;
    background-color: rgb(244, 245, 247);
    min-height: 510px;

&.grid-boxed{
    padding: 0px 180px 20px;
    @media only screen and (max-width: 1599px){
        padding: 0px 130px 20px;
    }
    @media only screen and (max-width: 1399px){
        padding: 0px 50px 20px;
    }
    @media only screen and (max-width: 991px){
        padding: 0px 30px 20px;
    }
}
.lesson-remove-btn{
    width: 20%;
    margin-top: 10px;
    color: ${({ theme }) => theme['danger-color']};
    float: right;
}
.report-style-class {
    height: 100vh;
    margin: 1% auto;
    width: 100%;
}
.main-row{
    background: white;
    border-radius: 10px;
    padding: 30px 20px;
}
.hyIOZv{
    margin-bottom: 0!important;
}
.ant-select-item-option:hover{
    background: white!important;
}

.ant-card-rtl .ant-card-extra{
            margin-right: 0 !important;
        }
.ant-tabs-tab span svg {        
    ${({ theme }) => (theme.rtl ? 'padding-left' : 'padding-right')}: 5px;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected{
    background: white!important;
}
/* Picker Under Input */
.ant-form-item-control-input .ant-picker {
    padding: ${({ theme }) => (theme.rtl ? '0 0 0 12px' : '0 12px 0 0')} !important;
}
.sequenceNumberClass{
width:100%;
}
/* progressbars */

.ant-progress {
    display: inline-flex !important;
    align-items: center;
}

.ant-progress>div {
    display: flex;
    flex-direction: column;
}

.ant-progress .ant-progress-outer {
    ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 0 !important;
    ${({ theme }) => (!theme.rtl ? 'padding-right' : 'padding-left')}: 0 !important;
}

.ant-progress .ant-progress-text {
    order: 0;
    margin-left: auto;
    ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 10px !important;
    align-self: flex-end;
    text-align: center;
}

.ant-progress-status-warning .ant-progress-bg {
    background: #fa8b0c;
}.ant-carousel .slick-list .slick-slide.slick-active

/* progress bars */

@media only screen and (max-width: 1199px){
    padding: 0px 15px;
}
@media only screen and (max-width: 991px){
    min-height: 580px;
}
.w-100{
    width: 100%;
}
.product-sidebar-col{
    @media only screen and (max-width: 767px){
        order: 2;
    }
}
.ant-skeleton-paragraph{
    margin-bottom: 0;
}

/* // ant alert */
.ant-alert-closable{
    .ant-alert-message{
      ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
    }
}

.ant-alert-with-description .ant-alert-description{
    display: inline-block;
}

/* // ant Calendar Picker */
.ant-picker-calendar{
    .ant-badge-status-text{
        color: ${({ theme }) => theme['gray-color']};
    }
}
.ant-picker-calendar-header .ant-picker-calendar-year-select{
    @media only screen and (max-width: 400px){
        width: 50% !important;
    }
}
.ant-picker-calendar-header .ant-picker-calendar-month-select{
    @media only screen and (max-width: 400px){
        width: calc(50% - 8px) !important
    }
}

/* // Card Grid */
.card-grid-wrap{
    .ant-card-grid{
        @media only screen and (max-width: 575px){
            width: 50% !important
        }
    }
}

/* // Drawer */
.atbd-drawer{
    .ant-card-body{
        text-align: center;
    }
}
.drawer-placement{
    @media only screen and (max-width: 400px){
        text-align: center;
    }
    .ant-radio-group{
        font-size: 11px;
        @media only screen and (max-width: 400px){
            margin-bottom: 15px;
        }
    }
}
.ant-drawer-content-wrapper{
    @media only screen and (max-width: 400px){
        width: 260px !important;
    }
    @media only screen and (max-width: 375px){
        width: 220px !important;
    }
}

/* // Input */
.input-wrap{
    @media only screen and (max-width: 991px){
        min-height: 500px;
    }
    input::placeholder{
        color: ${({ theme }) => theme['light-color']};
    }
}
/* // Modal Buttons */
.modal-btns-wrap{
    margin: 0 -5px;
}
/* spinner */
.ant-spin{
    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 20px;
    &:last-child{
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 0;
    }
}

/* Column Cards Wrapper */
.columnCardsWrapper{
    background: #F4F5F7;
    border-radius: 4px;
    padding: 50px 30px 25px;
}
.columnCardsWrapper .ant-card{
    background: #fff;
}
.columnCardsWrapper .ant-card-head{
    background: #fff;
}

/* Ant Collapse */
.ant-collapse{
    border-color: #E3E6EF;
    border-radius: 5px;
}
.ant-collapse.ant-collapse-icon-position-left .ant-collapse-header{
    color: #5A5F7D;
    padding: 12px 16px 10px 45px;
    font-size: 15px;
    font-weight: bold;
}
.ant-collapse-content p{
    color: #9299B8;
    margin-bottom: 0;
}
.ant-collapse-content > .ant-collapse-content-box {
    padding: 20px 20px 12px;
}
.ant-collapse-content > .ant-collapse-content-box .ant-collapse-content-box{
    padding: 10.5px 20px;
}
.ant-collapse.ant-collapse-borderless{
    background-color: #fff;
}
.ant-collapse > .ant-collapse-item,
.ant-collapse .ant-collapse-content{
    border-color: #E3E6EF;
}
.ant-collapse > .ant-collapse-item.ant-collapse-item-disabled .ant-collapse-header{
    color: #ADB4D2 !important;
}

.ant-collapse > .ant-collapse-item .ant-collapse-header .ant-collapse-arrow{

    font-size: 8px;
}

.ant-collapse .ant-collapse {
    border: 0 none;
    background: #fff;
}

.ant-collapse .ant-collapse > .ant-collapse-item {
    border-bottom: 0;
}

.ant-collapse .ant-collapse .ant-collapse-content{
    border-radius: 0;
}

/* // Ant Radio */
.ant-radio-button-wrapper{
    height: 48px;
    line-height: 46px;
    padding: 0 25.25px;
    @media only screen and (max-width: 1024px){
        padding: 0 10px;
    }
    @media only screen and (max-width: 379px){
        height: 40px !important;
        line-height: 38px !important;
        font-size: 12px;
        padding: 0 6px;
    }
}

/* // Select */
.ant-tree-select .ant-select-selector{
    height: 42px;
}
.tag-select-list{
    margin-bottom: -10px;
    .ant-select{
        margin-bottom: 10px;
    }
}
.ant-select-selector{
    border-color: #E3E6EF !important;
}


.ant-select{
    &.ant-select-multiple{
        .ant-select-selection-item{
            ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 8px;
        }
    }
    .ant-select-selection-item{
        ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 10px !important;
    }
 
    &.ant-select-lg{
        height: 50px;
        line-height: 48px;
        .ant-select-selector{
            height: 50px !important;
            line-height: 48px;
        }
        .ant-select-selection-item{
            line-height: 48px !important;
            ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 8px;
        }
        &.ant-select-multiple.ant-select-lg .ant-select-selection-item{
            height: 32px;
            line-height: 32px !important;
        }
    }
    &.ant-select-multiple.ant-select-sm{
        .ant-select-selection-item{
            height: 16px;
            line-height: 14px;
            font-size: 11px;
        }
    }
}
/* // Slider */
.slider-with-input{
    .ant-slider{
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
    }
    .slider-with-input__single{
        margin-bottom: 15px;
    }
}

/* // Taglist */
.taglist-wrap{
    margin: -5px;
    .ant-tag {
        line-height: 22px;
        padding: 0 10.2px;
        border: 0 none;
        margin: 5px;
        color: ${({ theme }) => theme['gray-color']};
        &.ant-tag-has-color{
            color: #fff !important;
        }
        &.ant-tag-magenta{
            color: #eb2f96;
        }
        &.ant-tag-red{
            color: #f5222d;
        }
        &.ant-tag-volcano{
            color: #fa541c;
        }
        &.ant-tag-orange{
            color: #fa8c16;
        }
        &.ant-tag-gold{
            color: #faad14;
        }
        &.ant-tag-line{
            color: #a0d911;
        }
        &.ant-tag-green{
            color: #a0d911;
        }
        &.ant-tag-cyan{
            color: #13c2c2;
        }
        &.ant-tag-blue{
            color: #1890ff;
        }
        &.ant-tag-geekbule{
            color: #2f54eb;
        }
        &.ant-tag-purple{
            color: #722ed1;
        }
    }
}

/* // Timepicker List */
.timepicker-list{
    margin: -5px;
    .ant-picker{
        margin: 5px;
    }
}

/* // Ant Menu */
.ant-menu{
    .ant-menu-submenu-title{
        svg{
            color: ${({ theme }) => theme['light-color']};
        }
    }
}

/* Ant Comment */
.ant-comment-inner{
    padding: 0;
}
.ant-comment-content-detail p{
    color: #9299B8;
}
.ant-list-items{
    padding-top: 22px;
    cursour: pointer;
}
.ant-list-items li:not(:last-child){
    margin-bottom: 22px;
}
.ant-comment:not(:last-child){
    margin-bottom: 22px;
}
.ant-comment-nested{
    margin-top: 22px;
}
.ant-comment-actions li span{
    color: #ADB4D2;
}
.ant-comment-content-detail textarea{
    resize: none;
    min-height: 170px;
    border-radius: 5px;
}

/* // Vector Map */
.rsm_map{
    min-height: 505px;
    .world-map{
        width: 100%;
        height: auto;
        @media only screen and (max-width: 1599px){
            height: 480px;
        }
        @media only screen and (max-width: 1399px){
            height: 400px;
        }
        @media only screen and (max-width: 575px){
            height: 400px;
        }
        @media only screen and (max-width: 767px){
            height: 300px;
        }
        @media only screen and (max-width: 575px){
            height: 250px;
        }
        @media only screen and (max-width: 479px){
            height: 350px;
        }
        @media only screen and (max-width: 375px){
            height: 240px;
        }
    }
    .controls{
        position: absolute;
        right: 30px;
        bottom: 30px;
        button{
            display: block;
            width: 27px;
            height: 27px;
            background: none;
            color: #5a5f7d;
            border: 1px solid #f1f2f6;
            padding: 0;
            font-size: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            cursor: pointer;
            &:first-child{
                border-radius: 6px 6px 0 0;
            }
            &:last-child{
                border-radius: 0 0 6px 6px;
            }
            &:focus{
                outline: none;
            }
            svg{
                width: 10px;
            }
        }
        button + button{
            border-top: 0 none;
        }
    }
}
.btn-log-ticket-active{
    width: 12rem;
    padding: 5px 10px;
    margin: 0 15px;
    border-radius: 5rem;
    align-items: center;
    color: #255CA3;
    background: #EBEDF1;
    border: solid 2px #EBEDF1;
    font-weight: 700;
}
.btn-log-ticket-inactive{
    width: 12rem;
    padding: 5px 10px;
    margin: 0 15px;
    border-radius: 5rem;
    align-items: center;
    color: #979797;
    background: transparent;
    border: transparent;
    font-weight: 700;
    box-shadow: none;
}
.btn-log-ticket-inactive:hover{
    border: solid 2px #EBEDF1;
}
.btn-view-ticket-active{
    width: 12rem;
    padding: 5px 10px;
    margin: 0 15px;
    border-radius: 5rem;
    align-items: center;
    color: #255CA3;
    background: #EBEDF1;
    border: solid 2px #EBEDF1;
    font-weight: 700;
}
.btn-view-ticket-inactive{
    width: 12rem;
    padding: 5px 10px;
    margin: 0 15px;
    border-radius: 5rem;
    align-items: center;
    color: #979797;
    background: transparent;
    border: transparent;
    font-weight: 700;
    box-shadow: none;
}
.btn-view-ticket-inactive:hover{
    border: solid 2px #EBEDF1;
}
/* // Checkout Wrapper */
.checkoutWraper{
    // .ant-card-body{
    //     padding: 50px 50px 50px 30px !important;
    //     @media only screen and (max-width: 575px){
    //         padding: 25px !important;
    //     }
    //     .ant-card-body{
    //         padding: 25px !important;
    //         @media only screen and (max-width: 375px){
    //             padding: 15px !important;
    //         }
    //     }
    // }
    .ant-steps{
        margin-top: -22px;
    }
}

/* // Star Active */
a{
    i,
    span.fa{
      font-size: 16px;
      color: ${({ theme }) => theme['extra-light-color']};
    }
    &.starDeactivate{
      i:before{
        content: "\f31b";
      }
    }
    &.starActive{
      i,
      span.fa{
        color: ${({ theme }) => theme['warning-color']};
      }
      i:before,
      span.fa:before{
        color: ${({ theme }) => theme['warning-color']};
        content: "\f005";

      }
    }
}

.ant-timeline{
    color: ${({ theme }) => theme['gray-color']};
    .ant-timeline-item-content{
        font-size: 16px;
    }
}


.ant-rate-content{
    font-weight: 500;
    color: ${({ theme }) => theme['gray-color']}
}

.account-card{
    .ant-card-head{
        .ant-card-extra{
            @media only screen and (max-width: 575px){
               padding-top: 0 !important;
            }
        }
        
    }
            
}

/* // Rechart */
.recharts-default-legend{
    .recharts-legend-item{
        min-width: 100px !important;
    }
}

/* // Radio */
.radio-size-wrap{
        .ant-radio-button-wrapper{
            @media only screen and (max-width: 1450px){
                padding: 0 11.5px;
            }
        }
    }
}

/* // Message  */
.message-button-list{
    margin: -4px;
    .ant-btn {
        margin: 4px;
    }
}
/* Chart Label */

.chart-label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
    color: #5a5f7d;
}

.chart-label .label-dot {
    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 8px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
}

.chart-label .label-dot.dot-success {
    background: #20c997;
}

.chart-label .label-dot.dot-info {
    background: #0055b8;
}

.chart-label .label-dot.dot-warning {
    background: #fa8b0c;
}

.chart-label .label-dot {
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
}

// Ant comment action
.ant-comment-actions{
    li{
        position: relative;
        &:not(:last-child){
            margin-right: 8px;
            padding-right: 8px;
            &:after{
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 12px;
                background-color: #C6D0DC;
                content: '';
            }
        }
        .com-time{
            cursor: default;
        }
        span{
            margin-right: 0;
        }
    }
}

// Emoji Picker React
.emoji-picker-react{
    top: 15px;
    right: 25px;
    box-shadow: 0 5px 10px #efefef10;
    @media only screen and (max-width: 479px){
        top: 25px;
        right: -50px;
        width: 260px;
    }
    .emoji-categories{
        padding: 0 10px;
    }
    .emoji-search{
        margin: 0 10px;
    }
    .content-wrapper:before{
        display: none;
    }
    .emoji-group{
        padding: 0 10px;
    }
    .emoji-group:before{
        font-size: 12px;
        font-weight: 600;
        color: ${({ theme }) => theme['dark-color']};
    }
    .emoji-group .emoji-img{
        margin: 5px !important;
    }
}

.wizard-side-border{
    >.ant-card{
        .ant-card-body{
            padding: 0 25px !important;
        }
    }
    .checkout-successful{
        >.ant-card{
            .ant-card-body{
                padding: 25px !important;
            }
        }
    }
    .payment-method-form.theme-light{
        .shipping-selection__card{
            .ant-card-body{
                padding: 25px 0 !important;
            }
        }
    }
    .shipping-selection__card{
        .ant-card-body{
            padding: 25px !important;
        }
    }
    .atbd-review-order{
        .ant-card-body{
            padding: 25px 25px 0 !important;
            @media only screen and (max-width: 767px) {
                padding: 15px 15px 0 !important;
            }
        }
    }
    
    .ant-steps {
        padding: 50px;
        @media only screen and (max-width: 1399px) {
            padding: 25px;
        }
    }
    .steps-wrapper{
        padding: 50px;
        @media only screen and (max-width: 1399px) {
            padding: 25px;
        }
        ${({ theme }) => (theme.rtl ? 'border-right' : 'border-left')}: 1px solid ${({ theme }) =>
  theme['border-color-light']};
    }
}
.editor-compose > div {
    position: static;
    max-width: 100%;
    margin: 25px 0;
}

// Ant Dragger
.ant-upload-drag{
    background-color: #fff !important;
    border-radius: 10px !important;
    display: flex;
    align-items: center;
    min-height: 100px;
    border-color: #C6D0DC;
    &.sDash-uploader-large{
        min-height: 180px;
    }
    .ant-upload-drag-container{
        .ant-upload-text{
            margin-bottom: 0;
            font-size: 15px;
            color: #9299B8;
        }
    }
}

// Form Validation
.ant-form-item{
    &.ant-form-item-has-success{
        .ant-input{
            border-color: ${({ theme }) => theme['success-color']};
        }
        &.ant-form-item-with-help{
            .ant-form-item-explain{
                color: ${({ theme }) => theme['success-color']};
            }
        }
    }
    &.ant-form-item-with-help{
        .ant-form-item-explain{
            margin-top: 6px;
        }
    }
}
.removeButton{
    font-size: 17px;
    font-weight: bold;
}
.ant-space-item{
    width: 100%!important;
}
.image-list{
    border:1px solid lightgray;
    padding: 15px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 15px;
}
.error{
    color: red!important;
    margin-top: 5px;
}
.panel-class{
    color: black;  
    font-weight: bold; 
    text-transform: capitalize;
}
.ant-btn-icon-only.ant-btn-sm > *{
    width: 20px!important;
    color:gray;
}
.slide-icon{
    margin-top: 5px;
    text-align: right;
}
.new-ticket-btn{
    display: flex !important;
    justify-content: center!important;
    align-items: center!important;
}
button.ant-btn.ant-btn-text{
    color: #255CA3;
}
.product{

    position: relative;
    button.ant-btn.ant-btn-text{
        color: #255CA3;
    }
    .ant-card-head{
        background: none;
        display: none;
    }
    .ant-card{
        background: none;
    }
    .product-filter{
        background: white;
        border-radius: 10px;
    }
    .hyIOZv{
        margin-bottom: 0px!important;
    }
    .ant-input-affix-wrapper{
        border-radius: 23px 0 0 23px !important;
    }
    .krRpZu .ant-select{
        margin-bottom: 0 !important;
        width: 100%;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
        height: 30px !important;
        border-radius: 0 23px 23px 0 !important;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .search-result{
        margin-bottom: 0 !important;
        font-size: 11px;
        margin: 0 !important;
        text-align: center;
    }
    .ant-card-body{
        padding: 0!important;
    }
   
   
    .search-div{
        position: fixed;
        z-index: 100;
        width: 100%
        bottom: 0;

        .search-btn{
            margin-bottom: 20px;
            float: right;
            margin-right: 20px;
        }
    }
    .ant-btn > span{
        font-size: 40px;
    }
    .m-20{
        // margin: 20px;

        h2{
            color: #272B41;
            font-size: 18px;
        }

        p{
            color: #272B41;
            font-size: 12px;
            font-weight: 400;

        }
    }
   
    .ant-btn{
        border: none;
        box-shadow: none;
        outline: none;
        background: transparent;
    }
    @media only screen and (max-width: 1599px){
        .ant-select{
            margin-bottom: 0 !important;
        }
    }
}
.product-details{
    margin-top: 20px;
    h3{
        color: #255CA3;
    }
    h2{
        font-size: 18px;
        color: #272B41;
        margin-bottom: 15px;
    }

    p{
        font-size: 14px;
        color: #272B41;
    }
     .card-img{
         width: 100%;
         height: 100%;
         padding: 20px;
         object-fit: cover;
         
     }
     .ant-card-actions{
        background: #255CA3 !important;
     }
     .ant-card-body{
         padding: 0!important;
     }
     .ant-card-meta-title{
         padding: 20px !important;
         font-size: 12px;
         border-top: 1px solid #E3E6EF;
     }
     .ant-list-lg .ant-list-item{
         padding: 0 15px 0 0!important;
     }
 }

 .category-product-list{
     h2{
         font-size: 17px;
         color: #255CA3;
         margin-bottom: 20px;
     }
     h3{
        font-weight: 700 !important;
     }
     .card-img{
         width: 100%;
         height: 100%;
         object-fit: cover;
     }
     .ant-card-body{
         padding: 0!important;
     }
     .ant-card{
         overflow: hidden;
     }
     .ant-list-lg .ant-list-item{
         padding: 0 15px 0 0 !important;
     }
 }
 .product-row{

    // background: #E5E5E5;
    padding: 20px 30px; 
    border-radius: 5px, 
    margin-top: 20px; 
    margin-bottom: 20px;
    justify-content: space-between;

    h3{
        font-weight: bold;
    }

    .m-30{
        margin-top: 30px;
    }
    .ant-carousel .slick-list .slick-slide{
        text-align: center;
    }
    .product-gallery{
        margin-top: 40px;
    }
    p{
        color: #272B41;
        margin-top: 20px;
        font-size: 12px;
        font-weight: 300;
    }
    .ant-btn{
        font-size: 12px;
    }
    
    .related{
        margin-top:30px;
        margin-left:15px;
        width: 100%;
        // border-left:2px solid #E3E6EF;
       h5{
        text-transform: uppercase;
        margin-left: 15px;
       }
       .price{
         color: #714194;
         font-size: 32px;
         font-weight: 800;
       }
       b{
        color: #255CA3;
        font-weight: bold;
        font-size: 16px;
       }
       .price-prefix{
        color: #255CA3;
        font-weight: 500;
        font-size: 16px;
       }
       .quiz-button{
        background: #64A2D9;
        border: #64A2D9;
        border-radius: 5rem;
        margin-top: 10px;
        padding-left: 35px;
        padding-right: 35px;
        textAlign: 'center',
       }
       .product-description{
            border: solid 1px #EBEDF1;
            border-radius: 5rem;
            // padding: 5px;
            color: #255CA3;
            font-weight: bold;
            background: #EBEDF1;
            width: 80%;
            height: 2rem;
            text-align: center;
       }
       .relate-content-Btn{
            background: transparent;
            border-radius: 5rem;
            width: 80%;
            height: 2rem
            font-weight: bold;
            color: #979797;
            border: solid 1px transparent;
       }
       .relate-content-Btn:hover{
            background: #EBEDF1;
            color: #255CA3;
            border: solid 1px #EBEDF1;
       }
       a{
           font-size: 16px;
       }
       @media only screen and (max-width: 575px){
        border-left:0px;
        border-top:2px solid #E3E6EF;
        margin-left:10px;
        h5{
            margin-top:10px
        }
     }
    }
    .related-sub{
        margin-left: 15px;
        font-size: 14px;
        font-weight: 400;
        color: #272B41;
    }
    .mt-30{
        margin-top: 30px;
    }
    .product-placeholders{
        margin-top: 30px;

        h4{
            font-size: 13px;
            color: #272B41;
        }
        span{
            color: #272B41;
            font-size: 12px;
            font-weight: 300;
        }
    }
  
 }
 .log-ticket{
    background: white;

    .ant-card-head{
        border-bottom: none!important;
     }
    .ant-upload{
        width: 100%!important;

        .ant-btn{
            text-align: left;
            color: #d9d9d9;
            width: 100%;
            height: 38px;
        }
        .ant-btn > span{
            width: 95%;
            float: left;
            margin-top: 3px;
        }
    }
 }
 .char-len{
     float: right;
     margin-top: -20px;
 }

 //quiz
 .quiz-header-text{
     text-transform: uppercase;
     font-size: 16px;
     font-weight: 600;
     margin-bottom: 20px;
 }
 .quiz-card{
     .ant-card-head{
        border-bottom: none !important;
     }
     .ant-card-body{
         padding: 0 15px 0 15px!important;
     }
     .card-text{
        height: 60px; 
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box; 
        overflow: hidden;
        font-size: 12px;
     }
     .quiz-link{
        float: right;
        color: #349CF7;
     }
     .quiz-points{
        color: #349CF7;
        font-weight: 600;
     }
 }
 .dashboard{
     .ant-card-head{
         border-bottom: none !important;
         padding: 0 !important;
     }
     .ant-card-head-title span{
         margin-left: 0 !important;
     }
     .ant-card-body{
         padding: 0px !important;
     }
     .ant-table-column-title{
         color: black !important;
     }
     .ant-carousel{
         width: 100% !important;
         height: 100% !important;
         object-fit: contain;
     }
     .height{
         height: 450px;
     }
     .dashboard-cards{
        // display: flex; 
        // flex-direction: column; 
        // justify-content: space-between;
     }
     .order{
         height: 100%;

         .ant-table-tbody > tr > td{
            padding: 10px 5px !important;
        }

        .ant-table-thead th.ant-table-column-has-sorters{
            padding: 10px 5px !important;
        }

         .order-title{
             font-size: 18px;
             font-weight: 500;
             text-align: center;
             padding: 15px 0;
         }

        a{
            color: gray !important;
            text-align: center;
        }
     }
     .type-box{
         display: flex;
         justify-content: center;
         align-items: start;
         font-size: 13px;
         flex-direction: column;
        //  border: 1px solid lightgray;
         border-radius: 5px;
         height: 120px;
         word-wrap: break-word;

         p{
           
             font-size: 14px;
             text-align: center;
             font-weight: 600;
             margin-bottom: 5px !important;
             overflow-wrap: anywhere
         }
         span{
             font-size: 15px;
             font-weight: 600;
             text-align: center;
             margin-bottom: 15px;
         }

         a{
            //  color: gray !important;
             position: absolute;
             bottom: 0;
             text-align: center;
         }
     }
     .my-cipla{
         font-size: 12px;
         height: 100%;

         thead{
             display: none;
         }

         .pl-10{
             padding-left: 10px;
         }

         .ant-card-head-title{
            text-align: center;
        }

         .progress-bar{
            border: 1px solid lightgray; 
            border-radius: 5px;
            padding: 10px; 
            font-size: 12px;
            margin-bottom: 20px;
            height: fit-content;
            line-height: 24px;
         }
       
         .ant-card-body{
             padding: 0px !important;
             font-size: 12px !important;
             cursor: default;
         }
         .carousel-left{
            border: 1px solid #E3E6EF;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            flex-direction: column;
            text-align: center;
    
            h2{
                color: #0055b8;
                font-weight: 1000;
                font-size: 30px;
                margin-bottom: 0;
            }
    
            p{
                color: #50ACE3;
                font-size: 11px;
            }
        }
        .carousel-right{
            padding: 0 20px;
    
            h4{
                font-size: 12px;
            }
            span{
                font-size: 10px;
            }
        }
        .carousel-row{
            background: white; 
            padding: 30px 30px 
            border-radius: 5px;
            border: 1px solid white;
    
            .feature-img{
                width: 100%;
            }
        }
         @media screen and (max-width: 767px){
             margin-top: 15px;
         }
     }
     .list{
        border: 1px solid lightgray;
        border-radius: 5px;
        height: 100%;
        padding: 0 !important;

        .ant-card-head-title{
            padding-left: 10px;
            text-align: center;
        }
    

        .desc{
            font-size: 13px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-clamp: 2;
            margin-bottom: 10px;
            margin-top: 10px;
        }
     }
     @media screen and (max-width: 767px){
        .height{
            height: 100%;
        }
        .type-box{
            margin-bottom: 10px;
        }
        .order-list{
            margin-top: 30px;
        }
    }
 }
 .cipla-table{
     .ant-card-head-title{
        padding-left: 10px;
     }
    .ant-table-tbody > tr > td{
        padding: 5px !important;
    }
 }
 .f-size{
    font-size: 12px;
}
.related-products{
    background: #E5E5E5;

    h3{
        font-weight: 700;
        font-size: 20px;
    }
    .related-products-Btn{
        background: transparent;
        border: solid 2px;
        border-image: linear-gradient(90deg, rgba(6,112,247,1) 0%, rgba(0,193,243,1) 0%, rgba(0,236,162,1) 100%) 1;
        color: rgba(0,236,162,1);
        border-radius: 5rem;
        width: 8rem;
    }
}
 .banner-img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
 }
 .p-10{
     .ant-card-body{
         padding: 10px !important;
     }
 }
 .p-0{
     padding: 0 !important;
 }
 @media screen and (max-width: 880px) and (min-width:750px){
    .f-size{
        font-size: 12px;
    }
 }
 .color-gray{
     a{
         color: gray !important;
     }
 }
 .featured-course-aboutView{
    .ant-card.ant-card-bordered, .ant-card-head {
        background: transparent;
        
      }
    
      .ant-card.ant-card-bordered{
        .ant-card.ant-card-bordered{
          background: white;
        }
      }
      .ant-card-head-title{
        font-weight: bold;
      }
 }
`;
export {
  AlertList,
  AutoCompleteWrapper,
  BannerCardStyleWrap,
  BasicFormWrapper,
  BlockButtonsWrapper,
  Body,
  BtnWithIcon,
  ButtonSizeWrapper,
  ButtonsGroupWrapper,
  CalendarWrapper,
  CardToolbox,
  CompanyCard,
  ContactCardWrapper,
  DatePickerWrapper,
  DragDropStyle,
  ExportStyleWrap,
  FeaturedProducts,
  FileCardWrapper,
  FormGroupWrapper,
  GalleryCard,
  ImportStyleWrap,
  KnowledgePage,
  LandingPage,
  Main,
  MainContainer,
  MainFAQs,
  MessageStyleWrapper,
  NotificationListWrapper,
  PageHeaderWrapper,
  ProfileAuthorBox,
  SearchTable,
  SidebarSingle,
  TableStyleWrapper,
  TableWrapper,
  TagInput,
  TopToolBox,
  UserCard,
};
