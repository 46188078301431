import React from "react";
import { UserDropDwon } from "./auth-info-style";
import { Row, Col } from "antd/lib/grid";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { useMsal } from '@azure/msal-react';
import { useDispatch } from "react-redux";
import { logOut } from '../../../redux/authentication/actionCreator';
import { useSelector } from "react-redux";
import moment from "moment";



const UserDetails = () => {

    const permissions = useSelector(state => state.permissions);
    const profileInformation = useSelector(state => state.profileInformation);

    const { instance } = useMsal();
    const dispatch = useDispatch();

    const SignOut = e => {
        e.preventDefault();
        dispatch(logOut());
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
    };
    return (
        <>
            <UserDropDwon>
                <div className="user-dropdwon">
                    <Row>
                        <Col span={24}>
                            <p>Your Details</p>
                        </Col>
                        <Col span={24}>
                            <Link to='/profile/edit'>
                                Update Your Profile
                            </Link>
                        </Col>
                        <Col span={24} style={{ marginTop: '20px' }}>
                            <Row>
                                <Col span={12}>
                                    <h4>First Name</h4>
                                    <span>{profileInformation.person?.firstName}</span>
                                </Col>
                                <Col span={12}>
                                    <h4>Last Name</h4>
                                    <span>{profileInformation.person?.lastName}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginTop: '20px' }}>
                            <Row>
                                <Col span={12}>
                                    <h4>Birthday</h4>
                                    <span>{profileInformation.person?.birthDate !==null ? moment(profileInformation.person?.birthDate).format('DD-MM-YYYY'): "Not set"}</span>
                                </Col>
                                <Col span={12}>
                                    <h4>Gender</h4>
                                    <span>{profileInformation.person?.genderCodeString}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginTop: '20px' }}>
                            <Row>
                                <Col span={12}>
                                    <h4>Profession</h4>
                                    <span>{permissions.portalRole}</span>
                                </Col>
                                <Col span={12}>
                                    <h4>Employer Name</h4>
                                    <span>{profileInformation.person?.preferredFullName}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
                        <FeatherIcon icon="log-out" /> Sign Out
                    </Link>
                </div>

            </UserDropDwon>
        </>
    )
}

export default UserDetails;