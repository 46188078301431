import { Col, Row, Skeleton, Table } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { SearchTable } from '../../container/styled';
import { useState } from 'react';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAccount } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { themeLoading } from '../../redux/themeLayout/actionCreator';
import { bindActionCreators } from 'redux';
import { TriggerGetProducts } from './api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ProductResult = params => {
  const history = useHistory();

  const landingColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const [products, setProducts] = useState({
    initialized: false,
    totalRows: 0,
    data: [],
  });
  const [isVisible, setIsVisible] = useState({ initialized: true, visible: true });
  const [showSkeleton, setShowSkeleton] = useState(true);

  const { instance, accounts, inProgress } = useMsal();

  const dispatch = useDispatch();
  const themeLoadingToggle = bindActionCreators(themeLoading, dispatch);

  useEffect(() => {
    TriggerGetProducts(setProducts, accounts[0] || {}, inProgress, instance, params.params, themeLoadingToggle);
  }, [params.params]);

  useEffect(() => {
    if (products.initialized) {
      setShowSkeleton(false);
    }
  });

  useEffect(() => {
    //  window.addEventListener('scroll', handleScroll);
  });

  function handleScroll() {
    if (
      params.productRef?.current?.offsetTop + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      setIsVisible({ initialized: true, visible: true });
      setShowSkeleton(false);
    }
  }

  return (
    <>
      <Skeleton active loading={showSkeleton}>
        <SearchTable>
          <p>
            <b>Products</b>
          </p>
          <Row style={{ marginBottom: '15px' }}>
            <Col span={24}>
              <div className="table-bordered table-responsive">
                <Table
                  columns={landingColumns}
                  pagination={false}
                  dataSource={products.data}
                  rowKey="id"
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        history.push(`/products/category/${record.id}/product/${record.productId}`);
                      },
                    };
                  }}
                />
              </div>
            </Col>
          </Row>
          <NavLink to={'/products'}>Show more</NavLink>
        </SearchTable>
      </Skeleton>
    </>
  );
};

export default ProductResult;
