import { useAccount, useMsal } from '@azure/msal-react';
import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { protectedResources } from '../../../config/auth/authConfig';
import { getOrder, TriggerDocumentDownload } from './OrderApi';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Table, Button, Descriptions, Card, Row, Col, Tag, notification } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Main } from '../../styled';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { themeLoading } from '../../../redux/themeLayout/actionCreator';
import { OrderDetailsTableColumn } from './OrderDetailsColumns';
import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { TableWrapper, TableStyleWrapper } from '../../styled';
import './style.css';
import { theme } from '../../../config/theme/themeVariables';
import { currency } from '../../../components/utilities/utilities';

export function OrderDetail() {
  const { id } = useParams();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const themeLoadingToggle = bindActionCreators(themeLoading, dispatch);
  const changeLayoutMode = useSelector(state => state.ChangeLayoutMode);
  const [orderDetailData, setOrderDetailData] = useState({ succeeded: false, data: {} });
  const history = useHistory();

  useEffect(() => {
    themeLoadingToggle(true);
    getOrder(
      setOrderDetailData,
      themeLoadingToggle,
      protectedResources.orderDetail.endpoint,
      id ? id : '',
      instance,
      account,
    );
  }, [account, inProgress, instance, id]);

  useEffect(() => {
    if (!orderDetailData.data?.succeeded) {
      if (orderDetailData.data?.errors?.length > 0) {
        window.scrollTo(0, 0);

        notification['error']({
          message: 'Error',
          description: orderDetailData.errors.join('. '),
        });
      }
    }
  }, [orderDetailData]);

  const onHandleHelpClickEvent = () => {
    history.push('/support/log-ticket/' + orderDetailData.data?.id);
  };

  function GetTag(status) {
    let active = ['NEW', 'SHIPPED'];
    if (active.includes(status?.toUpperCase())) {
      return activeTag;
    } else return defaultTag;
  }

  const activeTag = {
    backgroundColor: theme['success-color'] + '15',
    color: theme['success-color'],
  };

  const defaultTag = {
    backgroundColor: theme['gray-color'] + '15',
    color: theme['gray-color'],
  };

  function NewlineText(props) {
    const text = props.text;

    if (text !== '' && text !== null) {
      const newText = text.split('\n')?.map(str => (
        <>
          {str}
          <br />
        </>
      ));

      return newText;
    } else {
      return <>{text}</>;
    }
  }

  function downloadDocument(url) {
    TriggerDocumentDownload(instance, account, url, themeLoadingToggle);
  }
  function IsInternal() {
    const permissions = useSelector(state => state.permissions);
    if (permissions.portalRole === 'Internal') {
      return true;
    } else {
      return false;
    }
  }
  return (
    <div className="ordersContainer">
      <PageHeader
        ghost
        title={!changeLayoutMode.loading ? `Order` : ''}
        buttons={
          !changeLayoutMode.loading
            ? [
                <div key="1" className="page-header-actions">
                  {!IsInternal && (
                    <Button onClick={onHandleHelpClickEvent} size="small" type="primary">
                      Get help for this order
                    </Button>
                  )}
                </div>,
              ]
            : []
        }
      />
      <Main>
        {!changeLayoutMode.loading && (
          <>
            <Row>
              <Col span={24}>
                <Cards headless>
                  <Row justify="center">
                    <Col span={23}>
                      <Card
                        title={
                          <>
                            {orderDetailData.data?.name}
                            <br />
                            {orderDetailData.data?.orderNumber}
                          </>
                        }
                        bordered={false}
                        bodyStyle={{ padding: 0, backgroundColor: '#F8F9FB', borderRadius: 20 }}
                        headStyle={{ borderBottom: 0, paddingBottom: 0 }}
                      >
                        <Descriptions
                          colon={false}
                          layout="vertical"
                          column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 2 }}
                        >
                          <Descriptions.Item label={<b>Date Ordered</b>}>
                            {orderDetailData.data?.submitDate}
                          </Descriptions.Item>
                          <Descriptions.Item label={<b>Date Fulfilled</b>}>
                            {orderDetailData.data?.dateFulfilled}
                          </Descriptions.Item>
                          <Descriptions.Item label={<b>Invoice Number</b>}>
                            {orderDetailData.data?.invoiceNumber}
                          </Descriptions.Item>
                          <Descriptions.Item label={<b>PO Number</b>}>
                            {orderDetailData.data?.poNumber}
                          </Descriptions.Item>
                        </Descriptions>
                        <br />
                        <br />
                        <Descriptions
                          colon={false}
                          layout="vertical"
                          column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 2 }}
                        >
                          {/* <Descriptions.Item label={<b>Payment Details</b>}>Added to Credit Facility</Descriptions.Item> */}

                          <Descriptions.Item label={<b>Status</b>}>
                            <Descriptions
                              colon={false}
                              layout="vertical"
                              column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 2 }}
                            >
                              <Descriptions.Item
                                label={
                                  <Tag
                                    style={GetTag(orderDetailData.data?.statusCode)}
                                    key={orderDetailData.data?.statusCode}
                                  >
                                    {orderDetailData.data?.statusCode?.toUpperCase()}
                                  </Tag>
                                }
                              ></Descriptions.Item>
                            </Descriptions>
                          </Descriptions.Item>

                          {/* <Descriptions.Item label={<b>Bill To</b>}>
                            <NewlineText text={data === undefined ? '' : data.billTo_Composite} />
                          </Descriptions.Item> */}
                          <Descriptions.Item label={<b>Order Created By</b>}>
                            {orderDetailData.data?.createdBy}
                          </Descriptions.Item>
                          <Descriptions.Item label={<b>Order Type</b>}>{orderDetailData.data?.type}</Descriptions.Item>
                          <Descriptions.Item label={<b>Amount</b>}>
                            {currency(orderDetailData.data?.totalAmount, 'R')}
                          </Descriptions.Item>
                        </Descriptions>
                      </Card>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col span={24}>
                      <Cards headless>
                        <br />
                        <TableStyleWrapper>
                          <TableWrapper className="table-responsive">
                            <Table
                              dataSource={orderDetailData.data?.salesOrderDetails?.map((value, index) => {
                                return { ...value, number: index + 1 };
                              })}
                              rowKey="id"
                              columns={OrderDetailsTableColumn}
                              loading={{ indicator: <></>, spinning: changeLayoutMode.loading }}
                              style={{ width: '100%' }}
                            />
                          </TableWrapper>
                        </TableStyleWrapper>
                      </Cards>
                    </Col>
                  </Row>
                  <Row justify="end">
                    <Col xxl={5} xl={5} md={5} sm={12} xs={24}>
                      <Cards headless>
                        <Descriptions
                          colon={true}
                          layout="horizontal"
                          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                        >
                          <Descriptions.Item label="Tax">
                            <div style={{ position: 'absolute', right: 0 }}>
                              {currency(orderDetailData.data?.totalTax, 'R')}
                            </div>
                          </Descriptions.Item>
                          <Descriptions.Item label="Subtotal">
                            <div style={{ position: 'absolute', right: 0 }}>
                              {currency(orderDetailData.data?.totalAmount - orderDetailData.data?.totalTax, 'R')}
                            </div>
                          </Descriptions.Item>
                          <Descriptions.Item label={<b>Total</b>}>
                            <div
                              style={{ position: 'absolute', right: 0, color: theme['primary-color'], fontSize: 18 }}
                            >
                              {currency(orderDetailData.data?.totalAmount, 'R')}
                            </div>
                          </Descriptions.Item>
                        </Descriptions>
                      </Cards>
                    </Col>
                  </Row>
                </Cards>
              </Col>
            </Row>
            <Row justify="left">
              <Col span={10}>
                <Card
                  title={'Related Documents'}
                  bordered={false}
                  bodyStyle={{ padding: 0, borderRadius: 20 }}
                  headStyle={{ borderBottom: 0, paddingBottom: 0 }}
                >
                  <Descriptions
                    colon={false}
                    layout="horizontal"
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                  >
                    {orderDetailData.data?.sharePointFile !== null ? (
                      <>
                        {orderDetailData.data?.sharePointFile?.map(file => {
                          return (
                            <Descriptions.Item label={<FeatherIcon size={16} icon="file-text" color="gray" />}>
                              {file.fileLeafRef}
                              <div style={{ position: 'absolute', right: 50 }}>
                                <FeatherIcon
                                  size={16}
                                  style={{ marginLeft: 20 }}
                                  icon="download"
                                  color="gray"
                                  onClick={() => downloadDocument(file?.fileRef)}
                                />
                              </div>
                            </Descriptions.Item>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </Descriptions>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Main>
    </div>
  );
}
