import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import LayoutSelector from '../container/profile/authentication/Index';
import { PasswordReset } from '../container/passwordReset';
import Home from '../container/landing';
import SignUp from '../container/profile/authentication/overview/Signup';

const FrontendRoutes = () => {
  return (
    <Switch>
      <Suspense fallback={<div className="spin">{/* <Spin /> */}</div>}>
        {/* <Route exact path="/" component={Login} /> */}
        <Route exact path="/register" component={SignUp} />
        <Route exact path="/passwordReset" component={PasswordReset} />
        <Route exact path="/" component={Home} />
      </Suspense>
    </Switch>
  );
};

export default LayoutSelector(FrontendRoutes);
