import { protectedResources } from "../../../../config/auth/authConfig";
import { DataService } from "../../../../config/dataService/dataService";
import { notification } from 'antd';


function getAPI(setData, endpoint) {

    DataService.get(endpoint)
        .then(resp => resp.data)
        .then(resp => {
            if (resp != undefined) {
                setData(resp.data);
            }
        })
        .finally(() => {

        })
        .catch((error, resp) => {

        });

}

export function TriggerGetRegister(setData) {
    getAPI(setData, protectedResources.titles.endpoint);
}


function getApiWithToken(populateProfileInfo, dispatch, instance, account, themeLoadingToggle, endpoint) {
    // themeLoadingToggle(true);

    DataService.getWithTokenSilent(endpoint, instance, account)
        .then(resp => resp.data)
        .then(resp => {
            if (resp != undefined) {
                if (resp.succeeded) {

                    const profileInfo = resp.data;
                    profileInfo.initialized = true;
                    dispatch(
                        populateProfileInfo(profileInfo)
                    )
                }
            }
        })
        .finally(() => {
            //  themeLoadingToggle(false);
        })
        .catch(() => {
            // themeLoadingToggle(false);
        });
}

function getApiToken(populateProfileLookUpInfo, dispatch, instance, account, themeLoadingToggle, endpoint) {
    // themeLoadingToggle(true);
    DataService.getWithTokenSilent(endpoint, instance, account)
        .then(resp => resp.data)
        .then(resp => {
            if (resp != undefined) {
                if (resp.succeeded) {
                    const profileLookUp = resp.data;
                    profileLookUp.initialized = true;
                    dispatch(
                        populateProfileLookUpInfo(profileLookUp)
                    )
                }
            }
        })
        .finally(() => {
            //  themeLoadingToggle(false);
        })
        .catch((error) => {
            // themeLoadingToggle(false);
            notification['error']({
                message: 'Error',
                description: error?.message,
              });

        });
}

function postApi(setSaveData, formRequest, instance, account, themeLoadingToggle, endpoint) {
    DataService.postWithTokenSilent(endpoint, formRequest, instance, account)
        .then(resp => resp.data)
        .then(resp => {
            if (resp != undefined) {
                setSaveData({
                    initialized: true,
                    data: resp
                });
            }
        })
        .finally(() => {
        })
        .catch((error, resp) => {
        });
}


export function TriggerGetProfileLookUp(populateProfileLookUpInfo, dispatch, instance, account, themeLoadingToggle) {
    const endpoint = protectedResources.getEditProfileLookUp.endpoint;
    getApiToken(populateProfileLookUpInfo, dispatch, instance, account, themeLoadingToggle, endpoint);
}

export function TriggerGetProfileDetails(populateProfileInfo, dispatch, instance, account, themeLoadingToggle) {
    getApiWithToken(populateProfileInfo, dispatch, instance, account, themeLoadingToggle, protectedResources.getProfileDetails.endpoint)
}

export function TriggerSaveUser(setSaveResponse, formRequest, instance, account, themeLoadingToggle) {
    postApi(setSaveResponse, formRequest, instance, account, themeLoadingToggle, protectedResources.editUser.endpoint)
}

export function TriggerSaveCompany(setSaveResponse, formRequest, instance, account, themeLoadingToggle) {
    postApi(setSaveResponse, formRequest, instance, account, themeLoadingToggle, protectedResources.editCompany.endpoint)
}

export function TriggerUpdateProfileImage(setSaveResponse, formRequest, instance, account, themeLoadingToggle) {
    DataService.postWithTokenSilent(protectedResources.saveUserImage.endpoint, formRequest, instance, account)
        .then(resp => resp.data)
        .then(resp => {
            if (resp != undefined) {
                setSaveResponse({
                    initialized: true,
                    data: resp
                });
            }
        })
        .finally(() => {

        })
        .catch((error, resp) => {
            console.log(error)
        });

}