import React, { useEffect } from 'react';
import { Result, Divider, Typography } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import './style.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CheckoutOrdersResultSuccess, CheckoutOrdersResultFailed } from './checkoutOrdersResult';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart } from '../../redux/cart/actionCreator';
import { populateProfileInfo } from '../../redux/profileInformation/actionCreator';
import { TriggerGetProfileDetails } from '../../components/utilities/auth-info/user-profile/api';
import { useAccount } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { bindActionCreators } from 'redux';
import { themeLoading } from '../../redux/themeLayout/actionCreator';

const { Paragraph, Text } = Typography;

export default function CheckoutResult(params) {
  const { checkoutResponse } = params;
  const history = useHistory();
  const dispatch = useDispatch();

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const themeLoadingToggle = bindActionCreators(themeLoading, dispatch);


  useEffect(() => {
    if (checkoutResponse.data.success) {
      dispatch(clearCart());
      TriggerGetProfileDetails(populateProfileInfo, dispatch, instance, account, themeLoadingToggle);
    }
  }, [checkoutResponse.data.success]);
  

  return (
    <>
      <Result
        status={checkoutResponse.data.success === false ? 'error' : 'success'}
        title={checkoutResponse.data.success === false ? 'Purchase failed' : 'Purchase was successful'}
        subTitle={checkoutResponse.data.success === false ? checkoutResponse.data.message : ''}
        extra={[<></>]}
      >
        <div className="desc">
          <Paragraph>
            {checkoutResponse.data.success === false ? (
              <CloseCircleOutlined className="site-result-demo-error-icon" />
            ) : (
              <CheckCircleOutlined className="site-result-demo-success-icon" />
            )}{' '}
            Points used: {checkoutResponse.data.pointsUsed.toLocaleString()}
          </Paragraph>
          <Paragraph>
            <CheckCircleOutlined className="site-result-demo-success-icon" /> Points balance:{' '}
            {checkoutResponse.data.pointsBalance.toLocaleString()}
          </Paragraph>
          <Divider orientation="Center">Orders</Divider>
          {checkoutResponse.data.success && <CheckoutOrdersResultSuccess checkoutResponse={checkoutResponse} />}
          {!checkoutResponse.data.success && <CheckoutOrdersResultFailed checkoutResponse={checkoutResponse} />}
        </div>
      </Result>
    </>
  );
}
