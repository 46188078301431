const actions = {
  POPULATE_ROLES: 'POPULATE_ROLES',

    populateUserRoles: permissions => {
      return {
        type: actions.POPULATE_ROLES,
        payload: permissions
      };
    }
  };
  
  export default actions;
  