export const courseType = {
  Cipla: 385790000,
  Denovo: 385790001,
  ProductQuiz: 385790004,
  StandaloneQuiz: 385790002,
  SalesRepresentativeTraining: 385790006,
  SalesRepresentativeContinuousLearning: 385790005,
};

export const courseTypeText = {
    Cipla: 'Cipla',
    Denovo: 'Denovo',
    ProductQuiz: 'ProductQuiz',
    StandaloneQuiz: 'StandaloneQuiz',
    SalesRepresentativeTraining: 'SalesRepresentativeTraining',
    SalesRepresentativeContinuousLearning: 'SalesRepresentativeContinuousLearning',
  };
  

export const moduleType = {
  Lesson: 'lesson',
  Assessment: 'assessment',
};

export const ticketState ={
  Active: 'Active',
  Resolved: 'Resolved',
  Cancelled: 'Cancelled'
};
