import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { AtbdTopDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
import { DispatchSalesRepPortfolios, DispatchSalesRepUnreadNotification, TriggerNotificationsGetAllUnread, TriggerPortfoliosGet } from '../../../container/dnaacademy/api';
import { themeLoading } from '../../../redux/themeLayout/actionCreator';
import { useAccount, useMsal } from '@azure/msal-react';
import { bindActionCreators } from 'redux';
import { populateUnreadNotifications } from '../../../redux/salesRepUnreadNotifications/actionCreator';
import { populatePortfolios } from '../../../redux/salesRepPortfolios/actionCreator';
import SalesRepValues from '../../../salesRepValues';

const NotificationBox = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const allUnreadNotifications = useSelector(state => state.salesRepUnreadNotification);
  const salesRepPortfolios = useSelector(state => state.salesRepPortfolios);
  const themeLoadingToggle = bindActionCreators(themeLoading, dispatch);
  const [error, setError] = useState({ initialized: false, data: {}, message: undefined });
  const permissions = useSelector(state => state.permissions);

  useEffect(()=>{
    if(!allUnreadNotifications.initialized){
      TriggerNotificationsGetAllUnread(setAllUnreadState, instance, account, themeLoadingToggle);
    }
  }, [allUnreadNotifications]);

  function setAllUnreadState(payload) {

    if (payload.initialized && payload.data !== undefined) {
      DispatchSalesRepUnreadNotification(payload, dispatch, populateUnreadNotifications);
    }

    if (payload.initialized && payload.data === undefined) {
      setError({ ...payload });
    }
  }
 
  function setPortfolios(payload) {

    if (payload.initialized && payload.data !== undefined) {
      DispatchSalesRepPortfolios(payload, dispatch, populatePortfolios);
    }

    if (payload.initialized && payload.data === undefined) {
      setError({ ...payload });
    }
  }

  useEffect(()=>{
    if(!salesRepPortfolios.initialized && SalesRepValues().salesRepRoles.includes(permissions.portalRole)){
      TriggerPortfoliosGet(setPortfolios, instance, account, themeLoadingToggle);
    }
  },[]);

  const { rtl } = useSelector(state => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
    };
  });

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} props={props} />;
  };

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div className="hello" style={thumbStyle} />;
  };

  const renderView = ({ style, ...props }) => {
    const customStyle = {
      marginRight: rtl && 'auto',
      [rtl ? 'marginLeft' : 'marginRight']: '-17px',
    };
    return <div {...props} style={{ ...style, ...customStyle }} />;
  };

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  renderView.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  const content = (
    <AtbdTopDropdwon className="atbd-top-dropdwon">
      <Heading as="h5" className="atbd-top-dropdwon__title">
        <span className="title-text">Notifications</span>
        <Badge className="badge-success" count={allUnreadNotifications?.data?.length} />
      </Heading>
      <Scrollbars
        autoHeight
        autoHide
        renderThumbVertical={renderThumb}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
      >
        <ul className="atbd-top-dropdwon__nav notification-list">
          {allUnreadNotifications.initialized && (
            <>
            {allUnreadNotifications.data?.map(item=>{
              return (
              <>
              <li>
                <Link to="/notifications">
                  <div className="atbd-top-dropdwon__content notifications">
                    <div className="notification-icon bg-primary">
                      <FeatherIcon icon="hard-drive" />
                    </div>
                    <div className="notification-content d-flex">
                      <div className="notification-text">
                        <Heading as="h5">
                          <span>{item.notificationType}</span>
                        </Heading>
                        <p style={{textDecoration:'none'}}>{item.notificationText}</p>
                      </div>
                      <div className="notification-status">
                        <Badge dot style={{background: '#96C8FA'}} />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              </>);
            })}
            </>
          )}
        </ul>
      </Scrollbars>
      <Link className="btn-seeAll" to="/notifications">
        See all incoming activity
      </Link>
    </AtbdTopDropdwon>
  );

  return (
    <div className="notification">
      <Popover placement="bottomLeft" content={content} action="click">
        {allUnreadNotifications?.data?.length > 0 ?
        <Badge dot offset={[-8, -5]}>
          <Link to="#" className="head-example">
            <FeatherIcon icon="bell" size={20} />
          </Link>
        </Badge> 
        : <Link to="#" className="head-example">
        <FeatherIcon icon="bell" size={20} />
      </Link>}
      </Popover>
    </div>
  );
};

export default NotificationBox;
