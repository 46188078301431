import { Menu } from 'antd';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { GetComponentPermissions, checkPermissions, hasAccessFlag } from '../components/AccessControl/PermisionWrapper';
import './style.css';
import SalesRepValues from '../salesRepValues';

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const wrapStyle = { style: { whiteSpace: 'normal', height: 'auto' } };

  const profileInformation = useSelector(state => state.profileInformation);

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'home'}`] : [],
  );

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);

    if (mainPathSplit.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
    // setOpenKeys(keys[keys.length - 1] !== 'submenu' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const permissions = useSelector(state => {
    return { initialized: state.permissions.initialized, roles: [...state.permissions.roles], portalRole: state.permissions.portalRole };
  });

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 && mainPathSplit[0] === ''
                  ? 'home'
                  : mainPathSplit.length === 2
                  ? mainPathSplit[0]
                  : mainPathSplit[0]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'learn'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      <Menu.Item key="home" icon={!topMenu && <FeatherIcon icon="home" />} {...wrapStyle}>
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          Home
        </NavLink>
      </Menu.Item>
      {checkPermissions({
        requiredPermissions: GetComponentPermissions(['ProductsOTP', 'ProductsRx']),
        userHeldPermissions: permissions,
        isComponent: false,
      }) ? (
        <SubMenu key="fe-products" icon={!topMenu && <FeatherIcon icon="package" />} title="Products">
          {checkPermissions({
            requiredPermissions: GetComponentPermissions('ProductsOTP'),
            userHeldPermissions: permissions,
            isComponent: false,
          }) ? (
            <Menu.Item key="productOtc" {...wrapStyle}>
              <NavLink onClick={toggleCollapsed} to={`${path}products/otc`}>
                OTC
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {checkPermissions({
            requiredPermissions: GetComponentPermissions('ProductsRx'),
            userHeldPermissions: permissions,
            isComponent: false,
          }) ? (
            <Menu.Item key="productPrescription" {...wrapStyle}>
              <NavLink onClick={toggleCollapsed} to={`${path}products/rx`}>
                Prescription
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
        </SubMenu>
      ) : (
        ''
      )}

      {checkPermissions({
        requiredPermissions: GetComponentPermissions('Learning'),
        userHeldPermissions: permissions,
        isComponent: false,
      }) ? (
        <SubMenu key="learn" icon={!topMenu && <FeatherIcon icon="file-text" />} title={SalesRepValues().salesRepRoles.includes(permissions.portalRole) || SalesRepValues().salesRepUserRole.includes(permissions.portalRole) ? "Training" : "Learn"}>
          <Menu.Item key="about" {...wrapStyle}>
            <NavLink onClick={toggleCollapsed} to={`${path}learn/about`}>
                    {SalesRepValues().salesRepUserRole.includes(permissions.portalRole) || SalesRepValues().salesRepRoles.includes(permissions.portalRole) ? 'Training Overview' : 'About'}
            </NavLink>
          </Menu.Item>

          <Menu.Item key="courses" {...wrapStyle}>
            <NavLink onClick={toggleCollapsed} to={`${path}learn/courses`}>
            {SalesRepValues().salesRepUserRole.includes(permissions.portalRole) || SalesRepValues().salesRepRoles.includes(permissions.portalRole) ? 'Training Library' : 'Courses'}
            </NavLink>
          </Menu.Item>

          {/* <Menu.Item key="therapeutic-categories" {...wrapStyle}>
            <NavLink onClick={toggleCollapsed} to={`${path}products`}>
              Therapeutic Categories
            </NavLink>
          </Menu.Item> */}
          {SalesRepValues().salesRepUserRole.includes(permissions.portalRole) || SalesRepValues().salesRepRoles.includes(permissions.portalRole) ? <></> :
          <Menu.Item key="quizzes" {...wrapStyle}>
            <NavLink onClick={toggleCollapsed} to={`${path}learn/quizzes`}>
                'Quizzes'
            </NavLink>
          </Menu.Item>}

          {SalesRepValues().salesRepRoles.includes(permissions.portalRole) ?
          <>
          <Menu.Item key="portfolioOfEvidence" {...wrapStyle}>
            <NavLink onClick={toggleCollapsed} to={`${path}learn/portfolioOfEvidence`}>
              Portfolio of Evidence
            </NavLink>
          </Menu.Item>
          </>
          :<></>}

          {SalesRepValues().salesRepUserRole.includes(permissions.portalRole) ?
          <>
          <Menu.Item key="portfolioOfEvidence" {...wrapStyle}>
            <NavLink onClick={toggleCollapsed} to={`${path}learn/myPortfolioOfEvidence`}>
              Portfolio of Evidence
            </NavLink>
          </Menu.Item>
          </>
          :<></>}
          
          {checkPermissions({
            requiredPermissions: GetComponentPermissions('MobiMiMs'),
            userHeldPermissions: permissions,
            isComponent: false,
          }) ? (
            <Menu.Item key="MiMs" {...wrapStyle}>
              <NavLink onClick={toggleCollapsed} to={`${path}mobiMims`}>
                MiMs
              </NavLink>
            </Menu.Item>
          ) : (
            ''
          )}
          {SalesRepValues().salesRepUserRole.includes(permissions.portalRole) || SalesRepValues().salesRepRoles.includes(permissions.portalRole) ? <></>:
          <>
          {checkPermissions({
            requiredPermissions: GetComponentPermissions('Rewards'),
            userHeldPermissions: permissions,
            isComponent: false,
          }) ? 
            <Menu.Item key="submenu" title="Rewards" style={{ paddingLeft: 34 }}>
              <NavLink onClick={toggleCollapsed} to={`${path}learn/myRewards`}>
                My Rewards
              </NavLink>
            </Menu.Item>
           : 
            <></>
          }
          </>}

          {/* <Menu.Item key="enrol">
            <NavLink onClick={toggleCollapsed} to={`${path}learn/enrol`}>
              Enrol
            </NavLink>
          </Menu.Item> */}
        </SubMenu>
      ) : (
        ''
      )}

      {checkPermissions({
        requiredPermissions: GetComponentPermissions('Orders'),
        userHeldPermissions: permissions,
        isComponent: false,
      }) ? (
        <Menu.Item key="orders" icon={!topMenu && <FeatherIcon icon="shopping-bag" />} {...wrapStyle}>
          <NavLink onClick={toggleCollapsed} to={`${path}orders`}>
            Orders
          </NavLink>
        </Menu.Item>
      ) : (
        ''
      )}

      {checkPermissions({
        requiredPermissions: GetComponentPermissions('Knowledge'),
        userHeldPermissions: permissions,
        isComponent: false,
      }) ? (
        <>
          <Menu.Item key="faq" icon={!topMenu && <FeatherIcon icon="message-square" />} title="Connect">
            <NavLink onClick={toggleCollapsed} to={`${path}support/knowledgebase/my-cipla-faqs`}>
              FAQs
            </NavLink>
          </Menu.Item>
        </>
      ) : (
        ''
      )}
      {checkPermissions({
        requiredPermissions: GetComponentPermissions('Support'),
        userHeldPermissions: permissions,
        isComponent: false,
      }) ? (
        <>
          <Menu.Item
            {...wrapStyle}
            key="support"
            title="Support"
            style={{ paddingLeft: 34 }}
            icon={!topMenu && <FeatherIcon icon="headphones" />}
          >
            <>
              <NavLink onClick={toggleCollapsed} to={`${path}support/view-ticket`}>
                Support
              </NavLink>
            </>
          </Menu.Item>
        </>
      ) : (
        ''
      )}

      {checkPermissions({
        requiredPermissions: GetComponentPermissions('Reports'),
        userHeldPermissions: permissions,
        isComponent: false,
      }) ? (
        <Menu.Item key="reports" icon={!topMenu && <FeatherIcon icon="pie-chart" />} {...wrapStyle}>
          <NavLink onClick={toggleCollapsed} to={`${path}report`}>
            Report
          </NavLink>
        </Menu.Item>
      ) : (
        ''
      )}

      {hasAccessFlag({
        hasAccess: SalesRepValues().adminRoles.includes(permissions.portalRole),
      }) ? (
        <SubMenu key="admin" icon={!topMenu && <FeatherIcon icon="user" />} title="Admin">
          <Menu.Item key="manageCourses" {...wrapStyle}>
            <NavLink onClick={toggleCollapsed} to={`${path}learn/admin/courses`}>
              {SalesRepValues().salesRepRoles.includes(permissions.portalRole)
                ? 'Manage Training'
                : 'Manage Courses'}
            </NavLink>
          </Menu.Item>
          <Menu.Item key="lessons" {...wrapStyle}>
            <NavLink onClick={toggleCollapsed} to={`${path}learn/admin/lessons`}>
              Manage Lessons
            </NavLink>
          </Menu.Item>
          <Menu.Item key="assessments" {...wrapStyle}>
            <NavLink onClick={toggleCollapsed} to={`${path}learn/admin/assessments`}>
              Manage Assessments
            </NavLink>
          </Menu.Item>
          {SalesRepValues().trainingManagerOnly.includes(permissions.portalRole) ?
          <Menu.Item key="grading" {...wrapStyle}>
            <NavLink onClick={toggleCollapsed} to={`${path}learn/grading`}>
              Grading
            </NavLink>
          </Menu.Item> : <></>}
          {!SalesRepValues().salesRepRoles.includes(permissions.portalRole) ?
          <>
          <Menu.Item key="pharmaceuticalCategories" {...wrapStyle}>
            <NavLink onClick={toggleCollapsed} to={`${path}learn/admin/pharmaceuticalList`}>
              Manage Therapeutic Categories
            </NavLink>
          </Menu.Item>
          <Menu.Item key="products" {...wrapStyle}>
            <NavLink onClick={toggleCollapsed} to={`${path}learn/admin/productList`}>
              Manage Products
            </NavLink>
          </Menu.Item>
          <Menu.Item key="rewardList" {...wrapStyle}>
            <NavLink onClick={toggleCollapsed} to={`${path}learn/admin/campaigns`}>
              Manage Rewards
            </NavLink>
          </Menu.Item>
          <Menu.Item key="quiz" {...wrapStyle}>
            <NavLink onClick={toggleCollapsed} to={`${path}learn/admin/quiz`}>
              Manage Quiz
            </NavLink>
          </Menu.Item>
          </> : <></>}
        </SubMenu>
      ) : (
        ''
      )}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
