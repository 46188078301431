const actions = {
  Set_All_Notifications: 'Set_All_Notifications',
  
  setAllNotifications: notifications => {
    return {
      type: actions.Set_All_Notifications,
      payload: notifications,
    };
  },
};
export default actions;