import { protectedResources } from "../../../../config/auth/authConfig";
import { DataService } from "../../../../config/dataService/dataService";

function postAPI(setData, values ,setLoading) {
    DataService.post(protectedResources.register.endpoint, values)
        .then(resp => resp.data)
        .then(resp => {
            if (resp != undefined) {
                setData(resp);
            }
        })
        .finally(() => {
            setLoading(false)
        })
        .catch((error, resp) => {
            setLoading(false)
        });
}

function getAPi(setData){
    DataService.get(protectedResources.titles.endpoint)
    .then(resp => resp.data)
    .then(resp => {
        if (resp != undefined) {
            setData({
                intialized: true,
                data: resp.data
            });
        }
    })
    .finally(() => {
        
    })
    .catch((error, resp) => {
        
    });
}
export function TriggerRegister(setData, values, setLoading) {
    postAPI(setData, values ,setLoading);
}
export function TriggerGetRegister(setData){
    getAPi(setData);
}