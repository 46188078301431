import { Col, Row, Skeleton, Table } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import { SearchTable } from "../../container/styled";
import { useState } from "react";
import { useEffect } from "react";
import { TriggerGetCampines, TriggerGetQuiz } from "./api";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { themeLoading } from "../../redux/themeLayout/actionCreator";
import { bindActionCreators } from "redux";


const QuizResult = (params) => {

    const landingColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
    ];


    const [quiz, setQuiz] = useState({
        initialized: false,
        totalRows: 0,
        data: [],
    });
    const [isVisible, setIsVisible] = useState({ initialized: true, visible: true });
    const [showSkeleton, setShowSkeleton] = useState(false);

    const { instance, accounts, inProgress } = useMsal();

    const dispatch = useDispatch();
    const themeLoadingToggle = bindActionCreators(themeLoading, dispatch);

    useEffect(() => {
        TriggerGetQuiz(
            setQuiz,
            accounts[0] || {},
            inProgress,
            instance,
            params.params,
            themeLoadingToggle,
        );
    }, [params.params]);

    useEffect(() => {
        if(quiz.initialized){
            setShowSkeleton(false)
        }
    })

    useEffect(() => {
        // window.addEventListener('scroll', handleScroll);
    });

    function handleScroll() {
        if (params.quizRef?.current?.offsetTop + document.documentElement.scrollTop >= document.documentElement.offsetHeight) {
            setIsVisible({ initialized: true, visible: true });
            setShowSkeleton(false);

        }
    }

    return (
        <>
            <Skeleton active loading={showSkeleton}>
                <SearchTable>
                    <p><b>Quiz</b></p>
                    <Row style={{ marginBottom: '15px' }}>
                        <Col span={24}>
                            <div className="table-bordered table-responsive">
                                <Table
                                    columns={landingColumns}
                                    pagination={false}
                                    dataSource={quiz.data}
                                    rowKey='id' />
                            </div>
                        </Col>
                    </Row>
                    <NavLink to={'/learn/campaigns'}>Show more</NavLink>
                </SearchTable>
            </Skeleton>
        </>
    )
}

export default QuizResult;