import actions from './actions';

const { Set_Portfolios } = actions;

const initialState = {
  initialized: false,
  portfolios: [],
};

const salesRepPortfoliosReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Set_Portfolios:
      return {
        initialized: payload.initialized,
        data: payload.data,
      };
    default:
      return state;
  }
};

export { salesRepPortfoliosReducer };