import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main } from "../styled";
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report, Embed, service, Page } from 'powerbi-client';
import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { themeLoading } from "../../redux/themeLayout/actionCreator";
import { bindActionCreators } from "redux";
import { TriggerGetReport } from "./api";




const Reports = () => {

    const [data, setData] = useState({ initialized: false, data: [] });

    const { instance, accounts, inProgress } = useMsal();
    const dispatch = useDispatch();
    const themeLoadingToggle = bindActionCreators(themeLoading, dispatch);
    const account = useAccount(accounts[0] || {});

    const [sampleReportConfig, setReportConfig] = useState({
        type: 'report',
        embedUrl: undefined,
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        settings: undefined,
    });
  

    useEffect(() => {
        if (!data.initialized) {
            TriggerGetReport(setData, instance, account, themeLoadingToggle);
        }
    }, [account, instance, inProgress]);


    useEffect(() => {
        if (data.initialized) {
            setReportConfig({
                ...sampleReportConfig,
                embedUrl: data.data.embedUrl,
                accessToken: data.data.token
            });
        }
    }, [data])


    return (
        <>
            <PageHeader title='Report' />
            <Main>
                <PowerBIEmbed
                    embedConfig={sampleReportConfig}
                    cssClassName={"report-style-class"}
                   
                />
            </Main>
        </>
    )
}

export default Reports;