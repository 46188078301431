import actions from './actions';

const { populateUserRoles } = actions;



export const populateRoles = (permissions) => {
    return (dispatch) => {
        dispatch(populateUserRoles(permissions));
    };
};


