import React from 'react';
import { Table, Alert } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { FigureCart, ProductTable } from '../Style';
import Heading from '../../../components/heading/heading';
import { Button } from '../../../components/buttons/buttons';
import { removeFromCart, updateCartItem } from '../../../redux/cart/actionCreator';
import { useDispatch, useSelector } from 'react-redux';



const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;


const CartTable = (params) => {

  const dispatch = useDispatch();

  const incrementUpdate = (quantity, item) => {
    const data = parseInt(quantity, 10) + 1;
    dispatch(updateCartItem({...item, quantity: data}));
  };

  const decrementUpdate = (quantity, item) => {
    const data = parseInt(quantity, 10) >= 2 ? parseInt(quantity, 10) - 1 : 1;
    dispatch(updateCartItem({...item, quantity: data}));
  };

  const cartDeleted = item => {
    dispatch(removeFromCart(item))
  };

  const productTableData = [];
  if (params.cart !== null) {
    params.cart.map(data => {
      const { id, imageUrl, name, quantity, dnaRewards} = data;
      return productTableData.push({
        key: id,
        product: (
          <div className="cart-single">
            <FigureCart>
              <img style={{ width: 80 }} src={API_ENDPOINT + '/Storage/v1/storage/get-image' + imageUrl}  alt="" />
              <figcaption>
                <div className="cart-single__info">
                  <Heading as="h6">{name}</Heading>
                </div>
              </figcaption>
            </FigureCart>
          </div>
        ),
        dnaRewards: <span className="cart-single-dnaRewards">{dnaRewards}</span>,
        quantity: (
          <div className="cart-single-quantity">
            <Button 
             onClick={() => decrementUpdate(quantity, data)} 
            className="btn-dec" type="light">
              <FeatherIcon icon="minus" size={12} />
            </Button>
            {quantity}
            <Button 
             onClick={() => incrementUpdate(quantity, data)} 
            className="btn-inc" type="light">
              <FeatherIcon icon="plus" size={12} />
            </Button>
          </div>
        ),
        total: <span className="cart-single-t-dnaRewards">{(quantity * dnaRewards).toLocaleString()}</span>,
        action: (
          <div className="table-action">
            <Button
              onClick={() => cartDeleted(data)}
              className="btn-icon"
              to="#"
              size="default"
              type="danger"
              shape="circle"
              transparented
            >
              <FeatherIcon icon="trash-2" size={16} />
            </Button>
          </div>
        ),
      });
    });
  }

  const productTableColumns = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Price',
      dataIndex: 'dnaRewards',
      key: 'dnaRewards',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  return (
    <>
      <ProductTable>
      <div className="table-cart table-responsive">
            <Table pagination={false} dataSource={productTableData} columns={productTableColumns}/>
          </div>
      </ProductTable>
    </>
  );
};

export default CartTable;
