const LOGIN_REQUEST = process.env.REACT_APP_LOGIN_REQUEST;

export const protectedResourcesApi = {
  loginRequest: {
    scopes: [LOGIN_REQUEST],
  },
  orders: {
    endpoint: 'Orders/v1/orders/get-orders',
  },
  orderDetail: {
    endpoint: 'Orders/v1/orders/get-order',
  },
  orderDocument: {
    endpoint: 'Orders/v1/orders/get-document',
  },
  titles: {
    endpoint: 'register/v1/register/get-register',
  },
  mobiMims: {
    endpoint: 'Mobimims/v1/Mobimims',
  },
  register: {
    endpoint: 'Register/v1/register/save-register',
  },
  roles: {
    endpoint: 'Accounts/v1/account/get-roles',
  },
  account: {
    endpoint: 'Accounts/v1/account/get-userprofile',
  },
  clients: {
    endpoint: 'Orders/v1/orders/get-clients',
  },
  tickets: {
    endpoint: 'tickets/v1/tickets/get-tickets',
  },
  ticketDetails: {
    endpoint: 'tickets/v1/tickets/get-ticket',
  },
  ticketDocument: {
    endpoint: 'tickets/v1/tickets/get-document',
  },
  saveTicket: {
    endpoint: 'tickets/v1/tickets/save-ticket',
  },
  saveTicketReply: {
    endpoint: 'tickets/v1/tickets/save-reply',
  },
  getTeamDetails: {
    endpoint: 'accounts/v1/account/get-teamdetail',
  },
  getCompanyInfo: {
    endpoint: 'accounts/v1/account/get-company',
  },
  getKBArticle: {
    endpoint: 'KnowledeBase/v1/kb/get-article',
  },
  getKBSingleArticle: {
    endpoint: 'KnowledeBase/v1/kb/get-articledetail',
  },
  saveKBFeedback: {
    endpoint: 'KnowledeBase/v1/kb/save-feedback',
  },
  courses: {
    endpoint: 'academy/v1/course/get-courses',
  },
  similarCourses: {
    endpoint: 'academy/v1/course/get-similar-courses',
  },
  recommendedProducts: {
    endpoint: 'academy/v1/product/get-recommended-product',
  },
  portfolios: {
    endpoint: 'academyAdmin/v1/course-admin/get-portfolios',
  },
  portfolioOfEvidence: {
    endpoint: 'academyAdmin/v1/portfolio-admin/manage-portfolio-evidence',
  },
  salesRepPOE: {
    endpoint: 'academyAdmin/v1/portfolio-admin/get-user-portfolio-evidence',
  },
  salesRepUserPOE: {
    endpoint: 'academy/v1/portfolio/get-user-portfolio-evidence',
  },
  coursesAdmin: {
    endpoint: 'academyAdmin/v1/course-admin/get-courses',
  },
  courseDetail: {
    endpoint: 'academy/v1/course/get-course',
  },
  courseAdminDetail: {
    endpoint: 'academyAdmin/v1/course-admin/get-course',
  },
  courseTags: {
    endpoint: 'academyAdmin/v1/course-admin/get-course-tags',
  },
  courseSave: {
    endpoint: 'academyAdmin/v1/course-admin/save-course',
  },
  scheduledAssessmentsGet: {
    endpoint: 'academyAdmin/v1/course-admin/get-scheduled-assessments',
  },
  learnerResultsGet: {
    endpoint: 'academyAdmin/v1/course-admin/get-scheduled-enrolled-learner',
  },
  releaseResults: {
    endpoint: 'academyAdmin/v1/course-admin/release-graded-learner-assessment',
  },
  learnerAssessmentGet: {
    endpoint: 'academyAdmin/v1/course-admin/get-scheduled-enrolled-learner-assessment',
  },
  learnerAssessmentReportGet: {
    endpoint: 'academyAdmin/v1/course-admin/get-scheduled-enrolled-learner-assessment-report',
  },
  learnerAssessmentGradingPost: {
    endpoint: 'academyAdmin/v1/course-admin/grade-scheduled-enrolled-learner-assessment',
  },
  courseSetStatus: {
    endpoint: 'academyAdmin/v1/course-admin/set-status',
  },
  courseBookmark: {
    endpoint: 'academy/v1/course/course-bookmark',
  },
  courseEnroll: {
    endpoint: 'academy/v1/course/course-enroll',
  },
  rateCourse: {
    endpoint: 'academy/v1/course/rate-course',
  },
  courseDownloadCertificate: {
    endpoint: 'academy/v1/course/get-certificate',
  },
  getAssessmentTypes: {
    endpoint: 'academyAdmin/v1/assessment-admin/get-assessment-types',
  },
  assessmentSave: {
    endpoint: 'academyAdmin/v1/assessment-admin/save-assessment',
  },
  assessmentsAdmin: {
    endpoint: 'academyAdmin/v1/assessment-admin/get-assessments',
  },
  assessmentAdminDetail: {
    endpoint: 'academyAdmin/v1/assessment-admin/get-assessment',
  },
  assessmentDetail: {
    endpoint: 'academy/v1/assessment/get-assessment',
  },
  assessmentSetStatus: {
    endpoint: 'academyAdmin/v1/assessment-admin/set-status',
  },
  assessmentStart: {
    endpoint: 'academy/v1/assessment/start-assessment',
  },
  assessmentSubmit: {
    endpoint: 'academy/v1/assessment/complete-assessment',
  },
  lessonSave: {
    endpoint: 'academyAdmin/v1/lesson-admin/save-lesson',
  },
  lessonsAdmin: {
    endpoint: 'academyAdmin/v1/lesson-admin/get-lessons',
  },
  lessonsAdminDetail: {
    endpoint: 'academyAdmin/v1/lesson-admin/get-lesson',
  },
  lessonSetStatus: {
    endpoint: 'academyAdmin/v1/lesson-admin/set-status',
  },
  lessonDetail: {
    endpoint: 'academy/v1/lesson/get-lesson',
  },
  pharmaceuticalCategory: {
    endpoint: 'academyAdmin/v1/category-admin/get-categories',
  },

  lookup: {
    endpoint: 'academyAdmin/v1/courses-admin/get-lookup?lookupTypes=',
  },
  categoriesave: {
    endpoint: 'academyAdmin/v1/category-admin/save-category',
  },
  getSingleCategory: {
    endpoint: 'academyAdmin/v1/category-admin/get-category',
  },
  getProductList: {
    endpoint: 'academyAdmin/v1/product-admin/get-products',
  },
  productSave: {
    endpoint: 'academyAdmin/v1/product-admin/save-product',
  },
  getSingleProduct: {
    endpoint: 'academyAdmin/v1/product-admin/get-product',
  },
  getCategoryExternal: {
    endpoint: 'academy/v1/category/get-categories',
  },
  getSingleCategoryExternal: {
    endpoint: 'academy/v1/category/get-category',
  },
  getSingleProductExternal: {
    endpoint: 'academy/v1/product/get-product',
  },
  categoriesetStatus: {
    endpoint: 'academyAdmin/v1/category-admin/set-status',
  },
  productSetStatus: {
    endpoint: 'AcademyAdmin/v1/product-admin/set-status',
  },
  getThemes: {
    endpoint: 'KnowledeBase/v1/kb/get-themes',
  },
  getRewardList: {
    endpoint: 'reward/v1/reward-admin/get-rewards',
  },
  rewardSave: {
    endpoint: 'reward/v1/reward-admin/save-reward',
  },
  rewardSetStatus: {
    endpoint: 'reward/v1/reward-admin/set-status',
  },
  rewardAdminDetail: {
    endpoint: 'reward/v1/reward-admin/get-reward',
  },
  getSearch: {
    endpoint: 'KnowledeBase/v1/kb/search',
  },
  rewardsGet: {
    endpoint: 'reward/v1/reward/get-campaigns',
  },
  getRewardDetails: {
    endpoint: 'reward/v1/reward/get-reward',
  },
  placeOrder: {
    endpoint: 'reward/v1/reward/placeorder',
  },
  getSingleFaqTheme: {
    endpoint: 'KnowledeBase/v1/kb/get-theme',
  },
  getLandingPage: {
    endpoint: 'landing/v1/landing/get-info',
  },
  getEditProfileLookUp: {
    endpoint: 'accounts/v1/account/get-lookups',
  },
  getProfileDetails: {
    endpoint: 'accounts/v1/account/get-userprofile',
  },
  getTicketLookUp: {
    endpoint: 'tickets/v1/tickets/get-lookups',
  },
  editUser: {
    endpoint: 'accounts/v1/account/save-userprofile',
  },
  saveUserImage: {
    endpoint: 'accounts/v1/account/save-picture',
  },
  searchCourses: {
    endpoint: 'General/v1/general/search-courses',
  },
  searchProducts: {
    endpoint: 'General/v1/general/search-products',
  },
  searchCampines: {
    endpoint: 'General/v1/general/search-campaigns',
  },
  searchOrders: {
    endpoint: 'General/v1/general/search-orders',
  },
  searchTickets: {
    endpoint: 'General/v1/general/search-tickets',
  },
  searchQuiz: {
    endpoint: 'General/v1/general/search-quiz',
  },
  getReport: {
    endpoint: 'General/v1/general/get-report',
  },
  getFeatureProduct: {
    endpoint: 'Academy/v1/category/get-categories-featuredproducts',
  },
  quizSave: {
    endpoint: 'QuizAdmin/v1/quiz-admin/save-quiz',
  },
  getQuizList: {
    endpoint: 'QuizAdmin/v1/quiz-admin/get-quizzes',
  },
  getQuizDetails: {
    endpoint: 'QuizAdmin/v1/quiz-admin/get-quiz',
  },
  setQuizStatus: {
    endpoint: 'QuizAdmin/v1/Quiz-admin/set-status',
  },
  editCompany: {
    endpoint: 'accounts/v1/account/save-company',
  },
  getProductByType: {
    endpoint: 'Academy/v1/category/get-categories-featuredproducts',
  },
  getProductLookUp: {
    endpoint: 'AcademyAdmin/v1/courses-admin/get-lookup?lookupTypes=ScheduleType',
  },
  getQuizzes: {
    endpoint: 'Quiz/v1/quiz/get-quizzes',
  },
  getQuizDetail: {
    endpoint: 'academy/v1/course/get-course',
  },
  getMarketingBannerImages: {
    endpoint: 'storage/v1/storage/get-marketing-banner',
  },
  getNotificationsGetAllHistory: {
    endpoint: 'notification/v1/notification/get-all-history',
  },
  getNotificationsGetAllUnread: {
    endpoint: 'notification/v1/notification/get-all-unread',
  },
  notificationReadStatusPost: {
    endpoint: 'notification/v1/notification/update-read-status',
  },
  notificationUnReadStatusPost: {
    endpoint: 'notification/v1/notification/update-unread-status',
  },
  notificationDeletePost: {
    endpoint: 'notification/v1/notification/delete-notification',
  },
};
