import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ArrowLeftOutlined } from '@ant-design/icons';

const BackButton = () => {
  const history = useHistory();
  return (
    <>
      <Button onClick={() => history.goBack()} type={'text'}>
        <ArrowLeftOutlined />
      </Button>
    </>
  );
};

export default BackButton;
