import { protectedResourcesApi } from './api';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const SIGN_IN_NAME = process.env.REACT_APP_SIGN_IN_NAME;
const RESET_PASSWORD_NAME = process.env.REACT_APP_RESET_PASSWORD_NAME;
const AUTHORITY_DOMAIN = process.env.REACT_APP_AUTHORITY_DOMAIN;

export const b2cPolicies = {
  names: {
    signUpSignIn: SIGN_IN_NAME,
    forgotPassword: RESET_PASSWORD_NAME,
  },
  authorities: {
    signUpSignIn: {
      authority: `https://ciplasaga.b2clogin.com/ciplasaga.onmicrosoft.com/${SIGN_IN_NAME}`,
    },
    forgotPassword: {
      authority: `https://ciplasaga.b2clogin.com/ciplasaga.onmicrosoft.com/${RESET_PASSWORD_NAME}`,
    },
  },
  authorityDomain: AUTHORITY_DOMAIN,
};

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: window.location.origin,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const msalForgetPasswordConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: b2cPolicies.authorities.forgotPassword.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const protectedResources = {  
  ...protectedResourcesApi
};

export const loginRequest = {
  scopes: protectedResourcesApi.loginRequest.scopes,
};

export const forgetPasswordRequest = {
  scopes: protectedResourcesApi.loginRequest.scopes,
};
