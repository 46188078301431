import React, { useEffect, useState, Suspense } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { useDispatch, Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import store from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './config/auth/authConfig';
import ProtectedRoute from './components/utilities/protectedRoute';
// // import 'antd/dist/antd.css';;

import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react';
import { login } from './redux/authentication/actionCreator';
import {
  PermissionsWrapper,
  GetComponentPermissions,
  GetPermissions,
} from './components/AccessControl/PermisionWrapper';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ReactGA from "react-ga4";

const { theme } = config;
const msalInstance = new PublicClientApplication(msalConfig);

const ProviderConfig = () => {
  const { rtl, isLoggedIn, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
    };
  });
  const dispatch = useDispatch();
  const { instance } = useMsal();
  let accountId = '';
  const [path, setPath] = useState(window.location.pathname);

  const history = useHistory();

  msalInstance
    .handleRedirectPromise()
    .then(response => {
      if (response !== null) {
        if (window.location.href.includes('password')) {
          window.location.href = window.location.origin;
        }
      }
    })
    .catch(error => {
      console.error(error);
      window.location.href = window.location.origin;
    });

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  // instance.handleRedirectPromise().then(handleLoginResponse);
  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn ? <Route component={Auth} /> : <ProtectedRoute path="/" component={Admin} />}
          {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
            <Redirect to="/" />
          )}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function Landing_Authenticated() {
  const changeLayoutMode = useSelector(state => state.ChangeLayoutMode);

  const { isLoggedIn } = useSelector(state => {
    return {
      isLoggedIn: state.auth.login,
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(login());
    }
  }, [isLoggedIn]);

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const permissions = useSelector(state => state.permission);

  GetPermissions({
    instance: instance,
    account: account,
    inProgress: inProgress,
  });

  return (
    <Suspense>
      <ProviderConfig />
    </Suspense>
  );
}

  
function App() {
  ReactGA.initialize(process.env.REACT_APP_GA_ID);

  return (
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          <Landing_Authenticated />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <ProviderConfig />
        </UnauthenticatedTemplate>
      </MsalProvider>
    </Provider>
  );
}

export default hot(App);
