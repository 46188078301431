import Styled from 'styled-components';

const Div = Styled.div`
    position: relative;
    header{
        box-shadow: 0 2px 30px ${({ theme }) => theme['gray-solid']}10;
        ${({ darkMode }) => (darkMode ? `background: #272B41;` : '')};
        z-index: 999;

        @media print {
            display: none;
        }

        .ant-btn-link{
            ${({ darkMode }) =>
        darkMode ? `background: #272B41;border-color: #272B41;color: #7D808D !important` : ''};
        }

        .head-example{
            ${({ darkMode }) => (darkMode ? `color: #A8AAB3;` : '')};
        }
        .ant-badge-count{
            padding: 0!important;
        }
        .ant-menu-sub.ant-menu-vertical{
            .ant-menu-item{
                a{
                    color: ${({ theme }) => theme['gray-color']};
                }
            }
        }
        .ant-menu.ant-menu-horizontal{
            display: flex;
            align-items: center;
            margin: 0 -16px;
            li.ant-menu-submenu{
                margin: 0 16px;
            }
            .ant-menu-submenu{
                &.ant-menu-submenu-active,
                &.ant-menu-submenu-selected,
                &.ant-menu-submenu-open{
                    .ant-menu-submenu-title{
                        color: ${({ darkMode }) => (darkMode ? `#fff;` : '#5A5F7D')};
                        svg,
                        i{
                            color: ${({ darkMode }) => (darkMode ? `#fff;` : '#5A5F7D')};
                        }
                    }
                }
                .ant-menu-submenu-title{
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ darkMode }) => (darkMode ? `#ffffff90;` : '#5A5F7D')};
                    svg,
                    i{
                        color: ${({ darkMode }) => (darkMode ? `#ffffff90;` : '#5A5F7D')};
                    }
                    .ant-menu-submenu-arrow{
                        font-family: "FontAwesome";
                        font-style: normal;
                        ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 6px;
                        &:after{
                            color: ${({ darkMode }) => (darkMode ? `#ffffff90;` : '#9299B8')};
                            content: '\f107';
                            background-color: transparent;
                        }
                    }
                }
            }
        }
       

    }
    .header-more{
        .head-example{
            ${({ darkMode }) => (darkMode ? `color: #A8AAB3;` : '')};
        }
    }
    .customizer-trigger{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: ${({ theme }) => (theme.rtl ? '0 10px 10px 0' : '10px 0 0 10px')};
        background-color: #0055b8;
        position: fixed;
        ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: all .3s ease;
        z-index: 999;
        box-shadow: 0 10px 15px rgba(#0055b8,.20);
        &.show{
            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 295px;
        }
        @media print {
            display: none;
        }
        svg,
        img{
            width: 20px;
            color: #fff;
            animation: antRotate 3s infinite linear;
        }
    }
    .customizer-wrapper{
        position: fixed;
        top: 0;
        ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
        width: 350px;
        transform: translateX(${({ theme }) => (theme.rtl ? '-350px' : '350px')});
        height: 100vh;
        overflow-y: auto;
        background-color: #fff;
        box-shadow: 0 0 30px #9299B810;
        z-index: 99999999999;
        transition: all .3s ease;
        @media only screen and (max-width: 479px){
            width: 280px;
            transform: translateX(${({ theme }) => (theme.rtl ? '-280px' : '280px')});
        }
        &.show{
            transform: translateX(0);
        }
    }
    .customizer{
        height: 100%;
        .customizer__head{
            position: relative;
            padding: 18px 24px;
            border-bottom: 1px solid #f0f0f0;
            text-align: left;
            .customizer-close{
                position: absolute;
                right: 15px;
                top: 15px;
                svg,
                i{
                    color: #FF4D4F;
                }
            }
            .customizer__title{
                font-weight: 600;
                color: #272B41;
                font-size: 16px;
                margin-bottom: 2px;
            }
        }
        .customizer__body{
            padding: 25px;
        }
        .customizer__single{
            &:not(:last-child){
                margin-bottom: 35px;
            }
            h4{
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 10px;
                color: #272B41;
            }
        }
    }
    .customizer-list{
        margin: -10px;
        .customizer-list__item{
            position: relative;
            display: inline-block;
            min-height: 60px;
            background-size: cover;
            margin: 10px;
            &.top{
                span.fa{
                    top: 35px;
                }
            }
            &:hover{
                span{
                    color: #0055b8;
                }
            }
            a{
                position: relative;
                display: block;
                &.active{
                    span.fa{
                        display: block;
                    }
                }
                span.fa{
                    display: none;
                    font-size: 16px;
                    margin-top: 0;
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    color: ${({ theme }) => theme['success-color']};
                }
            }
            img{
                width: 100%;
            }
            span{
                display: inline-block;
                margin-top: 15px;
                color: #272B41;
            }
        }
    }
    .striking-logo{
        @media only screen and (max-width: 875px){
            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 4px;
        }
        @media only screen and (max-width: 767px){
            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 0;
        }
        img{
            max-width: ${({ theme }) => (theme.topMenu ? '200px' : '200px')};
            width: 100%;
        }
        &.top-menu{
            ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 15px;
        }
    }
    .certain-category-search-wrapper{
        ${({ darkMode, theme }) =>
        darkMode ? `${!theme.rtl ? 'border-right' : 'border-left'}: 1px solid #272B41;` : ''};
         @media only screen and (max-width: 767px){
            padding: 0 15px;
        }
        input{
            max-width: 350px;
            ${({ darkMode }) => (darkMode ? `background: #272B41;` : '')};
            ${({ darkMode }) => (darkMode ? `color: #fff;` : '#5A5F7D')};
            @media only screen and (max-width: 875px){
                ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 5px;
            }
        }
    }
    
    .navbar-brand{
        button{
            padding: ${({ theme }) => (theme.rtl ? '0 15px 0 25px' : '0 25px 0 15px')};
            line-height: 0;
            margin-top: 4px;
            color: ${({ theme }) => theme['extra-light-color']};
            @media only screen and (max-width: 875px){
                padding: ${({ theme }) => (theme.rtl ? '0 10px 0 25px' : '0 25px 0 10px')};
            }
            @media only screen and (max-width: 767px){
                padding: ${({ theme }) => (theme.rtl ? '0 0px 0 15px' : '0 15px 0 0px')};
            }
        }
    }

    /* Sidebar styles */
    .ant-layout-sider{
        box-shadow: 0 0 30px #9299B810;
        @media (max-width: 991px){
            box-shadow: 0 0 10px #00000020;
        }
        @media print {
            display: none;
        }
        &.ant-layout-sider-dark{
            background: ${({ theme }) => theme['dark-color']};
            .ant-layout-sider-children{
                .ant-menu{
                    .ant-menu-submenu-inline{
                        > .ant-menu-submenu-title{
                            padding: 0 30px !important;
                        }
                    }
                    .ant-menu-item{
                        padding: 0 30px !important;
                    }
                }
            }
        }
        
        .ant-layout-sider-children{
            padding-bottom: 15px;
            >.sidebar-nav-title{
                margin-top: 8px;
            }

            .ant-menu{
                overflow-x: hidden;
                .ant-menu-sub.ant-menu-inline{
                    background-color: #fff;
                }
               
                .ant-menu-submenu, .ant-menu-item{
                    .feather,
                    img{
                        width: 16px;
                        font-size: 16px;
                        color: ${({ theme }) => theme['extra-light-color']};
                    }
                    span{
                        display: inline-block;
                        color: ${({ theme }) => theme['dark-color']};
                        transition: 0.3s ease;
                        a{
                            ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 10px;
                        }
                    }
                    .sDash_menu-item-icon{
                        line-height: .6;
                    }
                }
                .ant-menu-submenu{
                    span{
                        ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 20px;
                    }
                }
                .ant-menu-item{
                    .menuItem-iocn{
                        width: auto;
                    }
                }
                .ant-menu-item,
                .ant-menu-submenu-title{
                    a{
                        position: relative;
                    }
                    >span{
                        width: 100%;
                        .pl-0{
                            ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0px;
                        }
                    }
                    .badge{
                        position: absolute;                        
                        ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 30px;
                        top: 50%;
                        transform: translateY(-50%);
                        display: inline-block;
                        height: auto;
                        font-size: 10px;
                        border-radius: 3px;
                        padding: 3px 4px 4px;
                        line-height: 1;
                        letter-spacing: 1px;
                        color: #fff;
                        &.badge-primary{
                            background-color: ${({ theme }) => theme['primary-color']};
                        }
                        &.badge-success{
                            background-color: ${({ theme }) => theme['success-color']};
                        }
                    }
                }
                .ant-menu-submenu{
                    .ant-menu-submenu-title{
                        display: flex;
                        align-items: center;
                        .title{
                            padding-left: 0;
                        }
                        .badge{
                            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 45px;
                        }
                    }
                }
                .ant-menu-submenu-inline{
                    > .ant-menu-submenu-title{
                        display: flex;
                        align-items: center;
                        padding: 0 15px !important;
                        svg,
                        img{
                            width: 16px;
                            height: 16px;
                        }
                                                
                        .ant-menu-submenu-arrow{
                            right: auto;
                            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 24px;
                            &:after,
                            &:before{
                                width: 7px;
                                background: #868EAE;
                                height: 1.25px;
                            }
                            &:before{
                                transform: rotate(45deg) ${({ theme }) =>
        !theme.rtl ? 'translateY(-3.3px)' : 'translateY(3.3px)'};
                            }
                            &:after{
                                transform: rotate(-45deg) ${({ theme }) =>
        theme.rtl ? 'translateY(-3.3px)' : 'translateY(3.3px)'};
                            }
                        }
                    }
                    &.ant-menu-submenu-open{
                        > .ant-menu-submenu-title{
                            .ant-menu-submenu-arrow{
                                transform: translateY(2px);
                                &:before{
                                    transform: rotate(45deg) translateX(-3.3px);
                                }
                                &:after{
                                    transform: rotate(-45deg) translateX(3.3px);
                                }
                            }
                        }
                    }
                    .ant-menu-item{
                        ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0px !important;
                        ${({ theme }) => (theme.rtl ? 'padding-left' : 'padding-right')}: 0 !important;
                        transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
                        a{
                            ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 50px !important;
                        }
                    }
                }
                .ant-menu-item{
                    display: flex;
                    align-items: center;
                    padding: 0 15px !important;
                    &.ant-menu-item-active{
                        border-radius: 4px;
                        ${({ darkMode }) => (darkMode ? `background-color: rgba(255, 255, 255, .05);` : '')};
                    }
                    a{
                        width: 100%;
                        display: flex !important;
                        align-items: center;
                        .feather{
                            width: 16px;
                            color: ${({ theme }) => theme['extra-light-color']};
                        }
                        span{
                            ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 20px;
                            display: inline-block;
                            color: ${({ theme }) => theme['dark-color']};
                        }
                    }
                    &.ant-menu-item-selected{
                        svg,
                        i{
                            color: ${({ theme }) => theme['primary-color']};
                        }
                    }
                }
                .ant-menu-submenu,
                .ant-menu-item{
                    ${({ theme }) => theme.rtl && `padding-right: 5px;`}
                    
                    &.ant-menu-item-selected{
                        border-radius: 4px;
                        &:after{
                            content: none;
                        }
                    }
                    &.ant-menu-submenu-active{
                        border-radius: 4px;
                        ${({ darkMode }) => (darkMode ? `background-color: rgba(255, 255, 255, .05);` : '')};
                    }
                }
                .sidebar-nav-title{
                    margin-top: 40px;
                    margin-bottom: 24px;
                }
                &.ant-menu-inline-collapsed{
                    .ant-menu-submenu{
                        text-align: ${({ theme }) => (!theme.rtl ? 'left' : 'right')};                        
                        .ant-menu-submenu-title{
                            padding: 0 20px;
                            justify-content: center;
                        }
                    }
                    .ant-menu-item{
                        padding: 0 20px !important;
                        justify-content: center;
                    }
                    .ant-menu-submenu, .ant-menu-item{
                        span{
                            display: none;
                        }
                    }
                }
            }
        }
        .sidebar-nav-title{
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            ${({ darkMode }) => (darkMode ? `color: rgba(255, 255, 255, .38);` : 'color: #9299B8;')};
            padding: 0 ${({ theme }) => (theme.rtl ? '20px' : '15px')};
            display: flex;
        }
        &.ant-layout-sider-collapsed{
            padding: 15px 0px 55px !important;
            .sidebar-nav-title{
                display: none;
            }
            & + .atbd-main-layout{
                ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 80px;
            }
            .ant-menu-item{
                color: #333;
                .badge{
                    display: none;
                }
            }
        }
    }
    @media only screen and (max-width: 1150px){
        .ant-layout-sider.ant-layout-sider-collapsed{
            ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: -80px !important;
        }

    }

    .atbd-main-layout{
        ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: ${({ theme }) =>
        theme.topMenu ? 0 : '280px'};
        margin-top: 45px;
        transition: 0.3s ease;
        @media only screen and (max-width: 1150px){
            ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: auto !important;
        }
        @media print {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }

    /* Mobile Actions */
    .mobile-action{
        position: absolute;
        ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 20px;
        top: 50%;
        transform: translateY(-50%);
        display: inline-flex;
        align-items: center;
        @media only screen and (max-width: 767px){
            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
        }
        a{
            display: inline-flex;
            color: ${({ theme }) => theme['light-color']};
            &.btn-search{
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 18px;
            }
            svg{
                width: 20px
                height: 20px;
            }
        }
    }
    .admin-footer{
        @media print {
            display: none;
        }
        .admin-footer__copyright{
            display: inline-block;
            width: 100%;
            color: white;
            @media only screen and (max-width: 767px){
                text-align: center;
                margin-bottom: 10px;
            }
        }
        .admin-footer__links{
            text-align: ${({ theme }) => (theme.rtl ? 'left' : 'right')};
            @media only screen and (max-width: 767px){
                text-align: center;
            }
            a{
                color: white;
                &:not(:last-child){
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
                }
                &:hover{
                    color: ${({ theme }) => theme['primary-color']};
                }
            }
        }
    }    
`;

const SmallScreenAuthInfo = Styled.div`
        ${({ darkMode }) => (darkMode ? `background: #272B41;` : 'background: #fff')};
        width: 100%;
        position: fixed;
        margin-top: ${({ hide }) => (hide ? '0px' : '64px')};
        top: 0;
        ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: 0;
        transition: .3s;
        opacity: ${({ hide }) => (hide ? 0 : 1)}
        z-index: ${({ hide }) => (hide ? -1 : 1)}
        box-shadow: 0 2px 30px #9299b810;

`;

const SmallScreenSearch = Styled.div`
        ${({ darkMode }) => (darkMode ? `background: #272B41;` : 'background: #fff')};
        width: 100%;
        position: fixed;
        margin-top: ${({ hide }) => (hide ? '0px' : '64px')};
        top: 0;
        ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: 0;
        transition: .3s;
        opacity: ${({ hide }) => (hide ? 0 : 1)}
        z-index: ${({ hide }) => (hide ? -1 : 999)}
        box-shadow: 0 2px 30px #9299b810;

`;

const ModeSwitch = Styled.div`
    background: #ddd;
    width: 200px;
    position: fixed;
    ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
    top: 50%;
    margin-top: -100px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 5px;
    button{
        margin-top: 5px;
    }
`;

const TopMenuSearch = Styled.div`
    .top-right-wrap{
        position: relative;
        float: ${({ theme }) => (theme.rtl ? 'left' : 'right')};
    }
    .search-toggle{
        display: flex;
        align-items: center;
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
        ${({ theme }) => (theme.darkMode ? `color: #A8AAB3;` : 'color :#5A5F7D')};
        .feather-x{
            display: none;
        }
        .feather-search{
            display: flex;
        }
        &.active{
            .feather-search{
                display: none;
            }
            .feather-x{
                display: flex;
            }
        }
        svg,
        img{
            width: 20px;
        }
    }
    .topMenu-search-form{
        position: absolute;
        ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 100%;
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
        top: 12px;
        background-color: #fff;
        border: 1px solid ${({ theme }) => theme['border-color-normal']};
        border-radius: 6px;
        height: 40px;
        width: 280px;
        display: none;
        &.show{
            display: block;
        }
        .search-icon{
            width: fit-content;
            line-height: 1;
            position: absolute;
            left: 15px;
            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 15px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 9999;
        }
        i,
        svg{
            width: 18px;
            color: ${({ theme }) => (theme.darkMode ? `color: #A8AAB3;` : 'color:# 9299b8')};
        }
        form{
            height: auto;
            display: flex;
            align-items: center;
        }
        input{
            position: relative;
            border-radius: 6px;
            width: 100%;
            border: 0 none;
            height: 38px;
            padding-left: 40px;
            z-index: 999;
            ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 40px;
            &:focus{
                border: 0 none;
                box-shadow: 0 0;
                outline: none;
            }
        }
    }
`;

const TopMenuStyle = Styled.div`
    .strikingDash-top-menu{
        ul{
            li{
                display: inline-block;
                position: relative;
                ${({ theme }) => (theme.rtl ? 'padding-left' : 'padding-right')}: 14px;
                @media only screen and (max-width: 1024px){
                    ${({ theme }) => (theme.rtl ? 'padding-left' : 'padding-right')}: 10px;
                }
                &:not(:last-child){
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 34px;
                    @media only screen and (max-width: 1300px){
                        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 30px;
                    }
                    @media only screen and (max-width: 1199px){
                        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 26px;
                    }
                    @media only screen and (max-width: 1024px){
                        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 16px;
                    }
                }
                &.has-subMenu{
                    >a{
                        position: relative;
                        &:before{
                            position: absolute;
                            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: -14px;
                            top: 50%;
                            transform: translateY(-50%);
                            font-family: "FontAwesome";
                            content: '\f107';
                            line-height: 1;
                            color: ${({ theme }) => theme['light-color']};
                        }
                        &.active{
                            &:before{
                                ${({ theme }) => (theme.darkMode ? `color: #fff;` : 'color: #0055b8')};
                            }
                        }
                    }
                }
                &.has-subMenu-left{
                    >a{
                        position: relative;
                        &:before{
                            position: absolute;
                            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 30px;
                            top: 50%;
                            transform: translateY(-50%);
                            font-family: "FontAwesome";
                            content: '\f105';
                            line-height: 1;
                            color: ${({ theme }) => theme['light-color']};
                        }
                    }
                }
                &:hover{
                    >.subMenu{
                        top: 64px;
                        opacity: 1;
                        visibility: visible;
                    }
                }
                a{
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    ${({ theme }) => (theme.darkMode ? `color: #ffffff60;` : 'color: #5A5F7D')};
                    &.active{
                        ${({ theme }) => (theme.darkMode ? `color: #fff;` : 'color: #0055b8')};
                    }
                    svg,
                    img,
                    i{
                        margin-right: 14px;
                        width: 16px;
                    }
                }
                >ul{
                    li{
                        display: block;
                        position: relative;
                        ${({ theme }) => (theme.rtl ? 'padding-left' : 'padding-right')}: 0;
                        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 0 !important;
                        a{
                            font-weight: 400;
                            padding: 0 30px;
                            line-height: 3;
                            color: #868EAE;
                            transition: .3s;
                            &:hover,
                            &.active{
                                color: ${({ theme }) => theme['primary-color']};
                                background-color: ${({ theme }) => theme['primary-color']}06;
                                ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 40px;
                            }
                        }
                        &:hover{
                            .subMenu{
                                top: 0;
                                ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 250px;
                                @media only screen and (max-width: 1300px){
                                    ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 180px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .subMenu{
            width: 250px;
            background: #fff;
            border-radius: 6px;
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
            top: 80px;
            padding: 12px 0;
            visibility: hidden;
            opacity: 0;
            transition: 0.3s;
            z-index: 98;
            box-shadow: 0px 15px 40px 0px rgba(82, 63, 105, 0.15);
            @media only screen and (max-width: 1300px){
                width: 180px;
            }
            .subMenu{
                width: 250px;
                background: #fff;
                position: absolute;
                ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 250px;
                top: 0px;
                padding: 12px 0;
                visibility: hidden;
                opacity: 0;
                transition: 0.3s;
                z-index: 98;
                box-shadow: 0px 15px 40px 0px rgba(82, 63, 105, 0.15);
                @media only screen and (max-width: 1300px){
                    width: 200px;
                    ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 180px;
                }
            }
        }
    }
    // Mega Menu
    .strikingDash-top-menu{
        >ul{
            >li{
                &:hover{
                    .megaMenu-wrapper{
                        opacity: 1;
                        visibility: visible;
                        z-index: 99;
                    }
                }
                &.mega-item{
                    position: static;
                }
                .sDash_menu-item-icon{
                    line-height: .6;
                }
                .megaMenu-wrapper{
                    display: flex;
                    position: absolute;
                    text-align: ${({ theme }) => (theme.rtl ? 'right' : 'left')}
                    ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
                    top: 100%;
                    overflow: hidden;
                    z-index: -1;
                    padding: 16px 0;
                    box-shadow: 0px 15px 40px 0px rgba(82, 63, 105, 0.15);
                    border-radius: 0 0 6px 6px;
                    opacity: 0;
                    visibility: hidden;
                    transition: .4s;
                    background-color: #fff;
                    &.megaMenu-small{
                        width: 590px;
                        >li{
                            flex: 0 0 33.3333%;
                        }
                        ul{
                            li{
                                >a{
                                    padding: 0 45px;
                                    position: relative
                                    &:after{
                                        width: 5px;
                                        height: 5px;
                                        border-radius: 50%;
                                        position: absolute;
                                        ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 30px;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        background-color: #C6D0DC;
                                        content: '';
                                        transition: .3s;
                                    }
                                    &:hover,
                                    &.active{
                                        ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 45px;
                                        color: ${({ theme }) => theme['primary-color']};
                                        &:after{
                                            background-color: ${({ theme }) => theme['primary-color']};;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.megaMenu-wide{
                        width: 1000px;
                        padding: 5px 0 18px;
                        @media only screen and (max-width: 1300px){
                            width: 800px;
                        }
                        >li{
                            position: relative;
                            flex: 0 0 25%;
                            .mega-title{
                                position: relative;
                                font-size: 14px;
                                font-weight: 500;
                                padding-left: 45px;
                                ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 45px;
                                color: ${({ theme }) => theme['dark-color']};
                                &:after{
                                    position: absolute;
                                    height: 5px;
                                    width: 5px;
                                    border-radius: 50%;
                                    ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 30px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    background-color: #C6D0DC;
                                    content: '';
                                }
                            }
                        }
                    }
                    ul{
                        li{
                            position: relative;
                            &:hover{
                                >a{
                                    padding-left: 45px;
                                }
                                &:after{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                            >a{
                                line-height: 3;
                                color: #868EAE;
                                font-weight: 400;
                                transition: .3s;
                            }
                            
                            &:after{
                                width: 6px;
                                height: 1px;
                                border-radius: 50%;
                                position: absolute;
                                ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 30px;
                                top: 50%;
                                transform: translateY(-50%);
                                background-color: ${({ theme }) => theme['light-color']};
                                content: '';
                                transition: .3s;
                                opacity: 0;
                                visibility: hidden;
                            }
                        }
                    }
                }
            }
        }
    }
`;

const KBArticales = Styled.div`     
    .ant-card-body{
        padding: 20px 0 !important;
    }    
    .post-content{
        padding:20px;
        font-size:28px;
        font-weight: bold;

        .gallery{
            padding: 0 25px;
            img{
                margin-bottom: 10px;
                border-radius: 8px;
            }
        }
        .post-text{
            border-bottom: 1px solid #F1F2F6;
            padding: 0 25px 20px 25px;
            margin-bottom: 20px;
            p{
                font-size: 15px;
                font-weight: normal;
                color: ${({ theme }) => theme['gray-color']};
                &:last-child{
                    margin-bottom: 0;
                }
            }
        } 
        .post-actions{
           margin-top:10px;
           
            span{
                display: inline-flex;
                align-items: center;
                color: ${({ theme }) => theme['extra-light-color']};
                margin-${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 20px;
            }
            a{
                display: inline-flex;
                align-items: center;
                font-size: 13px;
                color: ${({ theme }) => theme['extra-light-color']};
                svg,
                i{
                    margin-${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 6px;
                }
            }
        }
        .commentArea{
            display: flex;
            align-items: center;
            padding: 0 25px 20px 25px;
            border-bottom: 1px solid #F1F2F6;
            @media only screen and (max-width: 991px){
                flex-flow: column;
                align-items: flex-start;
            }
            .comment-form{
                flex: auto;
                position: relative;
                display: flex;
                align-items: center;
                margin-${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 10px;
                @media only screen and (max-width: 991px){
                    flex-flow: column;
                    align-items: flex-start;
                    width: 100%;
                    margin-${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 0;
                    margin-bottom: 15px;
                }
                img{
                    max-width: 36px;
                    margin-${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 10px;
                    border-radius: 50%;
                    @media only screen and (max-width: 991px){
                        margin-${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 0;
                        margin-bottom: 15px;
                    }
                }
                textarea{
                    padding: 14px 22px;
                    height: 50px;
                    border: 0 none;
                    border-radius: 25px;
                    background-color: #F4F5F7;
                    resize: none;
                }
            }
            .btn-send{
                padding: 0;
                height: 50px;
                width: 50px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 10px 15px #5F63F220;
                @media only screen and (max-width: 991px){
                    width: 35px;
                    height: 35px;
                }
                svg,
                i,
                img{
                    width: 16px;
                    color: #fff;
                    @media only screen and (max-width: 991px){
                        width: 14px;
                    }
                }
            }
            .chatbox-reply-action{
                position: absolute;
                ${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 22px;
                align-items: center;
                @media only screen and (max-width: 991px){
                    bottom: 10px;
                }
                .smile-icon{
                    position: relative;                    
                    margin-${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 18px;
                    line-height: 1;
                    @media only screen and (max-width: 991px){
                        margin-${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 8px;
                    }
                    aside{
                        position: absolute;
                        z-index: 9999999;
                    }
                }
                a{
                    line-height: 1;
                    &:not(:last-child){
                        margin-${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 18px;
                        @media only screen and (max-width: 991px){
                            margin-${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 6px;
                        }
                    }
                    svg,
                    i{
                        color: #ADB4D2;
                        width: 18px;
                        @media only screen and (max-width: 991px){
                            width: 14px;
                        }
                    }
                }
                .ant-upload-list {
                    display: none;
                }
                .ant-upload{
                    margin: 0;
                    padding: 0;
                    width: auto;
                    height: auto;
                    line-height: .5;
                    background-color: transparent;
                    border: 0 none;
                }
            }
        }
        .commentReplay{
            padding: 20px 25px 0 25px;
            .ant-comment-content-author-name{
                font-size: 14px;
                font-weight: 600;
                color: ${({ theme }) => theme['dark-color']};
            }
            .ant-comment-actions{
                margin-top: 10px;
            }
        }
    }
`;

const SidebarNavWrap = Styled.div`
    height: fit-content;
    border: 1px solid ${({ theme }) => theme['border-color-normal']};
    border-radius: 4px;
    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 20px;
    height: 100%;
  
    .knowledge-sidebar{
        .knowledge-sidebar__title{
            font-size: 16px;
            font-weight: 500;
            padding: 18px 20px;
            color: ${({ theme }) => theme['dark-color']};
            border-bottom: 1px solid ${({ theme }) => theme['border-color-normal']};
        }
        ul{
            padding: 18px 20px 35px;
            li{
                &:not(:last-child){
                    margin-bottom: 18px;
                }
                a{
                    display: flex;
                    font-size: 16px;
                    font-weight: 500;
                    color: ${({ theme }) => theme['dark-color']};
                    i,
                    svg,
                    img{
                        min-width: 14px;
                        margin-top: 4px;
                        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 8px;
                    }
                }
            }
        }
        .submenu{
            padding: ${({ theme }) => (theme.rtl ? '0px 24px 0 0' : '0px 0 0 24px')};
            margin-top: 10px;
            position: relative;
            &.show{
                display: block;
            }
            &:after{
                position: absolute;
                ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 24px;
                top: 0;
                width: 2px;
                height: 100%;
                content: "";
                background-color: #EAEBEF;
            }
            li{
                margin-bottom: 0 !important;
                a{
                    display: flex;
                    font-size: 15px;
                    font-weight: 400;
                    padding: ${({ theme }) => (theme.rtl ? '6px 22px 6px 0' : '6px 0 6px 22px')};
                    position: relative;
                    color: ${({ theme }) => theme['gray-color']};
                    &.active{
                        &:after{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    &:after{
                        position: absolute;
                        ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0px;
                        top: 0;
                        width: 2px;
                        height: 100%;
                        content: "";
                        z-index: 2;
                        opacity: 0;
                        visibility: hidden;
                        background-color: ${({ theme }) => theme['primary-color']};
                    }
                    &:hover{
                        color: ${({ theme }) => theme['primary-color']};
                    }
                }
            }
            .submenu{
                &:after{
                    display: none;
                }
            }
        }
    }
`;
const KnowledgeDetailsWrap = Styled.div`
    .success-btn{
        border-color: green;
        color: green;

        span{
            margin-left: 10px;
        }
    }
    .danger-btn{
        span{
            margin-left: 10px;
        }
    }
    .green{
        background: green;
        color: white;

        span{
            margin-left: 10px;
        }
    }
    .red{
        background: red;
        color: white;

        span{
            margin-left: 10px;
        }
    }
    .knowledge-details{
        padding: 0 30px;
        @media only screen and (max-width: 1399px){
            padding: 0;
        }
        img{
            width: 100%;
        }
        .knowledge-details__title{
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 20px;
            color: ${({ theme }) => theme['cipla-blue']};
            @media only screen and (max-width: 1199px){
                font-size: 24px;
            }
        }
        .knowledge-details__single--block{

            &:first-child{
                margin-bottom: 55px;
            }
            &:not(:last-child){
                margin-bottom: 40px;
            }
            h4{
                font-size: 22px;
                font-weight: 600;
                margin-bottom: 16px;
                a{
                    color: ${({ theme }) => theme['dark-color']};
                }
            }
            p{
                font-size: 15px;
                color: ${({ theme }) => theme['dark-color']};
            }
            .knowledge-details-img{
                margin-top: 30px;
            }
            .btn-more{
                display: inline-block;
                margin-top: 25px;
                &:hover{
                    text-decoration: underline;
                }
            }
            .ant-collapse {
                border: 0 none;
            }
            .ant-collapse-item{
                border: 0 none;
                .ant-collapse-header{
                    position: relative;
                    padding: 0;
                    background-color: #fff;
                    .anticon{
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        width: 28px;
                        height: 28px;
                        border-radius: 50%;
                        background-color: #fff;
                        box-shadow: 0 2px 2px #92949F30;
                        &.anticon-right{
                            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: auto;
                            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
                        }
                        svg,
                        img,
                        i{
                            min-width: 14px;
                            color: ${({ theme }) => theme['primary-color']};
                        }
                    }
                }
                .ant-collapse-content {
                    border: 0 none;
                    .ant-collapse-content-box{
                        padding: 0;
                    }
                    .btn-more{
                        font-size: 12px;
                        font-weight: 500;
                        color: ${({ theme }) => theme['dark-color']};
                        text-transform: uppercase;
                    }
                }
            }
        }
        .knowledge-details-date-meta{
            font-size: 14px;
            color: ${({ theme }) => theme['light-gray-color']};
            .date{
                ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 3px;
                color: ${({ theme }) => theme['dark-color']};
            }
        }
        .knowledge-details-cta{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            margin: 25px 0;
            min-height: 106px;
            border: 1px solid ${({ theme }) => theme['border-color-normal']};
            box-shadow: 0 5px 10px ${({ theme }) => theme['dark-color']}10;
            @media only screen and (max-width: 575px){
                flex-flow: column;
            }
            .knowledge-details-cta__text{
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 40px;
                @media only screen and (max-width: 575px){
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 0px;
                    margin-bottom: 15px;
                }
                margin-bottom: 0;
            }
            .knowledge-details-cta__actions{
                margin: -5px;
                button{
                    height: 36px;
                    padding: 0px 16px;
                    margin: 5px;
                }
            }
        }
        .knowledge-details__bottom{
            display: flex;
            justify-content: space-between;
            padding-bottom: 50px;
            margin-bottom: 25px;
            border-bottom: 1px solid ${({ theme }) => theme['border-color-normal']};
            @media only screen and (max-width: 575px){
                flex-flow: column;
            }
            .knowledge-details__bottom--left{
                font-size: 16px;
                font-weight: 500;
                @media only screen and (max-width: 575px){
                    margin-bottom: 15px;
                }
                a{
                    margin-left: 14px;
                }
            }
            .knowledge-details__bottom--right{
                .soical-share{
                    li{
                        display: inline-block;
                        font-size: 14px;
                        font-weight: 400;
                        &:first-child{
                            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 20px;
                        }
                        &:not(:last-child){
                            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
                        }
                        a{
                            &:hover{
                                span.fa,
                                i,
                                svg{
                                    color: ${({ theme }) => theme['primray-color']};                            
                                }                            
                            }
                        }
                    }
                }
            }
        }
        .knowledge-details-pagination{
            margin-bottom: 45px;
            ul{
                display: flex;
                justify-content: space-between;
                @media only screen and (max-width: 575px){
                    flex-direction: column;
                }
                li{
                    &.page-next{
                        a{
                            span.fa{
                                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 8px;
                            }
                        }
                    }
                    &.page-previous{
                        @media only screen and (max-width: 575px){
                            margin-top: 10px;
                        }
                        a{
                            text-align: ${({ theme }) => (theme.rtl ? 'left' : 'right')};
                            @media only screen and (max-width: 575px){
                                text-align: ${({ theme }) => (theme.rtl ? 'right' : 'left')};
                            }
                            span.fa{
                                margin-left: 8px;
                                ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 8px;
                            }
                        }
                        .pagintaion-label{
                            justify-content: flex-end;
                            @media only screen and (max-width: 575px){
                                justify-content: flex-start;
                            }
                        }
                    }
                    a{
                        .pagintaion-label{
                            display: flex;
                            align-items: center;
                            margin-bottom: 6px;
                            span.fa{
                                display: inline-block;
                                font-size: 14px;
                            }
                            span{
                                font-size: 13px;
                                font-weight: 400;
                                color: ${({ theme }) => theme['light-color']};
                            }
                        }
                        .knowledge-details-title{
                            font-size: 14px;
                        }
                    }
                }
            }
            .knowledge-sidebar-trigger{
                background-color: ${({ theme }) => theme['primary-color']};
            }
        }
    }
    .related-article-list{
        padding: 24px 30px 20px;
        border: 1px solid ${({ theme }) => theme['border-color-normal']};
        margin-bottom: 30px;
        border-radius: 5px;
        .related-article-list__title{
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 18px;
            color: ${({ theme }) => theme['dark-color']};
        }
        .related-article{
            li{
                &:not(:last-child){
                    margin-bottom: 10px;
                }
                .related-article__single{
                    display: flex;
                    .article-icon{
                        margin-right: 12px;
                        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 12px;
                        i,
                        span,
                        svg{
                            color: #CCD4DE;
                        }
                    }
                    .related-article__title{
                        font-size: 15px;
                        color: ${({ theme }) => theme['gray-color']};
                    }
                }
                a{
                    font-size: 15px;
                    color: ${({ theme }) => theme['gray-color']};
                }
            }
        }
    }
    .sDash_comment-form{
        border-radius: 5px;
        padding: 20px 30px 30px;
        border: 1px solid ${({ theme }) => theme['border-color-normal']};
        .sDash_comment-form__title{
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
            color: ${({ theme }) => theme['dark-color']}; 
        }
        .ant-form-item{
            .ant-form-item-label{
                padding: 0 0 10px;
                label{
                    font-size: 15px;
                    color: ${({ theme }) => theme['gray-color']}; 
                }
            }
            textarea{
                resize: none;
                border-radius: 4px;
                min-height: 150px;
            }
        }
        .btn-submit{
            font-size: 14px;
            height: 46px;
            padding: 0px 29.95px;
            font-weight: 500;
        }
    }
`;
export {
    Div,
    SmallScreenAuthInfo,
    SmallScreenSearch,
    ModeSwitch,
    TopMenuStyle,
    TopMenuSearch,
    KBArticales,
    KnowledgeDetailsWrap,
    SidebarNavWrap
};
