import { Col, Row, Skeleton, Table, Tag } from "antd";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { SearchTable } from "../../container/styled";
import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { themeLoading } from "../../redux/themeLayout/actionCreator";
import { bindActionCreators } from "redux";
import { useState } from "react";
import { TriggerGetOrders } from "./api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const OrderResult = (props) => {

    const history = useHistory();
    const [order, setOrders] = useState({
        initialized: false,
        totalRows: 0,
        data: [],
    });
    const [isVisible, setIsVisible] = useState({ initialized: true, visible: true });
    const [showSkeleton, setShowSkeleton] = useState(true);

    useEffect(() => {
        //window.addEventListener('scroll', handleScroll);
    });


    function handleScroll() {
        if (props.orderRef?.current?.offsetTop + document.documentElement.scrollTop >= document.documentElement.offsetHeight) {
            if (!isVisible.initialized) {
                setIsVisible({ initialized: true, visible: true });
                setShowSkeleton(false);
            }
        }
    }


    const { instance, accounts, inProgress } = useMsal();

    const dispatch = useDispatch();
    const themeLoadingToggle = bindActionCreators(themeLoading, dispatch);

    const landingColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
    ]

    useEffect(() => {
        TriggerGetOrders(
            setOrders,
            accounts[0] || {},
            inProgress,
            instance,
            props.params,
            themeLoadingToggle,
        );
    }, [props.params]);

    useEffect(() => {
        if(order.initialized){
            setShowSkeleton(false)
        }
    })

    return (
        <>
            <Skeleton active loading={showSkeleton}>
                    <SearchTable>
                        <p><b>Orders</b></p>
                        <Row style={{ marginBottom: '15px' }}>
                            <Col span={24}>
                                <div className="table-bordered table-responsive">
                                    <Table
                                        columns={landingColumns}
                                        pagination={false}
                                        dataSource={order.data}
                                        rowKey='id'
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => { history.push('/orders/orderDetail/' + record.id) },
                                            };
                                        }} />
                                </div>
                            </Col>
                        </Row>
                        <NavLink to={'/orders'}>Show more</NavLink>
                    </SearchTable>
            </Skeleton>
        </>
    )
}

export default OrderResult;