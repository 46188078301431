import axios from 'axios';
import { getItem } from '../../utility/localStorageControl';
import { protectedResources } from '../auth/authConfig';
import { InteractionRequiredAuthError, BrowserAuthError } from '@azure/msal-browser';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const authHeader = () => ({
  Authorization: `Bearer ${getItem('access_token')}`,
});

const authHeader2 = access_token => ({
  Authorization: `Bearer ${access_token}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getItem('access_token')}`,
    'Content-Type': 'application/json',
  },
});

class DataService {
  static get(path = '') {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    });
  }

  static getWithToken(path = '', token = '') {
    return axios.get(path, {
      baseURL: API_ENDPOINT,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  static async getWithTokenSilent(path = '', instance, account) {
    try {
      window.scrollTo(0, 0);
      let response = await instance.acquireTokenSilent({
        scopes: protectedResources.loginRequest.scopes,
        account: account,
      });
      return this.getWithToken(path, response.idToken);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
        await instance.logoutRedirect();
      }
    }
  }

  static postWithToken(path = '', data = {}, token = '') {
    return axios
      .post(path, data, {
        baseURL: API_ENDPOINT,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch(error => {
        return error.response;
      });
  }
  static async postWithTokenSilent(path = '', data = {}, instance, account) {
    try {
      let response = await instance.acquireTokenSilent({
        scopes: protectedResources.loginRequest.scopes,
        account: account,
      });
      return this.postWithToken(path, data, response.idToken);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
        await instance.logoutRedirect();
      }
    }
  }
  static post(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }
  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(config => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = { ...headers, Authorization: `Bearer ${getItem('access_token')}` };

  return requestConfig;
});

client.interceptors.response.use(
  response => response,
  error => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;
    if (response) {
      if (response.status === 500) {
        // do something here
      } else {
        return originalRequest;
      }
    }
    return Promise.reject(error);
  },
);
export { DataService };
