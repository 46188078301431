export default function SalesRepValues() {
  const salesRepRoles = ['Sales Representative Training Manager', 'Sales Representative Administrator'];
  const adminRoles = ['Sales Representative Training Manager', 'Internal', 'Sales Representative Administrator'];
  const trainingManagerOnly = ['Sales Representative Training Manager'];
  const assessmentTypes = {
    workSheet: { name: 'Formative Assessment - Worksheet', id: 'e49b28bc-7619-ee11-8f6d-0022489b4650' },
    classProject: { name: 'Formative Assessment - Class Project', id: '3000e4c9-7619-ee11-8f6d-0022489ae632' },
    summative: { name: 'Summative Assessment', id: '8912afe2-7619-ee11-8f6d-0022489ae632' },
  };
  const questionTypesIds = {
    paragraphQuestion: '4a728b05-3717-ee11-8f6d-0022489ae632',
    multipleChoiceQuestion: 'f6ffb60e-3717-ee11-8f6d-0022489b4650',
  };
  const salesRepUserRole = ['Sales Representative'];

  const assessmentTypesIdList = [
    'e49b28bc-7619-ee11-8f6d-0022489b4650',
    '3000e4c9-7619-ee11-8f6d-0022489ae632',
    '8912afe2-7619-ee11-8f6d-0022489ae632',
  ];

  return {
    salesRepRoles,
    adminRoles,
    trainingManagerOnly,
    assessmentTypes,
    questionTypesIds,
    salesRepUserRole,
    assessmentTypesIdList,
  };
}
