import { Link } from 'react-router-dom';
import React from 'react';
import { Image, Descriptions } from 'antd';
import { currency } from "../../../components/utilities/utilities";

export const OrderDetailsTableColumn = [
  {
    title: '#',
    dataIndex: 'number',
    key: 'number',
    responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
    render: (text, order) => <>{order.number}</>,
  },
  {
    title: 'Product image',
    dataIndex: 'productImage',
    key: 'productImage',
    responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
    render: (text, order) => (
      <div style={{ width: 100 }}>
        {/* <Image src="https://media.istockphoto.com/photos/3d-rendering-of-antibiotic-pills-in-blister-pack-picture-id635948926?k=20&m=635948926&s=612x612&w=0&h=p0hina6VoA3hfQPedGzMsJkJYHzbnU2EHQpODVP3MN0=" /> */}
      </div>
    ),
  },
  {
    title: 'Product Details',
    dataIndex: 'productDescription',
    key: 'productDescription',
    sorter: true,
    responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
    render: (text, order) => (
      <Descriptions.Item>
        <div>
          <b> {order.productName}</b>
          <br />
          <Descriptions.Item>Qty: {order.quantity}</Descriptions.Item>
          <br />

          <Descriptions.Item>{currency(order.pricePerUnit,"R")}</Descriptions.Item>
        </div>
      </Descriptions.Item>
    ),
  },
  {
    title: 'Price Per Unit',
    dataIndex: 'pricePerUnit',
    key: 'pricePerUnit',
    sorter: true,
    responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
    render: (text, order) => <>{currency(order.pricePerUnit,"R")}</>,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    sorter: true,
    responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
  },
  {
    title: 'Order Total',
    dataIndex: 'orderTotal',
    key: 'orderTotal',
    sorter: true,
    responsive: ['sm', 'md', 'lg', 'xl', 'xxl'],
    render: (text, order) => <>{currency(order.pricePerUnit * order.quantity,"R")}</>,
  },
  {
    title: 'Order Detail',
    responsive: ['xs'],
    render: (text, order) => (
      <>
        <Descriptions colon={false} layout="Horizontal" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
          <Descriptions.Item>{order.productImage}</Descriptions.Item>
          <Descriptions.Item>{order.productName}</Descriptions.Item>
          <Descriptions.Item>{currency(order.pricePerUnit,"R")}/u</Descriptions.Item>
          <Descriptions.Item>Qty {order.quantity}</Descriptions.Item>
          <Descriptions.Item>{currency(order.pricePerUnit * order.quantity,"R")}</Descriptions.Item>
        </Descriptions>
      </>
    ),
  },
];
