import { protectedResources } from '../../config/auth/authConfig';
import { DataService } from '../../config/dataService/dataService';

export function TriggerGetRoles(populateRoles, dispatch, instance, account, themeLoadingToggle) {
  getApiRoles(populateRoles, dispatch, instance, account, themeLoadingToggle, protectedResources.roles.endpoint);
}

export function TriggerGetAccountInformation(populateProfileInfo, dispatch, instance, account, themeLoadingToggle) {
  getApiAccount(
    populateProfileInfo,
    dispatch,
    instance,
    account,
    themeLoadingToggle,
    protectedResources.account.endpoint,
  );
}

function getApiRoles(populateRoles, dispatch, instance, account, themeLoadingToggle, endpoint) {
  themeLoadingToggle(true);
  DataService.getWithTokenSilent(endpoint, instance, account)
    .then(resp => resp.data)
    .then(resp => {
      if (resp != undefined) {
        if (resp.succeeded) {
          dispatch(
            populateRoles({
              initialized: true,
              portalRole: resp.data.portalRole.length > 0 ? resp.data.portalRole[0].name : '',
              roles: resp.data.portalRole.length > 0 ? resp.data.portalRole[0].roles : [],
            }),
          );
        }
      }
    })
    .finally(() => {
      themeLoadingToggle(false);
    })
    .catch(() => {
      themeLoadingToggle(false);
    });
}

function getApiAccount(populateProfileInfo, dispatch, instance, account, themeLoadingToggle, endpoint) {
  themeLoadingToggle(true);
  DataService.getWithTokenSilent(endpoint, instance, account)
    .then(resp => resp.data)
    .then(resp => {
      if (resp != undefined) {
        if (resp.succeeded) {
          const profileInfo = resp.data;
          profileInfo.initialized = true;
          dispatch(populateProfileInfo(profileInfo));
        }
      }
    })
    .finally(() => {
      themeLoadingToggle(false);
    })
    .catch(() => {
      themeLoadingToggle(false);
    });
}
