import { Col, Row, Skeleton, Table } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import { SearchTable } from "../../container/styled";
import { useState } from "react";
import { useEffect } from "react";
import { TriggerGetCampines } from "./api";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { themeLoading } from "../../redux/themeLayout/actionCreator";
import { bindActionCreators } from "redux";


const CampinResult = (params) => {

    const landingColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
    ];

    const [campaign, setCampines] = useState({
        initialized: false,
        totalRows: 0,
        data: [],
    });
    const [isVisible, setIsVisible] = useState({ initialized: true, visible: true });
    const [showSkeleton, setShowSkeleton] = useState(true);

    const { instance, accounts, inProgress } = useMsal();

    const dispatch = useDispatch();
    const themeLoadingToggle = bindActionCreators(themeLoading, dispatch);


    useEffect(() => {
        TriggerGetCampines(
            setCampines,
            accounts[0] || {},
            inProgress,
            instance,
            params.params,
            themeLoadingToggle,
        );
    }, [params.params]);

    useEffect(() => {
        if (campaign.initialized) {
            setShowSkeleton(false)
        }
    })

    useEffect(() => {
        // window.addEventListener('scroll', handleScroll);
    });

    function handleScroll() {
        if (params.campinRef?.current?.offsetTop + document.documentElement.scrollTop >= document.documentElement.offsetHeight) {
            setIsVisible({ initialized: true, visible: true });
            setShowSkeleton(false);

        }
    }

    return (
        <>
            <Skeleton active loading={showSkeleton}>
                <SearchTable>
                    <p><b>Rewards</b></p>
                    <Row style={{ marginBottom: '15px' }}>
                        <Col span={24}>
                            <div className="table-bordered table-responsive">
                                <Table
                                    columns={landingColumns}
                                    pagination={false}
                                    dataSource={campaign.data}
                                    rowKey='id' />
                            </div>
                        </Col>
                    </Row>
                    <NavLink to={'/learn/campaigns'}>Show more</NavLink>
                </SearchTable>
            </Skeleton>
        </>
    )
}

export default CampinResult;