const actions = {
    Set_Portfolios: 'Set_Portfolios',
  
    setPortfolios: portfolios => {
      return {
        type: actions.Set_Portfolios,
        payload: portfolios,
      };
    },
  };
  export default actions;