import React, { useState } from 'react';
import { Row, Col, Button, Modal } from 'antd';

const TermsConditionModal = params => {
  const handleCancel = () => {
    params.setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="MYCIPLA WEBSITE TERMS AND CONDITIONS"
        visible={params.visible}
        className="modal"
        onCancel={handleCancel}
        width={window.innerWidth < 767 ? '100vw' : '60vw'}
        bodyStyle={{ height: '60vh', overflow: 'auto' }}
        footer={[
            <Button type="primary" visible={params.btnShow} onClick={params.acceptTC}>
            I Accept Terms & Conditions
          </Button>
          ]}
      >
        <ol>
          <li>
            <h3>INTRODUCTION</h3>
            <ol>
              <li>
                This My Cipla website can be accessed at www.mycipla.cipla.com (the “Website”) and provides information
                in respect of certain Cipla products, events and educational training.
              </li>
              <li>
                {' '}
                The Website is owned and operated by Cipla Medpro South Africa (Pty) Limited (“Cipla”, “we”, “us” and
                “our”), a company registered in Republic of South Africa, with registration number 2002/018027/07, with
                its principal place of business at Building 9, Parc du Cap Office Park, Mispel Street, Bellville, Cape
                Town, 7530.
              </li>
              <li>The Website terms and conditions (“Terms and Conditions”) govern the use of the Website.</li>
              <li>
                These Terms and Conditions are binding and enforceable against every person that accesses or uses this
                Website (hereinafter referred to as “you” or “your” or “user”). By using the Website, you acknowledge
                that you have read and agree to be bound by these Terms and Conditions and the Privacy Policy which
                shall be deemed incorporated as part of this Website Terms and Conditions. Please do not use the Website
                if you do not agree to be bound by these Terms and Conditions.
              </li>
              <li>
                Any questions that you may have regarding these Terms and Conditions and/or our Website, should be
                directed to my.cipla@cipla.com
              </li>
            </ol>
          </li>
          <li>
          <h3>WEBSITE PURPOSE AND USE</h3>
            <ol>
              <li>
                This Website contains Cipla product information relating to scheduled medicines and such information is
                provided exclusively for healthcare professionals who are registered with the relevant regulatory
                authority. By accessing such information, you warrant that you are appropriately registered with the
                relevant regulatory bodies and that you are a qualified healthcare professional in accordance with
                applicable law. intellectual property, whether registered or unregistered, contained therein, are owned
                by or licensed to Cipla, and shall remain the sole property of Cipla. The Content may not, in whole or
                in part, be used for any purpose without the prior written permission of Cipla
              </li>
              <li>
                The Content is provided to you AS IS for your information and may not be used, copied, reproduced,
                distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any purpose
                without the prior written consent of Cipla or as provided for herein.
              </li>
            </ol>
          </li>
          <li>
          <h3>TERMINATION OF USE</h3>
            <ol>
              <li>
                Cipla shall have the right to immediately disable your My Cipla account and terminate your access to the
                Website at any time in its discretion, without notice to you and with no liability to you or any third
                party.
              </li>
            </ol>
          </li>
          <li>
          <h3>INDEMNITY AND LIMITATION OF LIABILITY</h3>
            <ol>
              <li>
                You agree to defend, indemnify and hold harmless Cipla, its affiliates and their respective directors,
                officers, employees and third party partners from and against all claims, damages, losses, liabilities,
                costs and expenses (including attorney’s fees) arising from:
                <ol>
                  <li>your use of and access to the Website and/or any linked third party website; and</li>
                  <li>your violation of the Terms and Conditions.</li>
                </ol>
              </li>
              <li>
                {' '}
                To the extent permitted by applicable law, Cipla shall not be liable to you for any losses, damages,
                claims, liabilities, costs and expenses, howsoever arising.
              </li>
            </ol>
          </li>
        </ol>

        {/* {params.btnShow && (
          <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
            <Button type="primary" onClick={params.acceptTC}>
              I Accept Terms & Conditions
            </Button>
          </Row>
        )} */}
      </Modal>
    </>
  );
};

export default TermsConditionModal;
