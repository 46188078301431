import actions from "./actions";

const { POPULATE_PROFILELOOKUPINFORMATION } = actions;

const initialState = {
    initialized: false
}

const profileLookUpInformationReducer = (state = initialState, actions) => {
    const { type, payload } = actions;
    switch (type) {
        case POPULATE_PROFILELOOKUPINFORMATION:
            return payload;
        default:
            return state;
    }
}

export {profileLookUpInformationReducer};