import { protectedResources } from "../../config/auth/authConfig";
import { DataService } from "../../config/dataService/dataService";


function getApi(setData, setSkeletonState) {
    DataService.get(protectedResources.getLandingPage.endpoint)
    .then(resp => resp.data)
    .then(resp => {
      if (resp != undefined) {
        if (resp.succeeded) {
          setData({
            initialized: true,
            data: resp.data,
          });
          setSkeletonState({ loading: false })
        }
      }
    })
    .finally(() => {
    })
    .catch(() => {
    });
}
export function TriggerGetLandingPage(setData, setSkeletonState) {
    getApi(setData, setSkeletonState ,protectedResources.getLandingPage.endpoint)
}
