export const PermissionConfigs = [
  { component: 'Orders', permissions: ['Orders'] },
  { component: 'Product', permissions: ['Products'] },
  { component: 'Learning', permissions: ['Learning'] },
  { component: 'MobiMiMs', permissions: ['Mobimims'] },
  { component: 'Rewards', permissions: ['Learning'] },
  { component: 'Account', permissions: ['account'] },
  { component: 'Connect', permissions: ['Support', 'Knowledgebase'] },
  { component: 'Support', permissions: ['Support'] },
  { component: 'Knowledge', permissions: ['Knowledgebase'] },
  { component: 'Ticket', permissions: ['Support'] },
  { component: 'Reports', permissions: ['Reports'] },
  { component: 'ProductsOTP', permissions: ['ProductsOTP'] },
  { component: 'ProductsRx', permissions: ['ProductsRx'] },
];
