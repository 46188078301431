const actions = {
    POPULATE_PROFILEINFORMATION: 'POPULATE_PROFILEINFORMATION',
    populateProfileInformation: account => {
        return {
            type: actions.POPULATE_PROFILEINFORMATION,
            payload: account
        }
    }
}

export default actions;