import { useMsal } from "@azure/msal-react";
import { Col, Row, Skeleton, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { SearchTable } from "../../container/styled";
import { themeLoading } from "../../redux/themeLayout/actionCreator";
import { TriggerGetCourses } from "./api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CourseResult = (props) => {


    const history = useHistory();
    const [course, setCouses] = useState({
        initialized: false,
        totalRows: 0,
        data: [],
    });
    const [isVisible, setIsVisible] = useState({ initialized: true, visible: true });
    const [showSkeleton, setShowSkeleton] = useState(true);

    const landingColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            
        }
    ];
    const { instance, accounts, inProgress } = useMsal();

    const dispatch = useDispatch();
    const themeLoadingToggle = bindActionCreators(themeLoading, dispatch);


    useEffect(() => {
        TriggerGetCourses(
            setCouses,
            accounts[0] || {},
            inProgress,
            instance,
            props.params,
            themeLoadingToggle,
        );
    }, [props.params]);

    useEffect(() => {
        if (course.initialized) {
            setShowSkeleton(false)
        }
    })


    return (
        <>
            <Skeleton active loading={showSkeleton}>
                <SearchTable>
                    <p><b>Courses</b></p>
                    <Row style={{ marginBottom: '15px' }}>
                        <Col span={24}>
                            <div className="table-bordered table-responsive">
                                <Table columns={landingColumns}
                                    pagination={false}
                                    dataSource={course.data}
                                    rowKey='id'
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => { history.push('/learn/courses/course/' + record.id) },
                                        };
                                    }} />
                            </div>
                        </Col>
                    </Row>
                    <NavLink to={'/learn/courses'}>Show more</NavLink>
                </SearchTable>
            </Skeleton>
        </>
    )
}

export default CourseResult;