import actions from './actions';

const { POPULATE_ROLES } = actions;

const initialState = {
  initialized: false,
  portalRole: "",
  roles: [""]
};

const roleReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case POPULATE_ROLES:
      return {
        initialized: payload.initialized,
        portalRole: payload.portalRole,
        roles: payload.roles
      };
    default:
      return state;
  }
};

export { roleReducer };
