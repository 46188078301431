import actions from './actions';

const { Set_All_Notifications } = actions;

const initialState = {
  initialized: false,
  notifications: [],
};

const salesRepNotificationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Set_All_Notifications:
      return {
        initialized: payload.initialized,
        portalRole: payload.notifications,
      };
    default:
      return state;
  }


};

export { salesRepNotificationReducer };