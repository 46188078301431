import React, { useState, useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { OrderSummary } from '../Style';
import { Cards } from '../../../components/cards/frame/cards-frame';
import Heading from '../../../components/heading/heading';
import { Button } from '../../../components/buttons/buttons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Ordersummary = ({ itemCount, total, dnaPointsBalance }) => {
  const history = useHistory();
  return (
    <Cards
      bodyStyle={{
        backgroundColor: '#F8F9FB',
        borderRadius: '20px',
      }}
      headless
    >
      <OrderSummary>
        <Heading className="summary-table-title" as="h4">
          Order Summary
        </Heading>
        <Cards
          bodyStyle={{
            backgroundColor: '#ffffff',
            borderRadius: '20px',
          }}
          headless
        >
          <div className="order-summary-inner">
            <Heading className="summary-total" as="h4">
              <span className="summary-total-label">Total Points: ({itemCount} items) </span>
              <span className="summary-total-amount">{`${total.toLocaleString()}`}</span>
            </Heading>
            <Heading className="summary-total" as="h4">
              <span className="summary-total-label">Your Balance:</span>
              <span className="summary-total-amount">{dnaPointsBalance}</span>
            </Heading>
            <Button
              className="btn-proceed"
              type="secondary"
              size="large"
              onClick={() => history.push('/checkout')}
              disabled={itemCount === 0}
            >
              <Link to="#">Checkout</Link>
            </Button>
          </div>
        </Cards>
      </OrderSummary>
    </Cards>
  );
};

export default Ordersummary;
