import { TriggerNotificationsGetAllUnread } from '../../container/dnaacademy/api';
import actions from './actions';

const { Set_All_Unread_Notifications } = actions;

const initialState = {
  initialized: false,
  notifications: [],
};

const salesRepUnreadNotificationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Set_All_Unread_Notifications:
      return {
        initialized: payload.initialized,
        data: payload.data,
      };
    default:
      return state;
  }
};

export { salesRepUnreadNotificationReducer };