import React, { useState } from 'react';
import { Avatar, Row, Col } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Message from './message';
import Notification from './notification';
import Settings from './settings';
import CartIcon from './cartIcon';
import Support from './support';
import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import { useMsal } from '@azure/msal-react';
import UserDetails from './userDetails';
import { UserOutlined } from '@ant-design/icons';
import NotificationBox from './notification';
import SalesRepValues from '../../../salesRepValues';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const AuthInfo = () => {
  const profileInformation = useSelector(state => state.profileInformation);
  const permissions = useSelector(state => state.permissions);

  const { path } = useRouteMatch();

  const dispatch = useDispatch();
  const { instance } = useMsal();
  const [state, setState] = useState({
    flag: 'english',
  });
  const { flag } = state;

  const SignOut = e => {
    e.preventDefault();
    dispatch(logOut());
    instance.logoutRedirect().catch(e => {
      console.error(e);
    });
  };


  const onFlagChangeHandle = value => {
    setState({
      ...state,
      flag: value,
    });
  };

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle('english')} to="#">
        <img src={require('../../../static/img/flag/english.png')} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('germany')} to="#">
        <img src={require('../../../static/img/flag/germany.png')} alt="" />
        <span>Germany</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('spain')} to="#">
        <img src={require('../../../static/img/flag/spain.png')} alt="" />
        <span>Spain</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('turky')} to="#">
        <img src={require('../../../static/img/flag/turky.png')} alt="" />
        <span>Turky</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Message />
      <Notification />

      <Settings />
      <Support />
      <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}
      {SalesRepValues().salesRepRoles.includes(permissions.portalRole) || SalesRepValues().salesRepUserRole.includes(permissions.portalRole) ?
      <NotificationBox/> : <></>}
      <CartIcon />

      {window.innerWidth < 800 ? (
        <Link to={'/profile'} className="head-example">
          {profileInformation.initialized && profileInformation.person.profileImageUrl ? (
            <Avatar src={API_ENDPOINT + '/Storage/v1/storage/get-image' + profileInformation.person.profileImageUrl} />
          ) : (
            <Avatar icon={<UserOutlined />} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
          )}        </Link>
      ) : (
        <div className="cart">
          <Popover placement="bottomRight" content={<UserDetails isPopover={true} />} action="hover">
            <Link to="#" className="head-example">
              {profileInformation.initialized && profileInformation.person.profileImageUrl ? (
                <Avatar src={API_ENDPOINT + '/Storage/v1/storage/get-image' + profileInformation.person.profileImageUrl} />
              ) : (
                <Avatar icon={<UserOutlined />} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
              )}
            </Link>
          </Popover>
        </div>

      )}
    </InfoWraper>
  );
};

export default AuthInfo;
