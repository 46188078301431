import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Main } from "../../container/styled";
import { PageHeader } from "../page-headers/page-headers";
import CourseResult from "./CourseResult";
import OrderResult from "./OrderResult";
import ProductResult from "./ProductResult";
import { useRef } from "react";
import CampinResult from "./Campaign"
import TicketResult from "./TicketResult";
import QuizResult from "./QuizResult";



const SearchResult = () => {
    window.scroll(0, 0);
 
    let currentUrlParams = new URLSearchParams(window.location.search);
    let searchParams = currentUrlParams.get('filter');
    const elementRef = useRef(null);
    const productRef = useRef(null);
    const campinRef = useRef(null);
    const orderRef = useRef(null);
    const ticketRef = useRef(null);
    const quizRef = useRef(null);




    return (
        <>
            <PageHeader title='Search results' />
            <Main>
                <Row ref={elementRef}>
                    <Col span={24}>
                        <CourseResult params={searchParams} />
                    </Col>
                </Row>

                <Row style={{ marginTop: '30px', marginBottom: '20px' }} ref={productRef}>
                    <Col span={24}>
                        <ProductResult params={searchParams} productRef={productRef} />
                    </Col>
                </Row>


                <Row style={{ marginTop: '30px', marginBottom: '20px' }} ref={campinRef}>
                    <Col span={24}>
                        <CampinResult params={searchParams} campinRef={campinRef}/>
                    </Col>
                </Row>


                <Row style={{ marginTop: '30px', marginBottom: '20px' }} ref={orderRef}>
                    <Col span={24}>
                        <OrderResult params={searchParams} orderRef={orderRef}/>
                    </Col>
                </Row>

                <Row style={{ marginTop: '30px', marginBottom: '20px' }} ref={orderRef}>
                    <Col span={24}>
                        <TicketResult params={searchParams} ticketRef={ticketRef}/>
                    </Col>
                </Row>

                <Row style={{ marginTop: '30px', marginBottom: '20px' }} ref={quizRef}>
                    <Col span={24}>
                        <QuizResult params={searchParams} ticketRef={quizRef}/>
                    </Col>
                </Row>


            </Main>
        </>
    )
}

export default SearchResult;