import actions from './actions';

const { POPULATE_CLIENTLIST } = actions;

const initialState = {
  initialized: false,
  clients:[{ id: '', name: 'All clients' }]
};

const clientListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case POPULATE_CLIENTLIST:
      return {
        initialized: payload.initialized,
        clients: [...initialState.clients, ...payload.clients]
      };
    default:
      return state;
  }
};

export { clientListReducer };
