import { protectedResources } from '../../../config/auth/authConfig';
import { DataService } from '../../../config/dataService/dataService';

export function TriggerCoursesGet(setCourses, instance, account, themeLoadingToggle) {
  getApi(setCourses, instance, account, themeLoadingToggle, protectedResources.courses.endpoint);
}
export function TriggerAdminCoursesGet(setCourses, instance, account, themeLoadingToggle) {
  const finalEndpoint = protectedResources.coursesAdmin.endpoint + '?pagenumber=1&pagesize=50';
  getApi(setCourses, instance, account, themeLoadingToggle, finalEndpoint);
}
export function TriggerSimilarCoursesGet(setCourses, instance, account, themeLoadingToggle, search) {
  const finalEndpoint = protectedResources.similarCourses.endpoint + (search !== undefined ? '?filter=' + search : '');

  getApi(setCourses, instance, account, themeLoadingToggle, finalEndpoint);
}
export function TriggerRecommendedProductsGet(setProducts, instance, account, themeLoadingToggle, id) {
  const finalEndpoint = protectedResources.recommendedProducts.endpoint + '?id=' + id;

  getApi(setProducts, instance, account, themeLoadingToggle, finalEndpoint);
}
export function TriggerCoursesSearchGet(setCourses, instance, account, themeLoadingToggle, search) {
  const finalEndpoint = protectedResources.courses.endpoint + (search !== undefined ? '?filter=' + search : '');

  getApi(setCourses, instance, account, themeLoadingToggle, finalEndpoint);
}

export function TriggerAdminComponentGet(
  setComponent,
  account,
  instance,
  componentRequest,
  themeLoadingToggle,
  component,
) {
  themeLoadingToggle(true);

  let endpoint = '';

  switch (component) {
    case 'Course':
      endpoint = protectedResources.coursesAdmin.endpoint;
      break;
    case 'Assessment':
      endpoint = protectedResources.assessmentsAdmin.endpoint;
      break;
    case 'Lesson':
      endpoint = protectedResources.lessonsAdmin.endpoint;
      break;
    case 'Therapeutic category':
      endpoint = protectedResources.pharmaceuticalCategory.endpoint;
      break;
    case 'Product':
      endpoint = protectedResources.getProductList.endpoint;
      break;
    case 'Reward':
      endpoint = protectedResources.getRewardList.endpoint;
      break;
    case 'Quiz':
      endpoint = protectedResources.getQuizList.endpoint;
      break;
  }
  const finalEndpoint =
    endpoint +
    '?pageNumber=' +
    componentRequest.pageNumber.toString() +
    '&pagesize=' +
    componentRequest.pageSize.toString() +
    (componentRequest.filter != undefined ? '&filter=' + componentRequest.filter : '') +
    (componentRequest.sortBy != undefined ? '&sortBy=' + componentRequest.sortBy : '') +
    (componentRequest.sortOrder != undefined ? '&sortOrder=' + componentRequest.sortOrder : '');
 
  DataService.getWithTokenSilent(finalEndpoint, instance, account)
    .then(resp => resp.data)
    .then(resp => {
      if (resp != undefined) {
        if (resp.succeeded) {
          setComponent({
            totalRows: resp.totalRecordCount,
            data: resp.data,
          });
          themeLoadingToggle(false);
        }
      }
    });
}

export async function TriggerCourseDetailGet(setCourseDetails, instance, account, themeLoadingToggle, courseId) {
  const finalEndpoint = protectedResources.courseDetail.endpoint + '?Id=' + courseId;

  await getApi(setCourseDetails, instance, account, themeLoadingToggle, finalEndpoint);
}
export async function TriggerCourseTagsGet(setTags, instance, account, themeLoadingToggle) {
  getApi(setTags, instance, account, themeLoadingToggle, protectedResources.courseTags.endpoint);
}

export async function TriggerPortfoliosGet(setPortfolios, instance, account, themeLoadingToggle) {
  const finalEndPoint = protectedResources.portfolios.endpoint + '?pagenumber=1&pagesize=50&SortBy=name&SortOrder=desc';
  getApi(setPortfolios, instance, account, themeLoadingToggle, finalEndPoint);
}

export async function TriggerPortfolioOfEvidenceGet(setPortfolioOfEvidence, instance, account, themeLoadingToggle) {
  const finalEndPoint = protectedResources.portfolioOfEvidence.endpoint;
  getApi(setPortfolioOfEvidence, instance, account, themeLoadingToggle, finalEndPoint);
}

export async function TriggerGetSalesRepPortfolioOfEvidence(setSalesRepPOE, instance, account, themeLoadingToggle, id) {
  const finalEndPoint = protectedResources.salesRepPOE.endpoint + '?Id=' + id;
  getApi(setSalesRepPOE, instance, account, themeLoadingToggle, finalEndPoint);
}
export async function TriggerGetSalesRepUserPortfolioOfEvidence(setSalesRepPOE, instance, account, themeLoadingToggle, id) {
  const finalEndPoint = protectedResources.salesRepUserPOE.endpoint;
  getApi(setSalesRepPOE, instance, account, themeLoadingToggle, finalEndPoint);
}

export async function TriggerNotificationsGetAllHistory(setNotifications, instance, account, themeLoadingToggle) {
  const finalEndPoint =
    protectedResources.getNotificationsGetAllHistory.endpoint +
    '?pagenumber=1&pagesize=50&SortBy=createdon&SortOrder=desc';
  getApi(setNotifications, instance, account, themeLoadingToggle, finalEndPoint);
}

export async function TriggerNotificationsGetAllUnread(setUnreadNotifications, instance, account, themeLoadingToggle) {
  const finalEndPoint =
    protectedResources.getNotificationsGetAllUnread.endpoint +
    '?pagenumber=1&pagesize=50&SortBy=createdon&SortOrder=desc';
  getApi(setUnreadNotifications, instance, account, themeLoadingToggle, finalEndPoint);
}

export async function TriggerScheduledAssessmenstGet(setAssessments, instance, account, themeLoadingToggle) {
  const finalEndpoint =
    protectedResources.scheduledAssessmentsGet.endpoint + '?pagenumber=1&pagesize=5&SortBy=createdon&SortOrder=desc';

  await getApi(setAssessments, instance, account, themeLoadingToggle, finalEndpoint);
}

export async function TriggerLearnersResultsGet(
  setLearnerResults,
  instance,
  account,
  themeLoadingToggle,
  courseId,
  scheduleId,
  assessmentId,
) {
  const finalEndpoint =
    protectedResources.learnerResultsGet.endpoint +
    `?pagenumber=1&pagesize=5&SortBy=name&SortOrder=asc&ScheduledEventId=${scheduleId}&LearnCourseId=${courseId}&LearnAssessmentId=${assessmentId}`;

  await getApi(setLearnerResults, instance, account, themeLoadingToggle, finalEndpoint);
}

export async function TriggerReleaseResultsPost(setSubmitSaveResponse, request, instance, account, themeLoadingToggle){
  const finalEndpoint = protectedResources.releaseResults.endpoint; 

  await postApi(setSubmitSaveResponse, request, instance, account, themeLoadingToggle, finalEndpoint);
}

export function TriggerNotificationReadStatusPost(setNotificationReadStatus, notificationRequest, instance, account) {
  const finalEndpoint = protectedResources.notificationReadStatusPost.endpoint;

  postApiSilent(setNotificationReadStatus, notificationRequest, instance, account, finalEndpoint);
}

export function TriggerNotificationUnReadStatusPost(setNotificationReadStatus, notificationRequest, instance, account) {
  const finalEndpoint = protectedResources.notificationUnReadStatusPost.endpoint;

  postApiSilent(setNotificationReadStatus, notificationRequest, instance, account, finalEndpoint);
}

export function TriggerNotificationDeletePost(setNotificationReadStatus, notificationRequest, instance, account) {
  const finalEndpoint = protectedResources.notificationDeletePost.endpoint;

  postApiSilent(setNotificationReadStatus, notificationRequest, instance, account, finalEndpoint);
}
export async function TriggerLearnersAssessmentGet(
  setAssessment,
  instance,
  account,
  themeLoadingToggle,
  learnContactAssessmentId,
) {
  const finalEndpoint =
    protectedResources.learnerAssessmentGet.endpoint + `?LearnContactAssessmentId=${learnContactAssessmentId}`;

  await getApi(setAssessment, instance, account, themeLoadingToggle, finalEndpoint);
}

export async function TriggerLearnersAssessmentReportGet(
  setAssessment,
  instance,
  account,
  themeLoadingToggle,
  learnContactAssessmentId,
) {
  const finalEndpoint =
    protectedResources.learnerAssessmentReportGet.endpoint + `?LearnContactAssessmentId=${learnContactAssessmentId}`;

  await getApi(setAssessment, instance, account, themeLoadingToggle, finalEndpoint);
}

export async function TriggerLearnersAssessmentGradingPost(
  setAssessmentGrading,
  instance,
  account,
  themeLoadingToggle,
  request,
) {
  const finalEndpoint = protectedResources.learnerAssessmentGradingPost.endpoint;

  await postApi(setAssessmentGrading, request, instance, account, themeLoadingToggle, finalEndpoint);
}
export function TriggerAdminCourseDetailGet(setCourseDetails, instance, account, themeLoadingToggle, courseId) {
  const finalEndpoint = protectedResources.courseAdminDetail.endpoint + '?Id=' + courseId;

  getApi(setCourseDetails, instance, account, themeLoadingToggle, finalEndpoint);
}

export function TriggerCourseBookmarkPost(setCourseBookmark, instance, account, themeLoadingToggle, courseId, status) {
  const finalEndpoint = protectedResources.courseBookmark.endpoint + '?Id=' + courseId + '&statusCode=' + status;

  postApiSilent(setCourseBookmark, null, instance, account, finalEndpoint);
}

export function TriggerCourseEnrollPost(setCourseEnrolled, instance, account, themeLoadingToggle, courseId, status) {
  const finalEndpoint = protectedResources.courseEnroll.endpoint + '?Id=' + courseId + '&statusCode=' + status;

  postApi(setCourseEnrolled, null, instance, account, themeLoadingToggle, finalEndpoint);
}
export function TriggerRateCoursePost(setRatedCourse, feedbackData, instance, account, courseId, rating) {
  const finalEndpoint = protectedResources.rateCourse.endpoint + '?Id=' + courseId + '&rating=' + rating;

  postApiSilent(setRatedCourse, feedbackData, instance, account, finalEndpoint);
}

export function TriggerDownloadCourseCertificate(setError, instance, account, themeLoadingToggle, courseId) {
  const finalEndpoint = protectedResources.courseDownloadCertificate.endpoint + '?Id=' + courseId;

  getDocumentApi(setError, instance, account, themeLoadingToggle, finalEndpoint);
}

export async function TriggerCourseLookupsGet(
  setCoursesLookup,
  instance,
  account,
  themeLoadingToggle,
  setLoaded,
  loaded,
  lookupEntity,
) {
  function setLoadedItem(lookupEntity, loaded) {
    let allSet = true;
    var newLoaded = { ...loaded };

    newLoaded.checks.filter(check => check.Item === lookupEntity)[0].loaded = true;

    newLoaded.checks.forEach(check => {
      allSet = allSet && check.loaded;
    });

    newLoaded.all = allSet;

    if (allSet) {
      themeLoadingToggle(false);
    }

    return newLoaded;
  }

  themeLoadingToggle(true);

  const finalEndpoint = protectedResources.lookup.endpoint + lookupEntity;

  DataService.getWithTokenSilent(finalEndpoint, instance, account)
    .then(resp => resp.data)
    .then(resp => {
      if (resp != undefined) {
        if (resp.succeeded) {
          setCoursesLookup(resp.data);
  
          setLoaded({ ...setLoadedItem(lookupEntity, loaded) });
        }
      }
    });
}

export function TriggerStatusPost(setSaveResponse, instance, account, themeLoadingToggle, id, type, status) {
  const getEndpoint = () => {
    switch (type) {
      case 'Course':
        return protectedResources.courseSetStatus.endpoint;
      case 'Assessment':
        return protectedResources.assessmentSetStatus.endpoint;
      case 'Lesson':
        return protectedResources.lessonSetStatus.endpoint;
      case 'Category':
        return protectedResources.categoriesetStatus.endpoint;
      case 'Product':
        return protectedResources.productSetStatus.endpoint;
      case 'Reward':
        return protectedResources.rewardSetStatus.endpoint;
      case 'Quiz':
        return protectedResources.setQuizStatus.endpoint;
      default:
        return null;
    }
  };
  const finalEndpoint = getEndpoint() + '?Id=' + id + '&statuscode=' + status;
  postApi(setSaveResponse, null, instance, account, themeLoadingToggle, finalEndpoint);
}

export function TriggerSaveCourse(setSaveResponse, formRequest, instance, account, themeLoadingToggle) {
  postApi(setSaveResponse, formRequest, instance, account, themeLoadingToggle, protectedResources.courseSave.endpoint);
}

export function TriggerAdminAssessmentDetailGet(
  setAssessmentData,
  instance,
  account,
  themeLoadingToggle,
  assessmentId,
) {
  const finalEndpoint = protectedResources.assessmentAdminDetail.endpoint + '?Id=' + assessmentId;

  getApi(setAssessmentData, instance, account, themeLoadingToggle, finalEndpoint);
}

export async function TriggerAssessmentTypesGet(setAssessmentTypes, instance, account, themeLoadingToggle) {
  const finalEndpoint =
    protectedResources.getAssessmentTypes.endpoint + '?pagenumber=1&pagesize=5&SortBy=name&SortOrder=asc';

  await getApi(setAssessmentTypes, instance, account, themeLoadingToggle, finalEndpoint);
}

export async function TriggerAssessmentDetailGet(
  setAssessmentDetail,
  instance,
  account,
  themeLoadingToggle,
  assessmentId,
) {
  const finalEndpoint = protectedResources.assessmentDetail.endpoint + '?Id=' + assessmentId;

  await getApi(setAssessmentDetail, instance, account, themeLoadingToggle, finalEndpoint);
}

export function TriggerAssessmentStartPost(setAssessmentStart, instance, account, themeLoadingToggle, request) {
  postApi(
    setAssessmentStart,
    request,
    instance,
    account,
    themeLoadingToggle,
    protectedResources.assessmentStart.endpoint,
  );
}

export function TriggerAssessmentSubmitPost(setAssessmentSubmit, instance, account, themeLoadingToggle, request) {
  postApi(
    setAssessmentSubmit,
    request,
    instance,
    account,
    themeLoadingToggle,
    protectedResources.assessmentSubmit.endpoint,
  );
}

export function TriggerProductSave(setSaveResponse, formRequest, instance, account, themeLoadingToggle) {
  postApi(setSaveResponse, formRequest, instance, account, themeLoadingToggle, protectedResources.productSave.endpoint);
}

export function TriggerSaveAssessment(setSaveResponse, formRequest, instance, account, themeLoadingToggle) {
  postApi(
    setSaveResponse,
    formRequest,
    instance,
    account,
    themeLoadingToggle,
    protectedResources.assessmentSave.endpoint,
  );
}

export function TriggerAdminLessonDetailGet(setLessonData, instance, account, themeLoadingToggle, lessonId) {
  const finalEndpoint = protectedResources.lessonsAdminDetail.endpoint + '?Id=' + lessonId;

  getApi(setLessonData, instance, account, themeLoadingToggle, finalEndpoint);
}

export async function TriggerSaveLesson(setSaveResponse, formRequest, instance, account, themeLoadingToggle) {
  postApi(setSaveResponse, formRequest, instance, account, themeLoadingToggle, protectedResources.lessonSave.endpoint);
}

export async function TriggerLessonDetailGet(setLessonDetail, instance, account, themeLoadingToggle, lessonId) {
  const finalEndpoint = protectedResources.lessonDetail.endpoint + '?Id=' + lessonId;

  await getApi(setLessonDetail, instance, account, themeLoadingToggle, finalEndpoint);
}

export function TriggerProductDetails(setProductDetails, instance, account, themeLoadingToggle) {
  const finalEndpoint = protectedResources.lookup.endpoint + 'product';

  getProductApi(setProductDetails, instance, account, themeLoadingToggle, finalEndpoint);
}

export function TriggerCategoryDetail(setSingleCategoryData, instance, account, themeLoadingToggle, categoryId) {
  const finalEndpoint = protectedResources.getSingleCategory.endpoint + '?Id=' + categoryId;

  getApi(setSingleCategoryData, instance, account, themeLoadingToggle, finalEndpoint);
}

export function TriggerSingleProductDetail(setSingleProductData, instance, account, themeLoadingToggle, productId) {
  const finalEndpoint = protectedResources.getSingleProduct.endpoint + '?Id=' + productId;

  getApi(setSingleProductData, instance, account, themeLoadingToggle, finalEndpoint);
}

export function TriggerSingleQuizDetails(setSingleQuizData, instance, account, themeLoadingToggle, quizId) {
  const finalEndpoint = protectedResources.getQuizDetails.endpoint + '?Id=' + quizId;

  getApi(setSingleQuizData, instance, account, themeLoadingToggle, finalEndpoint);
}

export function Triggercategoriesave(setSaveResponse, formRequest, instance, account, themeLoadingToggle) {
  postApi(
    setSaveResponse,
    formRequest,
    instance,
    account,
    themeLoadingToggle,
    protectedResources.categoriesave.endpoint,
  );
}

export function TriggerGetCategoriesExternalUser(setCourses, instance, account, themeLoadingToggle) {
  getApi(setCourses, instance, account, themeLoadingToggle, protectedResources.getCategoryExternal.endpoint);
}

export function TriggerGetSingleCategoryExternal(
  setDetails,
  instance,
  account,
  themeLoadingToggle,
  categoryId,
  productType,
) {
  const finalEndpoint =
    protectedResources.getSingleCategoryExternal.endpoint +
    '?Id=' +
    categoryId +
    (productType === undefined ? '' : '&productType=' + productType);

  getApi(setDetails, instance, account, themeLoadingToggle, finalEndpoint, categoryId);
}

export function TriggerGetSingleProductExternal(setProductDetails, instance, account, themeLoadingToggle, productId) {
  const finalEndpoint = protectedResources.getSingleProductExternal.endpoint + '?Id=' + productId;

  getApi(setProductDetails, instance, account, themeLoadingToggle, finalEndpoint, productId);
}
export function TriggerGetFeaturedProducts(setProducts, instance, account, themeLoadingToggle) {
  const finalEndpoint = protectedResources.getFeatureProduct.endpoint;

  getApi(setProducts, instance, account, themeLoadingToggle, finalEndpoint);
}

export function TriggerGetProductsByType(setProducts, instance, account, type, themeLoadingToggle) {
  const finalEndpoint = protectedResources.getProductByType.endpoint + (type === '' ? '' : '?producttype=' + type);

  getApi(setProducts, instance, account, themeLoadingToggle, finalEndpoint);
}

export function TriggerGetProductLookUp(setProducts, instance, account, themeLoadingToggle) {
  const finalEndpoint = protectedResources.getProductLookUp.endpoint;

  getApi(setProducts, instance, account, themeLoadingToggle, finalEndpoint);
}

export function TriggerRewardSave(setSaveResponse, formRequest, instance, account, themeLoadingToggle) {
  postApi(setSaveResponse, formRequest, instance, account, themeLoadingToggle, protectedResources.rewardSave.endpoint);
}

export function TriggerQuizSave(setSaveResponse, formRequest, instance, account, themeLoadingToggle) {
  postApi(setSaveResponse, formRequest, instance, account, themeLoadingToggle, protectedResources.quizSave.endpoint);
}

export function TriggerAdminRewardDetailGet(setRewardData, instance, account, themeLoadingToggle, rewardId) {
  const finalEndpoint = protectedResources.rewardAdminDetail.endpoint + '?Id=' + rewardId;

  getApi(setRewardData, instance, account, themeLoadingToggle, finalEndpoint);
}

export function TriggerRewardsGet(setRewardData, instance, account, themeLoadingToggle) {
  getApi(setRewardData, instance, account, themeLoadingToggle, protectedResources.rewardsGet.endpoint);
}

export function TriggerRewardDetailGet(setRewardDetails, instance, account, themeLoadingToggle, rewardId) {
  const finalEndpoint = protectedResources.getRewardDetails.endpoint + '?Id=' + rewardId;

  getApi(setRewardDetails, instance, account, themeLoadingToggle, finalEndpoint);
}

export function DispatchCourseDetail(payload, dispatch, action) {
  dispatch(action(BuildCourseDetailState(payload)));
}

function BuildCourseDetailState(payload) {
  let courseDetail = {
    courseLocalState: 'ViewDetail',
    courseDetail: {
      id: payload.data.id,
      name: payload.data.name,
      typeText: payload.data.typeText,
      bigImage: payload.data.bigImage,
      bigImageUrl: payload.data.bigImageUrl,
      scheduledStartDate: payload.data.scheduledStartDate,
      scheduledEndDate: payload.data.scheduledEndDate,
      courseUrl: payload.data.courseUrl,
      cpdPoints: payload.data.cpdPoints,
      description: payload.data.description,
      dnaRewardPoints: payload.data.dnaRewardPoints,
      enrolStatusText: payload.data.enrolStatusText,
      userRating: payload.data.userRating,
      tagList: payload.data.tagList,
      allAssessmentsPassed: payload.data.allAssessmentsPassed
    },
    courseContent: [],
  };
  payload.data.assessments.map(assessment => {
    courseDetail.courseContent.push({
      type: 'assessment',
      id: assessment.id,
      order: assessment.order,
      status: assessment.lastStatusCodeText,
      content: {
        title: assessment.name,
        content: assessment.name,
        failedAttempts: assessment.failedAttempts,
        lastPercentage: assessment.lastPercentage,
      },
    });
  });

  payload.data.lessons.map(lesson => {
    courseDetail.courseContent.push({
      type: 'lesson',
      id: lesson.id,
      order: lesson.order,
      status: null,
      content: {
        title: lesson.name,
        objective: lesson.objective,
        courseUrl: lesson.courseUrl,
        reportFileUrl: lesson.reportFileUrl,
      },
    });
  });
  courseDetail.courseContent = courseDetail.courseContent.sort((a, b) => (a.order > b.order ? 1 : -1));

  return { initialized: payload.initialized, data: courseDetail };
}

function BuildAllUnreadNotificationState(payload) {
  return { initialized: payload.initialized, data: payload.data };
}

export function DispatchSalesRepUnreadNotification(payload, dispatch, action) {
  dispatch(action(BuildAllUnreadNotificationState(payload)));
}

function BuildPortfolios(payload) {
  return { initialized: payload.initialized, data: payload.data };
}

export function DispatchSalesRepPortfolios(payload, dispatch, action) {
  dispatch(action(BuildPortfolios(payload)));
}

function postApi(setSaveResponse, formRequest, instance, account, themeLoadingToggle, endpoint) {
  themeLoadingToggle(true);
  DataService.postWithTokenSilent(endpoint, formRequest, instance, account)
    .then(resp => resp.data)
    .then(resp => {
      if (resp != undefined) {
        setSaveResponse({ ...resp });
      }
    })
    .finally(() => {
      themeLoadingToggle(false);
    })
    .catch((error, resp) => {
      themeLoadingToggle(false);
    });
}

function postApiSilent(setSaveResponse, formRequest, instance, account, endpoint) {
  DataService.postWithTokenSilent(endpoint, formRequest, instance, account)
    .then(resp => resp.data)
    .then(resp => {
      if (resp != undefined) {
        setSaveResponse(resp);
      }
    })
    .catch((error, resp) => {
      console.log(error);
    });
}

function getApi(setContent, instance, account, themeLoadingToggle, endpoint) {
  themeLoadingToggle(true);
  DataService.getWithTokenSilent(endpoint, instance, account)
    .then(resp => resp.data)
    .then(resp => {
      if (resp != undefined) {
        if (resp.succeeded) {
          setContent({
            initialized: true,
            data: resp.data,
          });
        }
      }
    })
    .finally(() => {
      themeLoadingToggle(false);
    })
    .catch(resp => {
      themeLoadingToggle(false);
      setContent({
        initialized: true,
        data: undefined,
        message: resp.response?.data.message,
      });
    });
}

function getDocumentApi(setContent, instance, account, themeLoadingToggle, endpoint) {
  themeLoadingToggle(true);
  DataService.getWithTokenSilent(endpoint, instance, account)
    .then(resp => resp.data)
    .then(resp => {
      if (resp.data != undefined) {
        if (resp.succeeded) {
          var documentElement = document.createElement('a');
          documentElement.href = 'data:application/octet-stream;base64,' + resp.data.documentBody;
          documentElement.download = resp.data.documentName;
          documentElement.click();

          themeLoadingToggle(false);
        }
      }
    })
    .finally(() => {
      themeLoadingToggle(false);
    })
    .catch(resp => {
      themeLoadingToggle(false);
      setContent({
        initialized: true,
        message: resp.response.data.message,
      });
    });
}

function getProductApi(setProductDetails, instance, account, themeLoadingToggle, finalEndpoint) {
  themeLoadingToggle(true);

  DataService.getWithTokenSilent(finalEndpoint, instance, account)
    .then(resp => resp.data)
    .then(resp => {
      if (resp != undefined) {
        if (resp.succeeded) {
          setProductDetails({
            initialized: true,
            data: resp.data,
          });
        }
      }
    })
    .finally(() => {
      themeLoadingToggle(false);
    })
    .catch(() => {
      themeLoadingToggle(false);
    });
}
