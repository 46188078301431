import { Col, Row, Button } from 'antd';
import React, {  } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './style.css';
import CartTable from './overview/cartTable';


function Cart(params) {

  const cart = useSelector(state => state.Cart);
  const history = useHistory();

  return (
    <>
      <div style={{ overflow: 'auto', maxHeight: 350 }}>
        <CartTable cart={cart} />
      </div>

      <Row justify="center" style={{background: 'purple'}}>
        {/* <Col xxl={24} xl={24} md={24} sm={24} xs={24} offset={0}> */}
        <Button
            style={{ borderRadius: '5rem', margin: '10px', border: 'solid 2px white', background: 'transparent' }}
            key={'cart'}
            type="primary"
            disabled={cart.length === 0}
            onClick={() => history.push('/cart')}
          >
            View cart
          </Button>
          <Button
            style={{ borderRadius: '5rem', margin: '10px', border: 'solid 2px #53AF8E', background: '#53AF8E' }}
            key={'checkOut'}
            type="primary"
            disabled={cart.length === 0}
            onClick={() => history.push('/checkout')}
          >
            Checkout
          </Button>
        {/* </Col> */}
      </Row>
    </>
  );
}

export default Cart;
