import React, { useEffect, lazy } from 'react';
// import { Navigate } from "react-router-dom";
import { PermissionConfigs } from './PermissionConfig';
import { useDispatch, useSelector } from 'react-redux';
import { DataService } from '../../config/dataService/dataService';

import { protectedResources } from '../../config/auth/authConfig';
import { populateRoles } from '../../redux/permission/actionCreator';
import { populateProfileInfo } from '../../redux/profileInformation/actionCreator';
import { logDOM } from '@testing-library/react';
import { themeLoading } from '../../redux/themeLayout/actionCreator';
import { bindActionCreators } from 'redux';
import { Redirect, Route } from 'react-router-dom';
import { InteractionRequiredAuthError, BrowserAuthError } from '@azure/msal-browser';
import { TriggerGetRoles, TriggerGetAccountInformation } from './api';
import { TriggerGetProfileLookUp } from '../utilities/auth-info/user-profile/api';
import { populateProfileLookUpInfo } from '../../redux/profileLookup/actionCreator';

export function GetPermissions(props) {
  const dispatch = useDispatch();
  const themeLoadingToggle = bindActionCreators(themeLoading, dispatch);

  const { permissions } = useSelector(state => {
    return {
      permissions: state.permissions,
    };
  });

  const { profileInformation } = useSelector(state => {
    return {
      profileInformation: state.profileInformation,
    };
  });

  const lookUp = useSelector(state => {
    return {
      lookup: state.lookUp,
    };
  });

  useEffect(() => {
    if (props.account && props.inProgress === 'none') {
      if (!permissions.initialized) {
        TriggerGetRoles(populateRoles, dispatch, props.instance, props.account, themeLoadingToggle);
      }
      if (!profileInformation.initialized) {
        TriggerGetAccountInformation(populateProfileInfo, dispatch, props.instance, props.account, themeLoadingToggle);
      }
      if (!lookUp.initialized) {
        TriggerGetProfileLookUp(populateProfileLookUpInfo, dispatch, props.instance, props.account, themeLoadingToggle);
      }
    }
  }, [props.account, props.inProgress, props.instance]);
}

export function PermissionsWrapper(props) {
  const permissions = useSelector(state => state.permissions);
  if (permissions != undefined && props.requiredPermissions != undefined) {
    if (permissions.initialized) {
      let hasPermission = false;
      hasPermission =
        permissions.roles.filter(userPermissionRole => props.requiredPermissions?.includes(userPermissionRole)).length >
        0;
      if (hasPermission) {
        return props.children;
      } else {
        if (props.isComponent) {
          return <Redirect to="permissionDenied" />;
        } else {
          return '';
        }
      }
    }
  }
  if (props.hasAccess != undefined) {
    if (props.hasAccess) {
      return props.children;
    } else {
      if (props.isComponent) {
        return <Redirect to="permissionDenied" />;
      } else {
        return '';
      }
    }
  }
  return props.children;
}
export const PermissionRoute = ({ component: Component, ...rest }) => {
  const UnAuthorize = lazy(() => import('../../container/pages/401'));
  const permissions = useSelector(state => state.permissions);

  var isPermitted = false;
  if (permissions.initialized) {
    isPermitted =
      permissions.roles.filter(r => rest.permissions.some(p => p.toLowerCase() == r.toLowerCase())).length > 0;
  }

  return <Route {...rest} render={props => (isPermitted ? <Component {...props} /> : <UnAuthorize />)} />;
};
export function checkPermissions(props) {
  if (props.userHeldPermissions != undefined && props.requiredPermissions != undefined) {
    if (props.userHeldPermissions.initialized) {
      let hasPermission = false;
      hasPermission =
        props.userHeldPermissions.roles.filter(userPermissionRole =>
          props.requiredPermissions?.includes(userPermissionRole),
        ).length > 0;

      if (hasPermission) {
        return true;
      } else {
        if (props.isComponent) {
          return false;
        } else {
          return false;
        }
      }
    }
  }
  return false;
}

export function hasAccessFlag(props) {
  if (props.hasAccess != undefined) {
    return props.hasAccess;
  } else {
    return false;
  }
}

export function GetComponentPermissions(prop) {
  let permissions = [];
  let found = false;
  PermissionConfigs.forEach(permissionConfig => {
    if (!found) {
      if (Array.isArray(prop)) {
        prop.forEach((item) => {
          if (permissionConfig.component == item) {
            permissions = permissionConfig.permissions;
            found = true;
          }
        });
      } else {
        if (permissionConfig.component == prop) {
          permissions = permissionConfig.permissions;
          found = true;
        }
      }
    }
  });
  return permissions;
}
