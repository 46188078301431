import React from 'react';
import { Row } from 'antd';
import { loginRequest } from '../../../config/auth/authConfig';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import homeroutes from '../../../utility/homeroutes';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const LayoutSelector = WraperContent => {
  return () => {
    const isAuthenticated = useIsAuthenticated();
    const location = useLocation();
    const isHomeRoute = location.pathname == '/' ? true : homeroutes.routes.includes(location.pathname);
    const { instance } = useMsal();
    
    if (!isHomeRoute) {
      if (!isAuthenticated) {
        instance.loginRedirect(loginRequest).catch(e => {
          console.error(e);
        });
      }
    }

    function LandingLayout() {
      return (
        <Row>
          <WraperContent />
        </Row>
      );
    }
    return <LandingLayout />;
  };
};

export default LayoutSelector;
