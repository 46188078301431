import { protectedResources } from '../../config/auth/authConfig';
import { DataService } from '../../config/dataService/dataService';

export function TriggerGetCourses(setOrders, account, inProgress, instance, query, themeLoadingToggle) {
  themeLoadingToggle(true);
  const finalEndpoint = protectedResources.searchCourses.endpoint + '?filter=' + query;
  getApi(setOrders, account, inProgress, instance, query, themeLoadingToggle, finalEndpoint);
}

export function TriggerGetProducts(setProducts, account, inProgress, instance, query, themeLoadingToggle) {
  themeLoadingToggle(true);
  const finalEndpoint = protectedResources.searchProducts.endpoint + '?filter=' + query;
  getApi(setProducts, account, inProgress, instance, query, themeLoadingToggle, finalEndpoint);
}

export function TriggerGetCampines(setCampines, account, inProgress, instance, query, themeLoadingToggle) {
  themeLoadingToggle(true);
  const finalEndpoint = protectedResources.searchCampines.endpoint + '?filter=' + query;
  getApi(setCampines, account, inProgress, instance, query, themeLoadingToggle, finalEndpoint);
}

export function TriggerGetOrders(setOrders, account, inProgress, instance, query, themeLoadingToggle) {
  themeLoadingToggle(true);
  const finalEndpoint = protectedResources.searchOrders.endpoint + '?filter=' + query;
  getApi(setOrders, account, inProgress, instance, query, themeLoadingToggle, finalEndpoint);
}

export function TriggerGetTickets(setTickes, account, inProgress, instance, query, themeLoadingToggle) {
  themeLoadingToggle(true);
  const finalEndpoint = protectedResources.searchTickets.endpoint + '?filter=' + query;
  getApi(setTickes, account, inProgress, instance, query, themeLoadingToggle, finalEndpoint);
}

export function TriggerGetQuiz(setQuiz, account, inProgress, instance, query, themeLoadingToggle) {
  themeLoadingToggle(true);
  const finalEndpoint = protectedResources.searchQuiz.endpoint + '?filter=' + query;
  // getApi(setQuiz, account, inProgress, instance, query, themeLoadingToggle, finalEndpoint)
}
//Does not exist in backend- Anton vd W

function getApi(setOrders, account, inProgress, instance, query, themeLoadingToggle, finalEndpoint) {
  DataService.getWithTokenSilent(finalEndpoint, instance, account)
    .then(resp => resp.data)
    .then(resp => {
      if (resp != undefined) {
        if (resp.succeeded) {
          setOrders({
            initialized: true,
            data: resp.data,
          });
          themeLoadingToggle(false);
        }
      }
    });
}
