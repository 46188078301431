const actions = {
    POPULATE_CLIENTLIST: 'POPULATE_CLIENTLIST',
    populateClientsList: clientList => {
      return {
        type: actions.POPULATE_CLIENTLIST,
        payload: clientList
      };
    }
  };
  
  export default actions;
  