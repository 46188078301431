const actions = {
    POPULATE_PROFILELOOKUPINFORMATION: 'POPULATE_PROFILELOOKUPINFORMATION',
    populateProfileLookUpInformation: account => {
        return {
            type: actions.POPULATE_PROFILELOOKUPINFORMATION,
            payload: account
        }
    }
}

export default actions;