import { DataService } from "../../config/dataService/dataService";
import { protectedResources } from "../../config/auth/authConfig";

function getApiToken(setData, instance, account, themeLoadingToggle, endpoint) {
    // themeLoadingToggle(true);
    DataService.getWithTokenSilent(endpoint, instance, account)
        .then(resp => resp.data)
        .then(resp => {
            if (resp != undefined) {
                setData({
                    initialized: true,
                    data: resp.data
                })
            }
        })
        .finally(() => {
            //  themeLoadingToggle(false);
        })
        .catch(() => {
            // themeLoadingToggle(false);
        });
}

export function TriggerGetReport(setData, instance, account, themeLoadingToggle){
    getApiToken(setData, instance, account, themeLoadingToggle, protectedResources.getReport.endpoint)
}