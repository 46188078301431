import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import { cartReducer } from './cart/reducers';
import { clientListReducer } from './clients/reducers';
import { courseDetailReducer, scheduledCoursesReducer } from './dnaacademy/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import { readMessageReducer } from './message/reducers';
import { readNotificationReducer } from './notification/reducers';
import { roleReducer } from './permission/reducers';
import { profileInformationReducer } from './profileInformation/reducers';
import { profileLookUpInformationReducer } from './profileLookup/reducer';
import ChangeLayoutMode from './themeLayout/reducers';
import { userReducer } from './users/reducers';
import { salesRepNotificationReducer } from './salesRepNotifications/reducers';
import { salesRepUnreadNotificationReducer } from './salesRepUnreadNotifications/reducers';
import { salesRepPortfoliosReducer } from './salesRepPortfolios/reducers';


const rootReducers = combineReducers({
  headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  users: userReducer,
  auth: authReducer,
  permissions: roleReducer,
  clientList: clientListReducer,
  ChangeLayoutMode,
  Cart: cartReducer,
  profileInformation: profileInformationReducer,
  lookUp: profileLookUpInformationReducer,
  courseDetail: courseDetailReducer,
  scheduledCourses: scheduledCoursesReducer,
  salesRepNotification: salesRepNotificationReducer,
  salesRepUnreadNotification: salesRepUnreadNotificationReducer,
  salesRepPortfolios: salesRepPortfoliosReducer,
});

export default rootReducers;
