import React from 'react';
import { Divider, Typography, Descriptions } from 'antd';
import './style.css';
import QRCode from 'qrcode.react';

const { Paragraph, Text } = Typography;

export function CheckoutOrdersResultSuccess(params) {
  const { checkoutResponse } = params;
  return (
    <>
      {checkoutResponse.data.orders.map(order => {
        return (
          <Paragraph>
            <Descriptions colon={true} layout="Horizontal" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
              {order.vouchers?.map(voucher => {
                return (
                  <>
                    <Descriptions.Item labelStyle={{ width: '40%' }} label={<b>Campaign Name</b>}>
                      {voucher.campaignName}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '40%' }} label={<b>Campaign Description</b>}>
                      {voucher.description}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '40%' }} label={<b>Amount</b>}>
                      {voucher.voucherAmount}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '40%' }} label={<b>Code</b>}>
                      {voucher.wiCode}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '40%' }} label={<b>Qr Code</b>}>
                      <QRCode value={voucher.wiQr} />
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ width: '40%' }}>
                      <Divider />
                    </Descriptions.Item>
                  </>
                );
              })}
            </Descriptions>
          </Paragraph>
        );
      })}
    </>
  );
}

export function CheckoutOrdersResultFailed(params) {
  const { checkoutResponse } = params;
  return (
    <>
      {checkoutResponse.data.orders.map(order => {
        return (
          <Paragraph>
            <Descriptions colon={true} layout="Horizontal" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
              <Descriptions.Item labelStyle={{ width: '40%' }} label={<b>Campaign Name</b>}>
                {order.campaignName}
              </Descriptions.Item>
              <Descriptions.Item labelStyle={{ width: '40%' }} label={<b>Campaign Description</b>}>
                {order.description}
              </Descriptions.Item>
              <Descriptions.Item labelStyle={{ width: '40%' }} label={<b>Quantity</b>}>
                {order.quantity}
              </Descriptions.Item>    
              <Descriptions.Item labelStyle={{ width: '40%' }} label={<b>Message</b>}>
                {order.message}
              </Descriptions.Item>            
              <Descriptions.Item labelStyle={{ width: '40%' }}>
                <Divider />
              </Descriptions.Item>
            </Descriptions>
          </Paragraph>
        );
      })}
    </>
  );
}
