import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import Dashboard from './dashboard';
import Orders from './orders';
import DNAAcademy from './dnaacademy';
import Support from './support';
import MobiMims from './mobiMims';
import Account from './account';
import Products from './product';
import withAdminLayout from '../../layout/withAdminLayout';
import { PermissionRoute, GetComponentPermissions } from '../../components/AccessControl/PermisionWrapper';
import Cart from '../../container/cart';
import Checkout from '../../container/cart/checkout';
import Profile from './profile';
import SearchResult from '../../components/SearchResult';
import Report from '../../container/report';
import Reports from '../../container/report';
import Notifications from '../../container/notifications';

const Admin = () => {
  const { path } = useRouteMatch();
  const NotFound = lazy(() => import('../../container/pages/404'));
  const UnAuthorize = lazy(() => import('../../container/pages/404'));

  return (
    <Switch>
      <Suspense fallback={<div className="spin">{/* <Spin /> */}</div>}>
        <Switch>
          <Route exact path={path} component={Dashboard} />

          <Route path={`${path}profile`} component={Profile} />

          <PermissionRoute
            permissions={GetComponentPermissions('Reports')}
            path={`${path}report`}
            component={Reports}
          />

          <Route path={`${path}search`} component={SearchResult} />

          <PermissionRoute permissions={GetComponentPermissions('Orders')} path={`${path}orders`} component={Orders} />

          <PermissionRoute
            permissions={GetComponentPermissions(['ProductsOTP', 'ProductsRx'])}
            path={`${path}products`}
            component={Products}
          />
          
          <PermissionRoute permissions={GetComponentPermissions('Learning')} path={`${path}learn`} component={DNAAcademy} />

          <PermissionRoute
            permissions={GetComponentPermissions('Support')}
            path={`${path}support`}
            component={Support}
          />
          <PermissionRoute
            permissions={GetComponentPermissions('MobiMiMs')}
            path={`${path}mobiMims`}
            component={MobiMims}
          />
          <Route path={`${path}account`} component={Account} />

          <PermissionRoute permissions={GetComponentPermissions('Learning')} path={`${path}notifications`} component={Notifications} />

          <PermissionRoute permissions={GetComponentPermissions('Learning')} path={`${path}cart`} component={Cart} />

          <PermissionRoute permissions={GetComponentPermissions('Learning')} path={`${path}checkout`} component={Checkout} />

          <Route component={NotFound} />
          <Route path="/" component={UnAuthorize} />
        </Switch>
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
