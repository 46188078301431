import actions from './actions';

const { ADD_REWARD_TO_CART, REMOVE_REWARD_FROM_CART, UPDATE_REWARD_ITEM, CLEAR_REWARD_CART } = actions;

const updateQuantity = p => (p.quantity ? { ...p, quantity: p.quantity + 1 } : { ...p, quantity: 2 });

const cartReducer = (state = [], action) => {
  const { type, payload } = action;
  let rewardInCart = false;

  if (payload !== undefined) {
    rewardInCart = state.find(p => p.id === payload.id);
  }

  switch (type) {
    case ADD_REWARD_TO_CART:
      if (!rewardInCart) return [...state, payload];
      return state;
    case REMOVE_REWARD_FROM_CART:
      if (rewardInCart) return [...state.filter(item => item.id !== payload.id)];
      return state;
    case UPDATE_REWARD_ITEM:
      if (rewardInCart) {
        let itemsToUpdate = [...state];
        itemsToUpdate.forEach(item => {
          if (item.id === payload.id) {
            item.quantity = payload.quantity;
          }
        });
        return [...itemsToUpdate];
      }
      return state;
    case CLEAR_REWARD_CART:
      return [];
    default:
      return state;
  }
};

export { cartReducer };
