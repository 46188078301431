import { Col, Collapse, Layout, Row, Card, Carousel, Rate, Menu, Drawer, Anchor, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import ciplaLogo from '../../static/img/cipla-logo1.png';
import { LandingPage } from '../styled';
import { useAccount, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../config/auth/authConfig';
import FeatherIcon from 'feather-icons-react';
import Heading from '../../components/heading/heading';
import headerImg from '../../static/img/headerSideImg1.png';
import { TriggerGetLandingPage } from './api';
import Signup from '../../container/profile/authentication/overview/Signup';
import { useSpring, a } from 'react-spring';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { isBrowser, isMobile } from 'react-device-detect';
import TermsConditionModal from '../profile/authentication/overview/TermsConditionModal';
import PrivacyPolicy from '../profile/authentication/overview/privacyPolicy';
import { courseType } from '../../components/enum';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const { Link } = Anchor;
const { Panel } = Collapse;

const customPanelStyle = {
  background: '#ffffff',
  borderRadius: 5,
  marginBottom: 5,
  fontSize: 14,
};

const Home = () => {
  const { Header, Footer } = Layout;

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const history = useHistory();
  const [state, setState] = useState({ current: 'mail', visible: false });
  const [signUpVisible, setSignUpVisible] = useState(false);
  const [data, setData] = useState({ initialized: false, data: [] });
  const [skeletonState, setSkeletonState] = useState({ loading: true });
  const [tcVisible, setTCVisible] = useState(false);
  const [policyVisible, setPolicyVisible] = useState(false);

  useEffect(() => {
    if (data.initialized === false) {
      TriggerGetLandingPage(setData, setSkeletonState);
    }
  }, [instance, accounts, inProgress]);

  const handleSubmit = instance => {
    instance.loginRedirect(loginRequest).catch(e => {
      console.error(e);
    });
  };
  const onRegister = () => {
    history.push('/register');
  };
  const showDrawer = () => {
    setState({
      visible: true,
    });
  };
  const onClose = () => {
    setState({
      visible: false,
    });
  };
  const SampleNextArrow = props => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          color: 'black',
          fontSize: '22px',
          lineHeight: '1.5715',
          border: '1px gray solid',
          borderRadius: '50px',
          padding: '20px',
          background: 'white',
        }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = props => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          color: 'black',
          fontSize: '20px',
          lineHeight: '1.5715',
          border: '1px gray solid',
          borderRadius: '50px',
          padding: '20px',
          zIndex: '100',
          background: 'white',
        }}
        onClick={onClick}
      />
    );
  };
  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const carouselResponsiveSettings = [
    {
      breakpoint: 1258,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 876,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  return (
    <>
      <LandingPage>
        <Layout>
          <Header className="landing-page-style">
            <Row className="header-main">
              <Col span={24} className="header-menu">
                <nav className="menuBar">
                  <div className="menuCon">
                    <div className="rightMenu">
                      <Menu mode="horizontal">
                        <Menu.Item key="4">
                          <Anchor affix={false}>
                            <Link href="#therapeuticCategory" title="Therapeutic Categories" />
                          </Anchor>
                        </Menu.Item>
                        <Menu.Item key="1">
                          <Anchor affix={false}>
                            <Link href="#learnCipla" title="Learn" />
                          </Anchor>
                        </Menu.Item>
                        <Menu.Item key="2">
                          <Button onClick={() => handleSubmit(instance)} className="menu-btn">
                            Sign in
                          </Button>
                        </Menu.Item>
                        <Menu.Item key="3">
                          <Button className="sign-up-btn" onClick={onRegister} style={{ marginRight: '0' }}>
                            Sign up
                          </Button>
                        </Menu.Item>
                      </Menu>
                    </div>
                    <Button className="barsMenu" type="primary" onClick={showDrawer}>
                      <span className="barsBtn"></span>
                    </Button>
                    <Drawer title="" closable={false} onClose={onClose} visible={state.visible}>
                      <Menu mode="vertical" style={{ width: '100%' }}>
                        <Menu.Item key="1" style={{ width: '100%' }}>
                          <Anchor affix={false}>
                            <Link href="#therapeuticCategory" title="Therapeutic Categories" />
                          </Anchor>
                        </Menu.Item>
                        <Menu.Item key="2" style={{ width: '100%' }}>
                          <Anchor affix={false}>
                            <Link href="#learnCipla" title="Learn" />
                          </Anchor>
                        </Menu.Item>
                        <Menu.Item key="3" style={{ width: '100%' }}>
                          <Button onClick={() => handleSubmit(instance)} style={{ border: 'none', marginLeft: '-5px' }}>
                            Sign in
                          </Button>
                        </Menu.Item>
                        <Menu.Item key="4" style={{ width: '100%' }}>
                          <a href="" onClick={onRegister}>
                            Sign up
                          </a>
                        </Menu.Item>
                      </Menu>
                    </Drawer>
                  </div>
                </nav>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={10} lg={10}>
                <div className="left-header-div">
                  <img src={ciplaLogo} alt="cipla-logo" className="cipla-logo" />
                  <h1 className="header-text">Connect with us, from anywhere</h1>
                  <h3 className="header-sub-text">
                    Manage all your interactions with Cipla in one central location,from order tracking, customer care
                    and learning through the CPD accredited My Cipla Learn.
                  </h3>
                  <div>
                    <Button type="primary" className="login-btn" onClick={() => handleSubmit(instance)}>
                      Login
                    </Button>
                    <Button type="default" className="new-account-btn" onClick={onRegister}>
                      Create account
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={14} lg={10} className="header-col">
                <img alt="Header Image" src={headerImg} className="header-img" />
              </Col>
            </Row>
          </Header>

          <Row className="category-row" id="therapeuticCategory">
            <Col span={24}>
              <h3>Therapeutic Categories</h3>
              <div style={{ width: '65%' }}>
                <p>
                  Cipla offers a broad range of products across therapeutic areas. Browse our product range by therapeutic
                  area, or simply click through individual brands for more product info, co-sell opportunities within our
                  range and more.
                </p>
              </div>

              <Skeleton avatar active loading={skeletonState.loading}>
                <Carousel slidesToShow={3} arrows {...settings} dots={false} responsive={carouselResponsiveSettings}>
                  {data?.data?.categories &&
                    data?.data?.categories.map(cat => {
                      return (
                        <>
                          <div style={{ height: '150px' }}>
                            <Row className="card"> 
                              <Col span={6} className="carousel-col">
                              <img
                                  src={API_ENDPOINT + '/Storage/v1/storage/get-image' + cat.iconUrl}
                                  alt="Category Icon"
                                  style={{ height: 'auto', width: 'auto' }}
                                />
                              </Col>
                              <Col span={18} style={{ paddingLeft: '10px' }} className="carousel-col">
                                <h3 className='category-title'>{cat.name}</h3>
                                <p>{cat.descriptionShort}</p>
                              </Col>
                            </Row>
                          </div>
                        </>
                      );
                    })}
                </Carousel>
              </Skeleton>
              <Button className="mt-15" onClick={() => handleSubmit(instance)}>
                View All Products
              </Button>
            </Col>
          </Row>

          <Row className="learn-row" id="learnCipla">
            <Col span={24}>
              <div style={{ width: '65%' }}>
                <h3>Learn with my Cipla</h3>
                <p>
                  {' '}
                  My Cipla Learn is a Cipla South Africa’s initiative that provides FREE CPD accredited programmes to
                  healthcare professionals. This platform supports and develops doctors, nurses, clinic sisters,
                  pharmacists, pharmacist assistants, schedule 1 and 2 front shop assistants through training events and
                  product related online training quizzes with a purpose of “advancing healthcare for South Africans”.
                </p>
              </div>
              <Row className="card-row" gutter={25}>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Flippy flipOnHover={false} flipOnClick={false}>
                    <FrontSide>
                      <Card>
                        <img src={require('../../components/images/productCategoriesIconGreen.png')} className="learnIcons" />
                        <br/>
                        <h3>Free Accredited CPD modules</h3>
                        <p>
                          Our free accredited digital CPD courses with certificates are open for enrolment to all
                          healthcare workers.
                        </p>
                      </Card>
                    </FrontSide>
                  </Flippy>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Flippy flipOnHover={false} flipOnClick={false}>
                    <FrontSide>
                      <Card>
                        <img src={require('../../components/images/productCategoriesIconPurple.png')} className="learnIcons" />
                        <br/>
                        <h3>Rewards and incentives</h3>
                        <p>Imagine being rewarded for learning? For any course that you complete, you earn rewards.{' '}</p>
                      </Card>
                    </FrontSide>
                  </Flippy>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Flippy flipOnHover={false} flipOnClick={false}>
                    <FrontSide>
                      <Card>
                        <img src={require('../../components/images/productCategoriesIconYellow.png')} className="learnIcons" />
                        <br/>
                        <h3>Earn and spend rewards</h3>
                        <p>Spend your rewards points to any voucher from any of our partners.</p>
                      </Card>
                    </FrontSide>
                  </Flippy>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Flippy flipOnHover={false} flipOnClick={false}>
                    <FrontSide>
                      <Card>
                        <img src={require('../../components/images/productCategoriesIconRed.png')} className="learnIcons" />
                        <br/>
                        <h3>Mims</h3>
                        <p>You can enjoy a free unlimited access to mobiMiMS via the My Cipla Learn.</p>
                      </Card>
                    </FrontSide>
                  </Flippy>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="course-row">
            <h3>Featured Courses</h3>
            <Col span={24} className="category-main">
              
              <Skeleton avatar active loading={skeletonState.loading}>
                <Row justify='center'>
                  {data?.data?.courses &&
                    data?.data?.courses.map(course => {
                      return (
                        <>
                          <Card
                            style={{ width: 300, margin: 15, display:'flex', flexDirection: 'column', justifyContent: 'start'}}
                            cover={
                              <div style={{ height: 150 }}>
                                <img
                                  src={`${API_ENDPOINT}/Storage/v1/storage/get-image${course.featuredImageUrl}`}
                                  alt="Featured Course Image"
                                  className="course-img"
                                />
                              </div>
                            }
                          >
                            <div className='rewardSticker'>
                              {course.courseType === courseType.Denovo && (
                                <span className="cdp-span">{course.cpdPoints} CPD Points</span>
                              )}
                              <span className="reward-span">{course.dnaRewardPoints} Reward Points</span>
                            </div>
                            <div style={{display:'flex', flexDirection: 'column', justifyContent:'space-between', textAlign: 'left', padding: 10, width: '100%', height: '15rem'}}>
                              <div>
                                <p>
                                  <strong style={{color: '#4D4D4D', fontFamily: 'Inter'}}>{course.name}</strong>
                                </p>
                                <p style={{color: '#000000', fontFamily: 'Inter'}}>{course.descriptionShort}</p>
                                <div>
                                  <Rate disabled value={course.courseRating} />
                                </div>
                              </div>

                              <Button className="course-btn" onClick={() => history.push('/login')}>
                                View Course
                              </Button>
                            </div>
                          </Card>
                        </>
                      );
                    })}
                </Row>
              </Skeleton>
            </Col>
          </Row>

          <Row className="faq-row">
            <Col span={24}>
                <h3>FAQs</h3>
                <Collapse
                  bordered={false}
                  defaultActiveKey={['1']}
                  expandIconPosition="right"
                  expandIcon={({ isActive }) => <FeatherIcon icon={isActive ? 'minus' : 'plus'} size={20} />}
                >
                  <Panel header="What can I access on the My Cipla? " key="1" style={customPanelStyle}>
                    <p>
                      <div>As a wholesaler / tender customer you are able to access the following areas: </div> 
                      <ul>
                        <li>Products</li>
                        <li>Orders</li>
                        <li>Support Tickets</li>
                        <li>Knowledge Base</li>
                      </ul>
                      <br />
                      <div>
                        As a medical practitioner / pharmacist / pharmacy you are able to access the following areas:
                      </div>
                      <br />
                      <ul>
                        <li>Products</li>
                        <li>Orders</li>
                        <li>Support Tickets</li>
                        <li>Knowledge Base</li>
                        <li>Learn & Rewards</li>
                      </ul>
                    </p>
                  </Panel>
                  <Panel header="How do I register on My Cipla? " key="2" style={customPanelStyle}>
                    <p>
                      Click on the Sign up button at the top of the page and fill in your details and you are ready to
                      go. Do not forget to choose your correct profession in the drop downs.
                    </p>
                  </Panel>
                  <Panel
                    header="Can I register if I am not a wholesaler, tender customer, medical practitioner, pharmacist or pharmacy worker? "
                    key="3"
                    style={customPanelStyle}
                  >
                    <p>
                      At the moment, My Cipla caters to My Cipla customers. We are looking forward to expanding our
                      reach to include our retail customers in the future.
                    </p>
                  </Panel>
                  <Panel header="How do I contact My Cipla if I have any questions? " key="4" style={customPanelStyle}>
                    <p>
                      <div>
                        If you are logged in, click on the contact at the bottom of the My Cipla pages. Alternatively
                        log a ticket under Support. If you are not logged in yet, email <u>my.cipla@cipla.com</u> for
                        assistance.
                      </div>
                    </p>
                  </Panel>
                  <Panel
                    header="I emailed you, but how long do I have to wait for a response? "
                    key="5"
                    style={customPanelStyle}
                  >
                    <p>
                      72 hours We are inundated with queries from healthcare professionals every day, so to avoid
                      disappointment our goal is to revert to you within 3 days after you’ve sent your query to our
                      team.  If it is an emergency, please contact us via our telephone number.
                    </p>
                  </Panel>
                  <Panel
                    header="Cipla is heavily involved with charity work – how do I see what you are up to or how I can get involved? "
                    key="6"
                    style={customPanelStyle}
                  >
                    <p>
                      The Cipla Foundation has initiatives spanning healthcare, education and enterprise development
                      with its aim to improve life for all South Africans. Our key initiatives are:
                      <ul>
                        <li>
                          <b>Miles for Smiles</b>
                        </li>
                        <li>
                          <ul>
                            <li>
                              - Raising funds for children born with cleft lips and cleft palates and inspiring
                              individuals to make a difference in the lives of those around them.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>Ajuga</b>
                        </li>
                        <li>
                          <ul>
                            <li>
                              - Making Early Childhood Development a top priority regarding education, nutrition and
                              healthcare.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <b>Sha’p Left</b>
                        </li>
                        <li>
                          <ul>
                            <li>
                              - Empowering our communities in creating access to medicines within a 1km radius of our
                              homes.
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <br />
                      There are many ways to get involved. Read more here:{' '}
                      <a href="https://ciplafoundationsa.co.za/">https://ciplafoundationsa.co.za/</a>
                    </p>
                  </Panel>
                </Collapse>
            </Col>
          </Row>

          <Footer>
            <Row className="footer-row">
              <Col xs={24} sm={24} md={8} lg={8} className="align-center">
                <img src={ciplaLogo} className="cipla-footer-logo" alt="cipla-footer-logo" />
                <span style={{color: 'white', fontWeight: 'bold'}}>{new Date().getFullYear()} © Cipla</span>
              </Col>
              <Col xs={24} sm={24} md={7} lg={7} className="footer-text">
                <p style={{float: 'right'}}>
                <span style={{ cursor: 'pointer', color: 'white', fontWeight: 'bold', margin: '0 0.5rem' }}>
                      <a style={{ color: 'inherit' }} href="mailto:my.cipla@cipla.com">
                        Contact
                      </a>
                    </span>
                    {' '}
                    <span style={{ cursor: 'pointer', color: 'white', fontWeight: 'bold', margin: '0 0.5rem' }} onClick={() => setTCVisible(true)}>
                      Terms & Conditions
                    </span>{' '}
                    <span style={{ cursor: 'pointer', color: 'white', fontWeight: 'bold', margin: '0 0.5rem' }} onClick={() => setPolicyVisible(true)}>
                      Privacy Policy
                    </span>
                  
                </p>
              </Col>
            </Row>
            <TermsConditionModal visible={tcVisible} btnShow={false} setIsModalVisible={setTCVisible} />
            <PrivacyPolicy visible={policyVisible} setIsModalVisible={setPolicyVisible} />
          </Footer>
        </Layout>
      </LandingPage>
    </>
  );
};

export default Home;
