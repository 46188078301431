import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Product = lazy(() => import('../../container/products'));
const SingleProduct = lazy(() => import('../../container/products/Components/product'));
const ProductList = lazy(() => import('../../container/products/Components/allProductList'));
const ProductGrid = lazy(() => import('../../container/products/Components/allProduct'));
const CategoryDetails = lazy(() => import('../../container/products/Components/categoryDetail'));
const ProductDetails = lazy(() => import('../../container/products/Components/productDetails'));

const ProductRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Product} />
      <Route exact path={`${path}/:productType`} component={Product} />
      <Route exact path={`${path}/:productType/category/:categoryId`} component={CategoryDetails} />
      <Route exact path={`${path}/:productType/category/:categoryId/product/:productId`} component={ProductDetails} />
      <Route exact path={`${path}/category/:categoryId`} component={CategoryDetails} />
      <Route exact path={`${path}/category/:categoryId/product/:productId`} component={ProductDetails} />

      {/* <Route exact path={`${path}/product/:type`} component={SingleProduct}/> */}
      {/* <Route exact path={`${path}/grid`} component={Product}/> 
       <Route exact path={`${path}/list`} component={Product}/> */}
    </Switch>
  );
};

export default ProductRoutes;
