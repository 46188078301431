import { protectedResources } from '../../../config/auth/authConfig';
import { DataService } from '../../../config/dataService/dataService';

export async function getPagedOrders(accessToken, apiEndpoint, orderRequest) {
  const finalEndpoint =
    apiEndpoint +
    '?pageNumber=' +
    orderRequest.pageNumber.toString() +
    '&pagesize=' +
    orderRequest.pageSize.toString() +
    (orderRequest.filter != undefined ? '&filter=' + orderRequest.filter : '') +
    (orderRequest.sortBy != undefined ? '&sortBy=' + orderRequest.sortBy.replace('submitDate','disa_salesdocumentdate') : '') +
    (orderRequest.sortOrder != undefined ? '&sortOrder=' + orderRequest.sortOrder : '') +
    (orderRequest.clientId != undefined ? '&clientId=' + orderRequest.clientId : '');
  return await DataService.getWithToken(finalEndpoint, accessToken);
}

export const getOrder = async (setData, themeLoadingToggle, apiEndpoint, id, instance, account) => {
  const finalEndpoint = apiEndpoint + '?id=' + id;
  return DataService.getWithTokenSilent(finalEndpoint, instance, account)
    .then(resp => resp.data)
    .then(resp => {
      if (resp != undefined) {
        setData(resp);
      }
    })
    .finally(() => {
      themeLoadingToggle(false);
    })
    .catch((error, resp) => {
      if (error != undefined) {
        setData(error.response?.data);
      }
      themeLoadingToggle(false);
    });
};

export function TriggerOrdersGet(setOrders, account, inProgress, instance, orderRequest, themeLoadingToggle) {
  themeLoadingToggle(true);
  const finalEndpoint =
    protectedResources.orders.endpoint +
    '?pageNumber=' +
    orderRequest.pageNumber.toString() +
    '&pagesize=' +
    orderRequest.pageSize.toString() +
    (orderRequest.filter != undefined ? '&filter=' + orderRequest.filter : '') +
    (orderRequest.sortBy != undefined ? '&sortBy=' + orderRequest.sortBy.replace('submitDate','disa_salesdocumentdate') : '') +
    (orderRequest.sortOrder != undefined ? '&sortOrder=' + orderRequest.sortOrder : '') +
    (orderRequest.clientId != undefined ? '&clientId=' + orderRequest.clientId : '');

  DataService.getWithTokenSilent(finalEndpoint, instance, account)
    .then(resp => resp.data)
    .then(resp => {
      if (resp != undefined) {
        if (resp.succeeded) {
          setOrders({
            totalRows: resp.totalRecordCount,
            orders: resp.data,
          });
          themeLoadingToggle(false);
        }
      }
    })
    .finally(() => {
      themeLoadingToggle(false);
    })
    .catch((error, resp) => {
      themeLoadingToggle(false);
    });
}
export function TriggerClientsGet(setClientsData, account, inProgress, instance) {
  DataService.getWithTokenSilent(protectedResources.clients.endpoint, instance, account)
    .then(resp => resp.data)
    .then(resp => {
      if (resp != undefined) {
        if (resp.succeeded) {
          setClientsData(resp.data);
        }
      }
    });
}

export function TriggerDocumentDownload(instance, account, url, themeLoadingToggle) {
  themeLoadingToggle(true);
  const finalEndpoint = protectedResources.orderDocument.endpoint + '?Url=' + url;

  DataService.getWithTokenSilent(finalEndpoint, instance, account)
    .then(resp => resp.data)
    .then(resp => {
      if (resp != undefined) {
        if (resp.succeeded) {
          var documentElement = document.createElement('a');
          documentElement.href = 'data:application/octet-stream;base64,' + resp.data.documentBody;
          documentElement.download = resp.data.documentName;
          documentElement.click();

          themeLoadingToggle(false);
        }
      }
    })
    .finally(() => {
      themeLoadingToggle(false);
    })
    .catch(e => {
      notification['error']({
        message: 'Ticket',
        description: 'Something went wrong.',
      });
      themeLoadingToggle(false);
    });
}
