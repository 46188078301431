import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Support = lazy(() => import('../../container/support'));
const SupportFaqs = lazy(() => import('../../container/support/faq'));
const SupportLogTicket = lazy(() => import('../../container/support/ticket/log-ticket'));
const SupportViewTicket = lazy(() => import('../../container/support/ticket/view-ticket'));
const SupportTicketDetails = lazy(()=> import('../../container/support/ticket/ticket-details'));
const KBArticleView = lazy(()=>import('../../container/support/knowledebase/view-article'));
const ThemeDetails = lazy(() => import('../../container/support/faq/faqThemeDetails'))
const MyCiplaFAQs = lazy(()=>import('../../container/support/knowledebase/my-cipla-faqs'));
const TopicPanel = lazy(()=>import('../../container/support/knowledebase/topics-panel'));


const SupportRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Support} />
      <Route exact path={`${path}/faq`} component={SupportFaqs} />
      <Route exact path={`${path}/knowledgebase/view-article`} component={KBArticleView} />
      <Route exact path={`${path}/knowledgebase/view-article/:id`} component={KBArticleView} />
      <Route exact path={`${path}/knowledgebase/my-cipla-faqs`} component={MyCiplaFAQs} />
      <Route exact path={`${path}/knowledgebase/topics-panel/`} component={TopicPanel} />
      <Route exact path={`${path}/log-ticket`} component={SupportLogTicket} />
      <Route exact path={`${path}/log-ticket/:salesOrderId`} component={SupportLogTicket} />
      <Route exact path={`${path}/view-ticket`} component={SupportViewTicket} />
      <Route path={`${path}/ticket-details/:id`} component={SupportTicketDetails} />
      <Route path={`${path}/faqTheme/:themeId`} component={ThemeDetails} />
    </Switch>
  );
};

export default SupportRoutes;
