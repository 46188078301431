import { useAccount, useMsal } from '@azure/msal-react';
import { Card, Checkbox, Col, Row, Table, Tooltip, Popover, Button, Divider, Badge, notification } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { bindActionCreators } from 'redux';

import moment from 'moment';
import BackButton from '../../components/buttons/BackButton';
import { themeLoading } from '../../redux/themeLayout/actionCreator';
import { Main, TableStyleWrapper, TableWrapper } from '../styled';
import { TriggerNotificationDeletePost, TriggerNotificationReadStatusPost, TriggerNotificationUnReadStatusPost, TriggerNotificationsGetAllHistory } from '../dnaacademy/api';
import { PageHeader } from '../../components/page-headers/page-headers';
import { populateUnreadNotifications } from '../../redux/salesRepUnreadNotifications/actionCreator';

const Notifications = () => {

  const changeLayoutMode = useSelector(state => state.ChangeLayoutMode);
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const [allNotifications, setAllNotifications] = useState({ initialized: false, data: [] });
  const themeLoadingToggle = bindActionCreators(themeLoading, dispatch);
  const [notificationSaveRequest, setNotificationSaveRequest] = useState();
  const [notificationAction, setNotificationAction] = useState();
  const allUnreadNotifications = useSelector(state => state.salesRepUnreadNotification);
  const [selectedNotification, setSelectedNotification] = useState();

  useEffect(() => {
    TriggerNotificationsGetAllHistory(setAllNotifications, instance, account, themeLoadingToggle);
  }, []);

  const formatDate = (date) => {
    return moment(date).format('LL');
  };


  useEffect(()=>{
    if(notificationSaveRequest !== undefined && notificationAction !== undefined) {
        if (!notificationSaveRequest.succeeded) {
           
            TriggerNotificationsGetAllHistory(setAllNotifications, instance, account, themeLoadingToggle);
            const filteredArray = allNotifications.data?.filter(notification => notification.notificationStatus === 'Unread');
            dispatch(populateUnreadNotifications(filteredArray));
            notification['error']({
                message: 'Error',
                description: notificationSaveRequest.errors.join('. '),
            });
        }  
    }
  },[notificationSaveRequest]);



  const handleMarkAsRead = (record) => {
        const notificationRequest = {
          notifications: [{
              activityid: record.activityId,
              learncourseid: record.learnCourseId,
          }]
      };
  
      TriggerNotificationReadStatusPost(setNotificationSaveRequest, notificationRequest, instance, account);
      setSelectedNotification(record);
  
      const updatedNotifications = allNotifications.data.map(notification => {
          if (notification.activityId === record.activityId) {
              return { ...notification, notificationStatus: 'Read' };
          }
          return notification;
      });
  
      setAllNotifications(prevState => ({
          ...prevState,
          data: updatedNotifications,
      }));
      
      const filteredArray = updatedNotifications.filter(notification => notification.notificationStatus === 'Unread');
      dispatch(populateUnreadNotifications({initialized: true, data: filteredArray}));
  };

  const handleDelete = (record) => {

    const notificationRequest = {notifications: [{
        activityid: record.activityId,
        learncourseid: record.learnCourseId,
    }]};

    TriggerNotificationDeletePost(setNotificationSaveRequest, notificationRequest, instance, account, themeLoadingToggle);
    setSelectedNotification(record);
    const index = allNotifications.data.findIndex((notification) => notification.activityId === record.activityId);
    if (index !== -1) {

        const updatedData = [...allNotifications.data];
    
        updatedData.splice(index, 1);
    
        setAllNotifications({
        ...allNotifications,
        data: updatedData,
        });

        const filteredArray = updatedData.filter(notification => notification.notificationStatus === 'Unread');
        dispatch(populateUnreadNotifications({initialized: true, data: filteredArray}));
    }
  };

  const handleMarkAllAsUnread = () => {
    let notificationsData = [];
        allNotifications.data.forEach(record => {
            const notificationObj = {
                activityid: record.activityId,
                learncourseid: record.learnCourseId,
            }
            notificationsData.push(notificationObj);
        });
    const notificationRequest = {notifications: notificationsData};

    TriggerNotificationUnReadStatusPost(setNotificationSaveRequest, notificationRequest, instance, account);
    const updatedData = allNotifications.data.map((notification) => ({
        ...notification,
        notificationStatus: 'Unread',
    }));

    setAllNotifications({
        ...allNotifications,
        data: updatedData,
    });

    const filteredArray = updatedData.filter(notification => notification.notificationStatus === 'Unread');
    dispatch(populateUnreadNotifications({initialized: true, data: filteredArray}));
    
  };

  const handleDeleteAll = () => {
    let notificationsData = [];
    allNotifications.data.forEach(record => {
      const notificationObj = {
        activityid: record.activityId,
        learncourseid: record.learnCourseId,
      };
      notificationsData.push(notificationObj);
    });
    const notificationRequest = {notifications: notificationsData};

    TriggerNotificationDeletePost(setNotificationSaveRequest, notificationRequest, instance, account);
    setAllNotifications({
        ...allNotifications,
        data: [],
      });

      const filteredArray = [];
      dispatch(populateUnreadNotifications({initialized: true, data: filteredArray}));
   
  };

  const content = record => (
    <Row justify='center' align='middle'>
      <Button onClick={()=>handleMarkAsRead(record)} style={{border:'none', background:'transparent'}}>Mark as read</Button>
      <Divider />
      <Button onClick={()=>handleDelete(record)} style={{border:'none', background:'transparent'}}>Delete</Button>
    </Row>
  );

  const actionContent = () => (
    <Row justify='center' align='middle'>
      <Button onClick={()=>handleMarkAllAsUnread()} style={{border:'none', background:'transparent'}}>Mark all as unread</Button>
      <Divider />
      <Button onClick={()=>handleDeleteAll()} style={{border:'none', background:'transparent'}}>Delete all</Button>
    </Row>
  );

  const colunms = [
    {
        title:'',
        key:'status',
        width: 20, // Set the desired width (e.g., 80 pixels)
        minWidth: 20, // Set the minimum width (e.g., 60 pixels)
        maxWidth: 20,
        render: (text, record) => (
            <>
                {record?.notificationStatus == "Unread" &&(<><Badge dot style={{background: '#96C8FA'}} /></>)}
            </>
          ),
    },
    {
      title: 'Notification',
      key: 'notificationType',
      dataIndex: 'notificationType',
      defaultSortOrder: 'descend',
      width: 200, // Set the desired width (e.g., 80 pixels)
      minWidth: 200, // Set the minimum width (e.g., 60 pixels)
      maxWidth: 200,
    //   sorter: true,
    sorter: (a, b) => (a.notificationType && b.notificationType) ? a.notificationType.localeCompare(b.notificationType) : 0,
    },
    {
      title: 'Date',
      key: 'notificationDate',
      dataIndex: 'notificationDate',
      width: 200, // Set the desired width (e.g., 80 pixels)
      minWidth: 200, // Set the minimum width (e.g., 60 pixels)
      maxWidth: 200,
      render: text => formatDate(text),
      defaultSortOrder: 'descend',
    //   sorter: true,
    sorter: (a, b) => (a.notificationDate && b.notificationDate) ? a.notificationDate.localeCompare(b.notificationDate) : 0,

    },
    {
      title: 'Description',
      key: 'notificationText',
      dataIndex: 'notificationText',
      width: 260, // Set the desired width (e.g., 80 pixels)
      minWidth: 260, // Set the minimum width (e.g., 60 pixels)
      maxWidth: 260,
      defaultSortOrder: 'descend',
        sorter: (a, b) => {
            if (a.notificationText && b.notificationText) {
            return a.notificationText.localeCompare(b.notificationText);
            } else if (a.notificationText) {
            return 1; 
            } else if (b.notificationText) {
            return -1; 
            } else {
            return 0;
            }
        },
    },
    {
      title: ( <>
      <span style={{marginRight:'10px'}}>Action</span>
      <Popover width="70" placement="bottomRight" content={actionContent} trigger="click">
      <span style={{cursor:'pointer'}}><FeatherIcon icon="more-vertical" /></span>
    </Popover>
    </>),
     width: 20, // Set the desired width (e.g., 80 pixels)
     minWidth: 20, // Set the minimum width (e.g., 60 pixels)
     maxWidth: 20,
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => (
        <>
            <Popover width="70" placement="bottomRight" content={() => content(record)} trigger="click">
                <span style={{cursor:'pointer'}}><FeatherIcon icon="more-vertical" /></span>
            </Popover>
        </>
      ),
    },
  ];


  return (
    <>
    <Main>
      {!changeLayoutMode.loading && (
        <>
          <Row align="middle" justify="space-between">
            <PageHeader
              ghost
              title={
                <>
                  <BackButton />
                  Notifications
                </>
              }
            />
          </Row>

            <Row>
              <Col span={24}>
                <TableStyleWrapper>
                  <TableWrapper className="table-responsive">
                    <Table
                      size="small"
                      className="gradingTable table-striped-rows"
                      rowKey="id"
                      columns={colunms}
                      dataSource={allNotifications?.data}
                      loading={{ indicator: <></>, spinning: changeLayoutMode.loading }}
                      pagination={{ pageSize: 10 }}
                      sorter={true}
                    />
                  </TableWrapper>
                </TableStyleWrapper>
              </Col>
            </Row>
        </>
      )}
    </Main>
    </>
  );
};

export default Notifications;
