import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { Row, Col, Badge } from 'antd';
import { SettingDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
import CartPopover from '../../../container/cart/cartPopover';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';

const CartIcon = () => {
  const history = useHistory();

  const cart = useSelector(state => state.Cart)
  
  return (
    <>
      {window.innerWidth < 800 ? (
        <Link to="#" className="head-example">
          <FeatherIcon icon="shopping-cart" size={20} onClick={() => history.push('/cart')} />
        </Link>
      ) : (
        <div className="cart">
          <Badge count={cart.length}>
            <Popover placement="bottomRight" content={<CartPopover isPopover={true} />} action="hover">
              <Link to="#" className="head-example">
                <FeatherIcon icon="shopping-cart" size={20} />
              </Link>
            </Popover>
          </Badge>
        </div>
      )}
    </>
  );
};

export default CartIcon;
