const actions = {
  Set_All_Unread_Notifications: 'Set_All_Unread_Notifications',

  setUnreadNotifications: notifications => {
    return {
      type: actions.Set_All_Unread_Notifications,
      payload: notifications,
    };
  },
};
export default actions;