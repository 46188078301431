import actions from "./actions";

const { POPULATE_PROFILEINFORMATION } = actions;

const initialState = {
    initialized:false,
    internalUser: false,
    firstName: null,
    lastName: null,
    mobilePhone: null,
    birthDate: null,
    genderCodeValue: 0,
    genderCodeString: null,
    person: {
        firstName: null,
        lastName: null,
        mobilePhone: null,
        birthDate: null,
        genderCode: null,
        genderCodeString: "",
        optIn: null,
        allowSms: null,
        allowEmail: null,
        profileImageName: null,
        profileImageUrl: null,
    },
    company: null,
    reward: null
}

const profileInformationReducer = (state = initialState, actions) => {
    const { type, payload } = actions;
    switch (type) {
        case POPULATE_PROFILEINFORMATION:
            return payload;
        default:
            return state;
    }
}

export {profileInformationReducer};