import { notification, Select } from 'antd';
import React, { useState, useEffect } from 'react';

import { Main } from '../styled';
import { useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { themeLoading } from '../../redux/themeLayout/actionCreator';
import { bindActionCreators } from 'redux';
import { useAccount } from '@azure/msal-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './style.css';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Typography } from 'antd';
import { TriggerOrderPlace } from './api';
import CheckoutDetail from './checkoutDetail';
import CheckoutResult from './checkoutResult';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const { Option } = Select;
const { Title } = Typography;

function Checkout(params) {
  const dispatch = useDispatch();

  const changeLayoutMode = useSelector(state => state.ChangeLayoutMode);
  const profileInformation = useSelector(state => state.profileInformation);

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [checkoutResponse, setCheckoutResponse] = useState({});

  const cart = useSelector(state => state.Cart);
  const history = useHistory();

  const themeLoadingToggle = bindActionCreators(themeLoading, dispatch);

  const isMobile = window.innerWidth < 800;

  useEffect(() => {
    if (!checkoutResponse.succeeded) {
      if (checkoutResponse.errors?.length > 0) {
        window.scrollTo(0, 0);

        notification['error']({
          message: 'Error',
          description: checkoutResponse.errors.join('. '),
        });
      }
    }
  }, [checkoutResponse]);

  const placeOrder = () => {
    let orders = [];

    cart.map(item => {
      orders.push({ id: item.id, quantity: item.quantity });
    });

    TriggerOrderPlace(setCheckoutResponse, { orders: orders }, instance, account, themeLoadingToggle);
  };

  
  return (
    <>
      <PageHeader />

      {!changeLayoutMode.loading && (
        <>
          {!checkoutResponse.succeeded && (
            <CheckoutDetail cart={cart} accountInformation={profileInformation} placeOrder={placeOrder} />
          )}
          {checkoutResponse.succeeded && <CheckoutResult checkoutResponse={checkoutResponse} />}
        </>
      )}
      {changeLayoutMode.loading && <Main></Main>}
    </>
  );
}

export default Checkout;
