import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Account = lazy(() => import('../../container/account'));
const CiplaTeam = lazy(()=>import('../../container/cipla-teams'));
const CompanyInfo = lazy(()=>import('../../container/profile/company-info'));

const AccountRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Account} />
      <Route exact path={`${path}/teams`} component={CiplaTeam} />
      <Route exact path={`${path}/company`} component={CompanyInfo} />
    </Switch>
  );
};

export default AccountRoutes;
