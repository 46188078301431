const actions = {
  ADD_REWARD_TO_CART: 'ADD_REWARD_TO_CART',
  REMOVE_REWARD_FROM_CART: 'REMOVE_REWARD_FROM_CART',
  UPDATE_REWARD_ITEM: 'UPDATE_REWARD_ITEM',
  CLEAR_REWARD_CART: 'CLEAR_REWARD_CART',

  addRewardToCart: reward => {
    return {
      type: actions.ADD_REWARD_TO_CART,
      payload: reward,
    };
  },

  removeRewardFromCart: reward => {
    return {
      type: actions.REMOVE_REWARD_FROM_CART,
      payload: reward,
    };
  },

  updateRewardCartItem: reward => {
    return {
      type: actions.UPDATE_REWARD_ITEM,
      payload: reward,
    };
  },

  clearRewardCart: () => {
    return {
      type: actions.CLEAR_REWARD_CART
    };
  },
};

export default actions;
