import styled from 'styled-components';

const Div = styled.div`
  .ant-input {
    border: none;
  }

  .ant-input:focus {
    border: none;
    outline: 0;
    box-shadow: none;
  }

  .certain-category-icon {
    font-size: 16px;
    position: relative;
    bottom: -2px;
    color: ${({ theme, darkMode }) =>
      darkMode ? '#A8AAB3' : theme['gray-color']};

    @media only screen and (max-width: 767px) {
      bottom: 0;
    }

    svg {
      margin-top: 4px;

      @media only screen and (max-width: 767px) {
        width: 12px;
      }
    }
  }

  .ant-row-middle {
    
  }
  .border {
    position: absolute;
    height: 80%;
    top: 8px;
    left: 20px;
    right: 0;
    bottom: 0;
    border: 2px solid #255CA3; /* Set the border properties as per your requirements */
    border-radius: 25px; /* Set the border radius as per your requirements */
    pointer-events: none; /* Prevent the overlay border from interfering with user interactions */
  }
`;

export { Div };
