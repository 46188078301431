import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Form, Input, Button, notification, Select, Checkbox, Row, Col, Card, Modal, BackTop } from 'antd';
import { useDispatch } from 'react-redux';
import { AuthWrapper } from './style';
import Heading from '../../../../components/heading/heading';
import { TriggerGetRegister, TriggerRegister } from './api';
import TermsConditionModal from './TermsConditionModal';


const SignUp = () => {
  const history = useHistory();
  const listInnerRef = useRef();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    values: null,
    checked: null,
  });
  const [form] = Form.useForm();

  const [data, setData] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [MPNumber, setMPNumber] = useState(false);
  const [optInChecked, setOptInChecked] = useState(false);
  const [smsChecked, setSmsChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [tcChecked, setTCChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveResponse, setSaveResponse] = useState();

  const handleSubmit = values => {
    setLoading(true);
    (values.TitleId = '1'), (values.eligibility = undefined);
    values.OptIn = optInChecked;
    values.AllowSms = smsChecked;
    values.AllowEmail = emailChecked;
    values.AcceptTC = tcChecked;
    TriggerRegister(setSaveResponse, values, setLoading);
    // dispatch(fbAuthSignUp({ ...values, terms: state.checked }));
  };

  useEffect(() => {
    if (saveResponse) {
      if (saveResponse?.succeeded) {
        notification['success']({
          message: 'Registration',
          description: 'Registration is successful.',
        });
        setLoading(false);
        history.push('/');
      } else {
        notification['error']({
          message: 'Registration',
          description: saveResponse?.errors,
        });
        setLoading(false);
      }
    }
  }, [saveResponse]);

  useEffect(() => {
    TriggerGetRegister(setData);
  }, []);


  const handleChange = value => {
    data.data.eligibilities.map(ele => {
      if (ele.id === value) {
        setMPNumber(ele.requireMpNumber);
        setProfessions(ele.professions);
      }
    });
  };

  const cardStyle = {
    borderRadius: "16px",
    // width: 700,
    // height: "95%",
    // marginRight: "24px",
    boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)"
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTCAccept, setIsTCAccept] = useState(false);
  const [showBtn, setShowBtn] = useState(true);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const acceptTC = () => {
    setIsTCAccept(true);
    setTCChecked(true);
    setIsModalVisible(false);

  }

  return (
    <div style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>
      <Row justify="center">
        <Col>
          <Card style={cardStyle}>
            <AuthWrapper>
              <p className="auth-notice">
                Already have an account? <NavLink to="/">Login now</NavLink>
              </p>
              <div className="auth-contents">
                <Form name="register" onFinish={handleSubmit} layout="vertical" form={form}>
                  <Heading as="h3">Create an account</Heading>

                  <Form.Item
                    label="First Name"
                    name="FirstName"
                    rules={[{ required: true, message: 'Please input your First name!' }]}
                  >
                    <Input placeholder="First name" />
                  </Form.Item>
                  <Form.Item
                    name="LastName"
                    label="Last Name"
                    rules={[{ required: true, message: 'Please input your Last name!' }]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>

                  <Form.Item
                    name="EmailAddress"
                    label="Email Address"
                    rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                  >
                    <Input placeholder="name@example.com" />
                  </Form.Item>

                  <Form.Item
                    name="eligibility"
                    label="Select Eligibilities"
                    rules={[{ required: true, message: 'Please select Eligibilities!' }]}
                  >
                    <Select placeholder="Select Eligibilities" onChange={handleChange}>
                      {data?.data?.eligibilities &&
                        data.data.eligibilities.map(item => {
                          return <Select.Option key={item.id}>{item.name}</Select.Option>;
                        })}
                    </Select>
                  </Form.Item>

                  {professions.length > 0 && (
                    <Form.Item
                      name="ProfessionId"
                      label="Select Professions"
                      rules={[{ required: true, message: 'Please select Professions!' }]}
                    >
                      <Select placeholder="Select Professions">
                        {professions.map(item => {
                          return <Select.Option key={item.id}>{item.name}</Select.Option>;
                        })}
                      </Select>
                    </Form.Item>
                  )}

                  {MPNumber && (
                    <Form.Item
                      name="MPNumber"
                      label="MP Number"
                      rules={[{ required: true, message: 'Please input MP Number!' }]}
                    >
                      <Input placeholder="MP Number" />
                    </Form.Item>
                  )}

                  {/* <Form.Item name="OptIn" valuePropName="checked" style={{ marginBottom: '0' }}>
                    <Checkbox checked onChange={() => setOptInChecked(!optInChecked)}>
                      Opt In
                    </Checkbox>
                  </Form.Item> */}
                  <p style={{marginBottom: '0px'}}><b>How would you prefer Cipla to communicate with you:</b></p>

                  <Form.Item name="AllowSms" valuePropName="checked" style={{ marginBottom: '-10px' }}>
                    <Checkbox checked={smsChecked} onChange={() => setSmsChecked(!smsChecked)}>
                      Sms
                    </Checkbox>
                  </Form.Item>

                  <Form.Item name="AllowEmail" valuePropName="checked" style={{ marginBottom: '0' }}>
                    <Checkbox checked={emailChecked} onChange={() => setEmailChecked(!emailChecked)}>
                      Email
                    </Checkbox>
                  </Form.Item>

                  {!isTCAccept &&
                    <Form.Item 
                    name="termsAndConditions"
                    label="Accept Terms and Conditions"
                    rules={[{ required: true, message: 'Please Accept Terms and Conditions' }]}>
                      <Button className="btn-create" htmlType="button" type="primary" size="large" onClick={showModal}>
                        Accept Terms and Conditions
                      </Button>
                    </Form.Item>
                  }

                  {isTCAccept &&
                    <Form.Item
                      name="AcceptTC"
                      initialValue={isTCAccept}
                      rules={[{ required: true, message: 'Please Accept Terms and Condition!' }]}
                    >
                      <Checkbox checked={isTCAccept}>
                        Accept Terms & conditions
                      </Checkbox>
                    </Form.Item>
                  }
                  
                  <TermsConditionModal 
                    visible={isModalVisible}
                    acceptTC={acceptTC}
                    btnShow={true}
                    setIsModalVisible={setIsModalVisible}
                    />
                  <Form.Item>
                    <Button className="btn-create" htmlType="submit" type="primary" size="large" loading={loading}>
                      Create Account
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </AuthWrapper>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SignUp;
