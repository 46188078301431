import actions from './actions';

const {
  COURSE_DETAILS,
  SET_COURSE_STATUS,
  SET_COURSE_MODULE_STATUS,
  RESET_COURSE,
  SET_COURSE_RATING,
  SET_SCHEDULED_COURSES,
} = actions;

const initialState = {
  initialized: false,
  courseDetails: {},
};

const scheduledCoursesState = {
  initialized: false,
  courses: [],
};

const courseDetailReducer = (state = initialState, action) => {
  const { type, payload, status } = action;
  switch (type) {
    case COURSE_DETAILS:
      return {
        initialized: payload.initialized,
        courseDetails: payload.data,
      };
    case SET_COURSE_STATUS:
      return {
        ...state,
        courseDetails: {
          ...state.courseDetails,
          courseDetail: { ...state.courseDetails.courseDetail, enrolStatusText: status },
        },
      };
    case SET_COURSE_MODULE_STATUS:
      return {
        ...state,
        courseDetails: {
          ...state.courseDetails,
          courseContent: state.courseDetails.courseContent.map(content => {
            if (content.id === status.moduleId) {
              return { ...content, status: status.state };
            }
            return content;
          }),
        },
      };
    case SET_COURSE_RATING:
      return {
        ...state,
        courseDetails: {
          ...state.courseDetails,
          courseDetail: { ...state.courseDetails.courseDetail, userRating: status },
        },
      };
    case RESET_COURSE:
      return {
        initialized: false,
        courseDetails: {},
      };
    default:
      return state;
  }
};

const scheduledCoursesReducer = (state = scheduledCoursesState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SCHEDULED_COURSES:
      return {
        initialized: payload.initialized,
        courses: payload.data,
      };
    default:
      return state;
  }
};

export { courseDetailReducer, scheduledCoursesReducer };
