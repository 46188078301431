import { protectedResources } from '../../../config/auth/authConfig';
import { DataService } from '../../../config/dataService/dataService';


export function TriggerOrderPlace(setSaveResponse, request, instance, account, themeLoadingToggle) {
  postApi(setSaveResponse, request, instance, account, themeLoadingToggle, protectedResources.placeOrder.endpoint);
}

function postApi(setSaveResponse, formRequest, instance, account, themeLoadingToggle, endpoint) {
  themeLoadingToggle(true);

  DataService.postWithTokenSilent(endpoint, formRequest, instance, account)
    .then(resp => resp.data)
    .then(resp => {
      if (resp != undefined) {
        setSaveResponse(resp);
      }
    })
    .finally(() => {
      themeLoadingToggle(false);
    })
    .catch((error, resp) => {
      themeLoadingToggle(false);
    });
}
