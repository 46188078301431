import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const DNAAcademy = lazy(() => import('../../container/dnaacademy'));
const AboutDNAAcademy = lazy(() => import('../../container/dnaacademy/about'));
const EnrolDNAAcademy = lazy(() => import('../../container/dnaacademy/enrol'));

const LearnDNAAcademy = lazy(() => import('../../container/dnaacademy/learn'));

const RewardsDNAAcademy = lazy(() => import('../../container/dnaacademy/rewards'));
const RewardDetail = lazy(() => import('../../container/dnaacademy/rewards/rewardDetail'));
const Rewards = lazy(() => import('../../container/dnaacademy/rewards/admin/rewardsList'));
const RewardEdit = lazy(() => import('../../container/dnaacademy/rewards/admin/rewardsEditExisting'));
const RewardCreate = lazy(() => import('../../container/dnaacademy/rewards/admin/rewardsCreateNew'));
const MyRewards = lazy(() => import('../../container/dnaacademy/rewards/myRewards'));

const Courses = lazy(() => import('../../container/dnaacademy/learn/courses/admin/courseList'));
const CourseDetails = lazy(() => import('../../container/dnaacademy/learn/courses/courseDetails'));
const CourseStart = lazy(() => import('../../container/dnaacademy/learn/courses/courseComponents/courseDetailsStart'));
const CourseCreate = lazy(() => import('../../container/dnaacademy/learn/courses/admin/courseCreateNew'));
const CourseEdit = lazy(() => import('../../container/dnaacademy/learn/courses/admin/courseEditExisting'));

const QuizStart = lazy(() => import('../../container/dnaacademy/learn/quiz/quizComponents/quizDetailsStart'));

const Assessments = lazy(() => import('../../container/dnaacademy/learn/assessment/admin/assessmentList'));
const CreateAnAssessment = lazy(() => import('../../container/dnaacademy/learn/assessment/admin/assessmentCreateNew'));
const AssessmentEdit = lazy(() => import('../../container/dnaacademy/learn/assessment/admin/assessmentEditExisting'));
const AssessmentDetail = lazy(() => import('../../container/dnaacademy/learn/assessment/assessmentDetails'));

const Lessons = lazy(() => import('../../container/dnaacademy/learn/lessons/admin/lessonList'));
const LessonsDetail = lazy(() => import('../../container/dnaacademy/learn/lessons/lessonDetail'));
const CreateALesson = lazy(() => import('../../container/dnaacademy/learn/lessons/admin/lessonCreateNew'));
const EditALesson = lazy(() => import('../../container/dnaacademy/learn/lessons/admin/lessonEditExisting'));

const PharmaceuticalList = lazy(() =>
  import('../../container/dnaacademy/therapeutic-area/pharmaceutical-categories/admin/pharmaceuticalList'),
);
const PharmaceuticalForm = lazy(() =>
  import('../../container/dnaacademy/therapeutic-area/pharmaceutical-categories/admin/therapeuticForm'),
);
const ProductList = lazy(() => import('../../container/dnaacademy/therapeutic-area/products/admin/index'));
const productForm = lazy(() => import('../../container/dnaacademy/therapeutic-area/products/admin/productForm'));

const QuizList = lazy(() => import('../../container/dnaacademy/learn/quiz/admin'));
const QuizForm = lazy(() => import('../../container/dnaacademy/learn/quiz/admin/quizFrom'));
const Quiz = lazy(() => import('../../container/dnaacademy/learn/quiz/quizList'));
const QuizDetails = lazy(() => import('../../container/dnaacademy/learn/quiz/quizDetails'));

const Grading = lazy(() => import('../../container/dnaacademy/learn/grading'));
const PortfolioOfEvidence = lazy(() => import('../../container/dnaacademy/learn/portfolioOfEvidence'));
const SalesRepPOE = lazy(() => import('../../container/dnaacademy/learn/portfolioOfEvidence/SalesRepPOE'));
const AssessmentGrades = lazy(() => import('../../container/dnaacademy/learn/grading/assessmentGrades'));
const AssessmentContactGrading = lazy(() =>
  import('../../container/dnaacademy/learn/grading/assessmentContactGrading'),
);

const DNAAcademyRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={DNAAcademy} />
      <Route exact path={`${path}/about`} component={AboutDNAAcademy} />
      <Route exact path={`${path}/enrol`} component={EnrolDNAAcademy} />

      <Route exact path={`${path}/admin/pharmaceuticalList`} component={PharmaceuticalList} />
      <Route exact path={`${path}/admin/createPharmaceutical`} component={PharmaceuticalForm} />
      <Route exact path={`${path}/admin/editPharmaceutical/:categoryId`} component={PharmaceuticalForm} />

      <Route exact path={`${path}/admin/productList`} component={ProductList} />
      <Route exact path={`${path}/admin/addProduct`} component={productForm} />
      <Route exact path={`${path}/admin/editProduct/:productId`} component={productForm} />

      <Route exact path={`${path}/courses`} component={LearnDNAAcademy} />
      <Route path={`${path}/courses/course/:courseId`} component={CourseDetails} />
      <Route path={`${path}/courses/startcourse/:courseId`} component={CourseStart} />
      <Route path={`${path}/courses/denovo/:courseId`} component={CourseStart} />
      <Route path={`${path}/courses/assessment/:courseId/:assessmentId`} component={AssessmentDetail} />
      <Route path={`${path}/courses/assessment/:assessmentId`} component={AssessmentDetail} />

      <Route exact path={`${path}/campaigns`} component={RewardsDNAAcademy} />
      <Route exact path={`${path}/campaigndetail/:rewardId`} component={RewardDetail} />
      <Route exact path={`${path}/myRewards`} component={MyRewards} />
      <Route exact path={`${path}/admin/campaigns`} component={Rewards} />
      <Route exact path={`${path}/admin/createReward`} component={RewardCreate} />
      <Route exact path={`${path}/admin/editReward/:rewardId`} component={RewardEdit} />

      <Route exact path={`${path}/admin/courses`} component={Courses} />
      <Route exact path={`${path}/admin/createcourse`} component={CourseCreate} />
      <Route exact path={`${path}/admin/createcourse/:schedule`} component={CourseCreate} />
      <Route exact path={`${path}/admin/editcourse/:courseId`} component={CourseEdit} />

      <Route exact path={`${path}/admin/lessons`} component={Lessons} />
      <Route exact path={`${path}/admin/createlesson`} component={CreateALesson} />
      <Route exact path={`${path}/admin/editlesson/:lessonId`} component={EditALesson} />

      <Route exact path={`${path}/admin/assessments`} component={Assessments} />
      <Route exact path={`${path}/admin/createassesment`} component={CreateAnAssessment} />
      <Route exact path={`${path}/admin/editassessment/:assessmentId`} component={AssessmentEdit} />
      <Route exact path={`${path}/grading`} component={Grading} />
      <Route exact path={`${path}/portfolioOfEvidence`} component={PortfolioOfEvidence} />
      <Route exact path={`${path}/get-user-portfolio-evidence/:salesRepId`} component={SalesRepPOE} />
      <Route exact path={`${path}/myPortfolioOfEvidence`} component={SalesRepPOE} />

      <Route
        exact
        path={`${path}/grading/assessmentGrades/:scheduleId/:courseId/:assessmentName/:assessmentId`}
        component={AssessmentGrades}
      />
      <Route
        exact
        path={`${path}/grading/assessmentLearnerGrading/:contactName/:assessmentName/:dateSubmitted/:contactId/:courseId/:learnContactAssessmentId`}
        component={AssessmentContactGrading}
      />

      <Route exact path={`${path}/quizzes`} component={Quiz} />
      <Route exact path={`${path}/quizzes/product/:productId`} component={Quiz} />
      <Route exact path={`${path}/quizzes/quiz/:quizId`} component={QuizDetails} />
      <Route path={`${path}/quizzes/startquiz/:courseId`} component={QuizStart} />

      <Route exact path={`${path}/admin/quiz`} component={QuizList} />
      <Route exact path={`${path}/admin/createQuiz`} component={QuizForm} />
      <Route exact path={`${path}/admin/editQuiz/:quizId`} component={QuizForm} />
    </Switch>
  );
};

export default DNAAcademyRoutes;
