import { Col, Row } from 'antd';
import React from 'react';

import { Cards } from '../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import { useSelector } from 'react-redux';
import './style.css';
import { PageHeader } from '../../components/page-headers/page-headers';
import CartTable from './overview/cartTable';
import OrderSummary from './overview/Ordersummary';

function Cart(params) {
  const cart = useSelector(state => state.Cart);

  const profileInformation = useSelector(state => state.profileInformation);

  return (
    <>
      <PageHeader />

      <Main>
        <div className="cartWraper">
          <Row gutter={15}>
            <Col xxl={24} xl={24} md={24} sm={24} xs={24}>
              <Cards headless>
                <Row gutter={30}>
                  <Col xxl={16} xl={16} md={16} sm={16}>
                    <CartTable cart={cart} />
                  </Col>
                  <Col xxl={7} xl={7} md={7} sm={7}>
                    <OrderSummary
                      total={cart
                        .map(item => item)
                        .reduce((partialSum, a) => partialSum + a.dnaRewards * a.quantity, 0)}
                      itemCount={cart.length}
                      dnaPointsBalance={profileInformation.reward?.dnaPointsBalance.toLocaleString()}
                    />
                  </Col>
                </Row>
              </Cards>
            </Col>
          </Row>
        </div>
      </Main>
    </>
  );
}

export default Cart;
