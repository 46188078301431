import actions from './actions';

const { addRewardToCart, removeRewardFromCart, updateRewardCartItem, clearRewardCart } = actions;



export const addToCart = (reward) => {
    return (dispatch) => {
        dispatch(addRewardToCart(reward));
    };
};

export const removeFromCart = (reward) => {
    return (dispatch) => {
        dispatch(removeRewardFromCart(reward));
    };
};

export const updateCartItem = (reward) => {
    return (dispatch) => {
        dispatch(updateRewardCartItem(reward));
    };
};

export const clearCart = () => {
    return (dispatch) => {
        dispatch(clearRewardCart());
    };
};







